/**
 *
 * Users
 *
 */

import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  UserGroupEnum,
  UserWithOutIdGroupEnum,
} from 'sportsbook-openapi-react';

import useBooleanState from 'hooks/useBooleanState';
import { useSearch } from 'hooks/useSearch';
import { TestIds } from 'types/testIds.types';

import { TextInput } from 'app/components/forms';
import { UserCreatePanel } from 'app/components/panels';
import { RoleGuards } from 'app/components/RoleGuards';
import RefreshIcon from 'assets/images/refresh.svg?react';

import { actionsNT } from 'app/providers/EntitiesProvider';

import { UsersList } from './components/UserList';
import * as S from './Users.styles';

export const USERS_FETCH_LIMIT = 60;

export function Users() {
  const dispatch = useDispatch();
  const { searchQuery, setSearchQueryDebounced } = useSearch();

  const [isCreateUserVisible, showCreateUser, hideCreateUser] =
    useBooleanState();

  const { t } = useTranslation();

  const [roleName, setRoleName] = useState({
    id: '',
    name: t('all'),
  });

  const roleOptions = [
    '',
    UserWithOutIdGroupEnum.ADMIN,
    UserWithOutIdGroupEnum.OPERATOR,
    UserWithOutIdGroupEnum.TRADER,
    UserWithOutIdGroupEnum.CUSTOMER,
    UserWithOutIdGroupEnum.SUPERVISOR,
    UserWithOutIdGroupEnum.TRANSLATOR,
  ].map(option => ({
    id: option as string,
    name: option ? option.toLowerCase() : t('all'),
  }));

  return (
    <>
      <Helmet>
        <title>{t('users')}</title>
        <meta name="description" content="Description of Users" />
      </Helmet>
      <S.Wrapper>
        <S.ControlsWrapper>
          <RoleGuards roles={[UserGroupEnum.ADMIN]}>
            <S.AddButton
              color="secondary"
              onClick={showCreateUser}
              testId={TestIds.UsersPageAddButton}
            >
              {t('add user')}
            </S.AddButton>
          </RoleGuards>
          <S.TextWrapper>{t('access')}</S.TextWrapper>
          <S.StyledSelectNew
            testId={TestIds.UsersPageAccessSelect}
            options={roleOptions}
            value={roleName}
            onChange={element =>
              setRoleName(element as { id: string; name: string })
            }
          />
          <S.InputWrapper>
            <TextInput
              testId={TestIds.UsersPageSearch}
              placeholder={t('search')}
              onChange={e => setSearchQueryDebounced(e.target.value)}
              icon={<FontAwesomeIcon icon={faSearch} />}
            />
          </S.InputWrapper>
          <S.Spacer />
          <S.RefreshButton
            testId={TestIds.UsersPageUpdateButton}
            color="secondary"
            onClick={() => {
              dispatch(
                actionsNT.usersFetchItems({
                  roleName: roleName.id,
                  limit: USERS_FETCH_LIMIT,
                }),
              );
            }}
          >
            <RefreshIcon />
          </S.RefreshButton>
        </S.ControlsWrapper>
        <UsersList
          roleName={roleName.id ? roleName.id : undefined}
          searchQuery={searchQuery}
        />
      </S.Wrapper>
      {isCreateUserVisible && <UserCreatePanel onClose={hideCreateUser} />}
    </>
  );
}
