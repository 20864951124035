import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { OutcomeType } from 'sportsbook-openapi-react';

import { useRequestState } from 'hooks/useRequestState';
import { FETCH_ALL_LIMIT, RU_LANG_CODE } from 'consts';

import { PanelFooter } from 'app/components/panels/PanelFooter';
import { Panel } from 'app/components/ui';

import {
  actionsNT,
  Entities,
  getEntityByTypeAndId,
} from 'app/providers/EntitiesProvider';
import { getCategoriesEntries } from 'app/providers/EntitiesProvider/categories';
import { getMarketsEntries } from 'app/providers/EntitiesProvider/markets';

import { FormValues, validationSchema } from './constants';
import { OutcomeForm } from './OutcomeForm';

interface Props {
  id: number;
  onClose: () => void;
}

export const OutcomeEditPanel = ({ onClose, id }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actionsNT.marketsFetchItems({ limit: FETCH_ALL_LIMIT }));
    dispatch(actionsNT.categoriesFetchItems({ limit: FETCH_ALL_LIMIT }));
  }, [dispatch]);

  useRequestState(Entities.TYPES, 'update', onClose);

  const outcomeType: OutcomeType = useSelector(state =>
    getEntityByTypeAndId(state, Entities.TYPES, id),
  );
  const markets = useSelector(getMarketsEntries);
  const categories = useSelector(getCategoriesEntries);

  const onSubmit = (values: FormValues) => {
    dispatch(
      actionsNT.typesUpdate({
        typeId: outcomeType.id,
        outcomeTypeWithOutId: {
          name: values.name,
          marketId: values.market?.id!,
          categoryId: values.category?.id!,
          code: values.code,
          priorityOrder: values.priorityOrder,
          marketLink: values.marketLink?.id!,
          shortNames: [
            ...(outcomeType?.shortNames?.filter(
              t => t.langCode !== RU_LANG_CODE,
            ) ?? []),
            {
              langCode: RU_LANG_CODE,
              value: values.shortName,
            },
          ],
          translations: outcomeType?.translations ?? [],
        },
      }),
    );
  };

  const changeStatus = () => {
    dispatch(
      actionsNT.typesUpdate({
        typeId: outcomeType.id,
        outcomeTypeWithOutId: {
          ...outcomeType,
          disabled: !outcomeType?.disabled,
        },
      }),
    );
  };

  return (
    <Formik<FormValues>
      initialValues={{
        name: outcomeType.name,
        market: markets[outcomeType.marketId],
        category: categories[outcomeType.categoryId],
        code: outcomeType.code,
        shortName:
          outcomeType.shortNames?.find(s => s.langCode === RU_LANG_CODE)
            ?.value ?? '',
        priorityOrder: outcomeType?.priorityOrder,
        marketLink: {
          id: outcomeType.marketLink,
          name: outcomeType.marketLink,
        },
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {formikProps => (
        <Panel
          title={t('panels.edit.outcomeType')}
          onClose={onClose}
          onSubmit={formikProps.handleSubmit}
          footer={
            <PanelFooter
              submitButtonText={t('edit')}
              disabled={!formikProps.dirty || !formikProps.isValid}
              leftButtonText={
                outcomeType?.disabled ? t('enable') : t('disable')
              }
              onLeftButtonClick={changeStatus}
            />
          }
        >
          <OutcomeForm categories={categories} {...formikProps} />
        </Panel>
      )}
    </Formik>
  );
};
