import styled from 'styled-components';

import { Button } from 'app/components/ui';

export const Content = styled.div`
  padding: 20px;

  display: grid;
  grid-template-rows: auto auto 1fr;
  grid-row-gap: 15px;
`;

export const StyledButton = styled(Button)`
  width: fit-content;
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  column-gap: 15px;
`;
