/**
 *
 * Tags
 *
 */

import { useCallback, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'react-table';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DictionaryDropdown } from 'app/pages/Dictionary/components/DictionaryDropdown';
import { omit } from 'lodash-es';
import { Tag } from 'sportsbook-openapi-react';

import { includes } from 'utils/filters';
import { useAudit } from 'hooks/audit/useAudit';
import useBooleanState from 'hooks/useBooleanState';
import { useLazyLoading } from 'hooks/useLazyLoading';
import { useRequestState } from 'hooks/useRequestState';
import { useSearch } from 'hooks/useSearch';
import { TestIds } from 'types/testIds.types';

import { TextInput, TextInputCell } from 'app/components/forms';
import { TagCreatePanel, TagEditPanel } from 'app/components/panels';
import { Button, Dialog, LoadingIndicator, Table } from 'app/components/ui';

import { AuditedEntities } from 'app/providers/AuditProvider';
import { actionsNT, Entities } from 'app/providers/EntitiesProvider';
import {
  getTagsHasMore,
  selectTagsItems,
} from 'app/providers/EntitiesProvider/tags';

import * as S from './Tags.styles';

export function Tags() {
  const { isLoading } = useRequestState(Entities.TAGS, 'fetchItems');
  const items = useSelector(selectTagsItems);
  const hasMore = useSelector(getTagsHasMore);
  const [tagToBeDeleted, setTag] = useState<null | Tag>(null);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { searchQuery, setSearchQueryDebounced } = useSearch();

  const [isCreateTagVisible, showCreateTag, hideCreateTag] = useBooleanState();
  const [editTagId, setEditTagId] = useState<null | number>(null);

  const onLazyLoad = useCallback(
    ({ limit, offset }) =>
      dispatch(
        actionsNT.tagsFetchItems({
          limit,
          offset,
          withPagination: true,
        }),
      ),
    [dispatch],
  );

  const onBottom = useLazyLoading({
    onLazyLoad,
    hasMore,
  });

  const handleUpdateTag = useCallback(
    (tag: Tag) => {
      dispatch(
        actionsNT.tagsUpdate({
          tagId: tag.id,
          tagWithOutId: {
            ...omit(tag, 'id'),
          },
        }),
      );
    },
    [dispatch],
  );

  const openAudit = useAudit({
    entity: AuditedEntities.TAGS,
  });

  const columns: Column<Tag>[] = useMemo(
    () => [
      {
        Header: t('name').toString(),
        accessor: 'name',
        Cell: ({ value, row: { original: data } }) => (
          <TextInputCell
            defaultValue={value}
            onSave={newValue => {
              handleUpdateTag({
                ...data,
                name: newValue,
              });
            }}
            validate={value => value.length > 3}
          />
        ),
      },
      {
        Header: '',
        id: 'action',
        Cell: ({ row: { original: data } }) => (
          <S.ButtonsWrapper>
            <Button
              testId={`${TestIds.TagsPageEditButton}--${data.name}`}
              color="action"
              onClick={() => {
                setEditTagId(data.id);
              }}
            >
              {t('edit')}
            </Button>
            <Button
              testId={`${TestIds.TagsPageDeleteButton}--${data.name}`}
              color="action"
              onClick={() => {
                setTag(data);
              }}
            >
              {t('delete')}
            </Button>
          </S.ButtonsWrapper>
        ),
      },
      {
        Header: '',
        accessor: 'id',
        id: 'audit',
        Cell: ({ row: { original: data } }) => (
          <Button
            testId={`${TestIds.TagsPageAuditButton}--${data.name}`}
            color="action"
            onClick={() => {
              openAudit({ id: String(data.id), includeRelated: false });
            }}
          >
            {t('audit')}
          </Button>
        ),
      },
    ],
    [t, handleUpdateTag, openAudit],
  );

  const filteredData = useMemo(
    () => items.filter(tag => includes(tag.name, searchQuery)),
    [items, searchQuery],
  );

  return (
    <>
      <Helmet>
        <title>{t('tags')}</title>
        <meta name="description" content="Description of Tags" />
      </Helmet>
      <S.Wrapper>
        <S.DropdownWrapper>
          <DictionaryDropdown value={t('tags')} />
        </S.DropdownWrapper>
        <S.ContentWrapper>
          <S.Header>
            <Button onClick={showCreateTag} testId={TestIds.TagsPageAddButton}>
              {t('add tag')}
            </Button>
            <TextInput
              testId={TestIds.TagsPageSearch}
              placeholder={t('search')}
              onChange={e => setSearchQueryDebounced(e.target.value)}
              icon={<FontAwesomeIcon icon={faSearch} />}
            />
          </S.Header>
          <Table
            testId={TestIds.TagsPageTable}
            testFieldName="name"
            columns={columns}
            data={filteredData}
            onBottom={onBottom}
            columnsWidth={['auto', 'fit-content', 'fit-content', 'fit-content']}
          />
          {isLoading && <LoadingIndicator type="absolute" />}
        </S.ContentWrapper>
      </S.Wrapper>
      {tagToBeDeleted && (
        <Dialog
          text={t('deleteDialog', { name: tagToBeDeleted.name })}
          onClose={() => setTag(null)}
          onConfirm={() =>
            dispatch(actionsNT.tagsDelete({ tagId: tagToBeDeleted.id }))
          }
        />
      )}
      {isCreateTagVisible && <TagCreatePanel onClose={hideCreateTag} />}
      {!!editTagId && (
        <TagEditPanel
          onClose={() => {
            setEditTagId(null);
          }}
          id={editTagId}
        />
      )}
    </>
  );
}
