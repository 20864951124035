/**
 *
 * MatchPhases
 *
 */

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'react-table';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DictionaryDropdown } from 'app/pages/Dictionary/components/DictionaryDropdown';
import { omit } from 'lodash-es';
import { MatchPhase, UserGroupEnum } from 'sportsbook-openapi-react';

import { useAudit } from 'hooks/audit/useAudit';
import useBooleanState from 'hooks/useBooleanState';
import { useSearch } from 'hooks/useSearch';
import { FETCH_ALL_LIMIT } from 'consts';
import { TestIds } from 'types/testIds.types';

import { TextInput, TextInputCell } from 'app/components/forms';
import {
  MatchPhaseCreatePanel,
  MatchPhaseEditPanel,
} from 'app/components/panels';
import { RoleGuards } from 'app/components/RoleGuards';
import { Button, Table } from 'app/components/ui';

import { AuditedEntities } from 'app/providers/AuditProvider';
import { actionsNT } from 'app/providers/EntitiesProvider';
import { selectMatchPhasesItems } from 'app/providers/EntitiesProvider/matchPhases';

import { useUserStore } from 'store';
import { getIsTranslator } from 'store/user';

import * as S from './MatchPhases.styles';

export function MatchPhases() {
  const items = useSelector(selectMatchPhasesItems);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { searchQuery, setSearchQueryDebounced } = useSearch();
  const isTranslator = useUserStore(getIsTranslator);

  const [
    isCreateMatchPhaseVisible,
    showCreateMatchPhase,
    hideCreateMatchPhase,
  ] = useBooleanState();
  const [editMatchPhaseId, setEditMatchPhaseId] = useState<null | number>(null);

  const handleUpdateMatchPhase = useCallback(
    (matchPhase: MatchPhase) => {
      dispatch(
        actionsNT.matchPhasesUpdate({
          matchphaseId: matchPhase.id,
          matchPhaseWithoutId: omit(matchPhase, 'id'),
        }),
      );
    },
    [dispatch],
  );

  const openAudit = useAudit({
    entity: AuditedEntities.MATCH_PHASES,
  });

  const columns: Column<MatchPhase>[] = useMemo(
    () => [
      {
        Header: t('name').toString(),
        accessor: 'name',
        Cell: ({ value, row: { original: data } }) => {
          return !isTranslator ? (
            <TextInputCell
              defaultValue={value}
              onSave={newValue => {
                handleUpdateMatchPhase({
                  ...data,
                  name: newValue,
                });
              }}
              validate={value => value.length > 3}
            />
          ) : (
            <>{value}</>
          );
        },
      },
      ...(!isTranslator
        ? [
            {
              Header: '',
              id: 'action',
              accessor: 'id' as keyof MatchPhase,

              Cell: ({ row: { original: data } }) => (
                <S.ButtonsWrapper>
                  <Button
                    testId={`${TestIds.MatchPhasesPageEditButton}--${data.name}`}
                    color="action"
                    onClick={() => {
                      setEditMatchPhaseId(data.id);
                    }}
                  >
                    {t('edit')}
                  </Button>
                </S.ButtonsWrapper>
              ),
            },
            {
              Header: '',
              accessor: 'id' as keyof MatchPhase,
              id: 'audit',
              Cell: ({ row: { original: data } }) => (
                <Button
                  testId={`${TestIds.MatchPhasesPageAuditButton}--${data.name}`}
                  color="action"
                  onClick={() => {
                    openAudit({ id: String(data.id), includeRelated: false });
                  }}
                >
                  {t('audit')}
                </Button>
              ),
            },
          ]
        : []),
    ],
    [t, isTranslator, handleUpdateMatchPhase, openAudit],
  );

  useEffect(() => {
    dispatch(
      actionsNT.matchPhasesFetchItems({
        limit: FETCH_ALL_LIMIT,
        name: searchQuery.length ? searchQuery.toLowerCase() : undefined,
      }),
    );
    dispatch(actionsNT.sportsFetchItems({ limit: FETCH_ALL_LIMIT }));
  }, [dispatch, searchQuery]);

  return (
    <S.Wrapper>
      <S.DropdownWrapper>
        <DictionaryDropdown value={'match phases'} />
      </S.DropdownWrapper>
      <S.ContentWrapper>
        <S.Header>
          <RoleGuards
            roles={[
              UserGroupEnum.ADMIN,
              UserGroupEnum.CUSTOMER,
              UserGroupEnum.OPERATOR,
              UserGroupEnum.SUPERVISOR,
            ]}
          >
            <Button
              onClick={showCreateMatchPhase}
              testId={TestIds.MatchPhasesPageAddButton}
            >
              {t('add match phase')}
            </Button>
          </RoleGuards>
          <TextInput
            testId={TestIds.MatchPhasesPageSearchInput}
            placeholder={t('search')}
            onChange={e => setSearchQueryDebounced(e.target.value)}
            icon={<FontAwesomeIcon icon={faSearch} />}
          />
        </S.Header>
        <Table
          testId={TestIds.MatchPhasesPageTable}
          testFieldName="name"
          columns={columns}
          data={items}
          columnsWidth={['auto', 'fit-content', 'fit-content']}
        />
      </S.ContentWrapper>
      {isCreateMatchPhaseVisible && (
        <MatchPhaseCreatePanel onClose={hideCreateMatchPhase} />
      )}
      {!!editMatchPhaseId && (
        <MatchPhaseEditPanel
          onClose={() => {
            setEditMatchPhaseId(null);
          }}
          id={editMatchPhaseId}
        />
      )}
    </S.Wrapper>
  );
}
