import { Fragment, useEffect, useState } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Column } from 'react-table';
import { faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CommentsSelect } from 'app/pages/Dictionary/Directory/components/CommentsSelect';
import { ExclusionPopup } from 'app/pages/Dictionary/Directory/components/ExclusionPopup';
import { TagsSelect } from 'app/pages/Dictionary/Directory/components/TagsSelect';
import { GeneralValuesPopup } from 'app/pages/Events/components/Odds/GeneralValuesPopup';
import { compareByPriority } from 'app/pages/Events/utils';
import { get, head } from 'lodash-es';
import {
  EntityType,
  Group,
  Margin,
  Sport,
  Tournament,
  UserGroupEnum,
} from 'sportsbook-openapi-react';

import { getTestId } from 'utils/getTestId';
import { useAudit } from 'hooks/audit/useAudit';
import useBooleanState from 'hooks/useBooleanState';
import { FETCH_ALL_LIMIT } from 'consts';
import { RootState } from 'types';
import { TestIds } from 'types/testIds.types';

import {
  GroupEditPanel,
  MarginCreatePanel,
  SportEditPanel,
  TournamentDependencyPanel,
  TournamentEditPanel,
} from 'app/components/panels';
import { RoleGuards } from 'app/components/RoleGuards';
import {
  Button,
  LoadingIndicator,
  Popup,
  Table,
  Tabs,
  Text,
} from 'app/components/ui';
import { ColumnWidth } from 'app/components/ui/Table/Table.types';
import {
  BetpaymaxColumn,
  CashoutColumn,
  DelayColumn,
  ExclusionColumn,
  MarginColumn,
  MarginProgressionColumn,
  MaxOddColumn,
  SourceMappingColumn,
} from 'app/components/сolumns';

import { AuditedEntities } from 'app/providers/AuditProvider';
import {
  actionsNT,
  DirectoryEntitiesEnum,
  getDirectoryEntityByTypeAndId,
  getEntities,
  idFieldByEntity,
} from 'app/providers/EntitiesProvider';
import { getCategoriesEntries } from 'app/providers/EntitiesProvider/categories';
import { getExclusion } from 'app/providers/EntitiesProvider/exclusions';
import {
  useBetPayMax,
  useCategoryCashoutBlock,
  useCategoryExclusion,
  useCategorySourceMapping,
  useDelay,
  useMargin,
  useMarginProgression,
} from 'app/providers/EntitiesProvider/hooks';
import { useMaxOdd } from 'app/providers/EntitiesProvider/hooks/useMaxOdd';
import { selectMarginItems } from 'app/providers/EntitiesProvider/margins';

import { useUserStore } from 'store';
import { getIsCustomer, getIsOperator } from 'store/user';

import * as S from './Content.styles';

const fields = [
  {
    key: 'id',
    translationKey: 'id',
  },
  {
    key: 'name',
    translationKey: 'name',
  },
  {
    key: 'sportId',
    translationKey: 'sport type',
    type: 'sports',
  },
  {
    key: 'groupId',
    translationKey: 'group',
    type: 'groups',
  },
  {
    key: 'countryId',
    translationKey: 'country',
    type: 'countries',
  },
  {
    key: 'tournamentId',
    translationKey: 'tournament',
    type: 'tournaments',
  },
];

const tabs = [
  {
    label: 'Линия',
    value: false,
  },
  {
    label: 'Live',
    value: true,
  },
];

type Match = {
  type: DirectoryEntitiesEnum;
  id: string;
};

const matchToEntityType = {
  sports: EntityType.SPORT,
  groups: EntityType.SPORT_GROUP,
  tournaments: EntityType.TOURNAMENT,
};

export const DirectoryContent = () => {
  const { t } = useTranslation();
  const params = useParams<Match>();
  const dispatch = useDispatch();
  const [live, setLive] = useState<boolean>(head(tabs)!.value);
  const { type } = params;

  if (!params.id || !type) {
    throw new Error('Provide id and type path parameters');
  }

  const id = +params.id;

  const entities = useSelector(getEntities);
  const marginItems = useSelector(selectMarginItems);
  const categories = useSelector(getCategoriesEntries);

  const [isEditSportVisible, showEditSport, hideEditSport] = useBooleanState();
  const [isEditTournamentVisible, showEditTournament, hideEditTournament] =
    useBooleanState();
  const [isEditGroupVisible, showEditGroup, hideEditGroup] = useBooleanState();
  const [
    isTournamentDependencyVisible,
    showTournamentDependency,
    hideTournamentDependency,
  ] = useBooleanState();
  const [isCreateMarginVisible, showCreateMargin, hideCreateMargin] =
    useBooleanState();

  const exclusion = useSelector(state =>
    getExclusion(state, matchToEntityType[type], id),
  );

  const entity = useSelector((state: RootState) =>
    getDirectoryEntityByTypeAndId(state, type, id),
  );

  const sportId: Sport['id'] | undefined = useMemo(() => {
    if (!entity) return undefined;
    switch (type) {
      case DirectoryEntitiesEnum.SPORTS: {
        return id;
      }
      case DirectoryEntitiesEnum.GROUPS: {
        return (entity as Group).sportId;
      }
      case DirectoryEntitiesEnum.TOURNAMENTS: {
        return (entity as Tournament).sportId;
      }
    }
  }, [entity, id, type]);

  useEffect(() => {
    dispatch(actionsNT.categoriesFetchItems({ limit: FETCH_ALL_LIMIT }));
  }, [dispatch]);

  const { isCustomer, customerId } = useUserStore(getIsCustomer);
  const isOperator = useUserStore(getIsOperator);

  useEffect(() => {
    if (isCustomer && customerId) {
      dispatch(
        actionsNT.exclusionsFetchItems({
          customerId: customerId,
          entityId: id,
          entityType: matchToEntityType[type],
        }),
      );
    }
  }, [dispatch, id, type, isCustomer, customerId]);

  useEffect(() => {
    // @ts-ignore
    dispatch(actionsNT[`${type}FetchItem`]({ [idFieldByEntity[type]]: id }));
  }, [type, dispatch, id]);

  const {
    marginProgressions,
    marginProgressionOutcomeCategories,
    updateMarginProgression,
  } = useMarginProgression({
    entityId: id,
    entityType: matchToEntityType[type],
    sportId,
    live,
    customerId,
  });

  const {
    deleteMarginByCategory,
    isMarginsLoading,
    updateMarginValue,
    margins: marginsByCategory,
  } = useMargin({
    entityId: id,
    entityType: matchToEntityType[type],
    live,
  });
  const {
    deleteCategorySourceMappingByCategory,
    isCategorySourceMappingsLoading,
    categorySourceMappings,
    updateCategorySourceMappingValue,
    sourcesOptions,
  } = useCategorySourceMapping({
    entityId: id,
    entityType: matchToEntityType[type],
  });
  const {
    updateBetPayMaxValue,
    deleteBetPayMaxByCategory,
    isBetPayMaxesLoading,
    betPayMaxes,
  } = useBetPayMax({
    entityId: id,
    entityType: matchToEntityType[type],
    live,
  });
  const { deleteDelayByCategory, isDelaysLoading, delays, updateDelayValue } =
    useDelay({
      entityId: id,
      entityType: matchToEntityType[type],
    });
  const {
    deleteMaxOddByCategory,
    isMaxOddsLoading,
    maxOdds,
    updateMaxOddValue,
  } = useMaxOdd({
    entityId: id,
    entityType: matchToEntityType[type],
    live,
  });
  const {
    categoryExclusions,
    updateCategoryExclusion,
    deleteCategoryExclusionByCategory,
    isCategoryExclusionsLoading,
  } = useCategoryExclusion({
    entityId: id,
    entityType: matchToEntityType[type],
    live,
  });
  const {
    categoryCashoutBlocks,
    deleteCategoryCashoutBlockByCategory,
    updateCategoryCashoutBlock,
    isCategoryCashoutBlockLoading,
  } = useCategoryCashoutBlock({
    entityId: id,
    entityType: matchToEntityType[type],
    live,
  });

  const isLoading =
    isCategoryExclusionsLoading ||
    isCategoryCashoutBlockLoading ||
    isMarginsLoading ||
    isBetPayMaxesLoading ||
    isDelaysLoading ||
    isMaxOddsLoading ||
    isCategorySourceMappingsLoading;

  const sortedCategories = useMemo(
    () =>
      marginItems
        .map(m => ({
          ...m,
          categoryName: categories[m.outcomeCategoryId]?.name,
        }))
        .sort((a, b) => {
          const categoryA = categories[a.outcomeCategoryId];
          const categoryB = categories[b.outcomeCategoryId];

          if (!categoryA || !categoryB) return 1;

          return compareByPriority(categoryA, categoryB);
        }),
    [categories, marginItems],
  );

  const openSportsAudit = useAudit({
    entity: AuditedEntities.SPORTS,
  });

  const openGroupsAudit = useAudit({
    entity: AuditedEntities.GROUPS,
  });

  const openTournamentsAudit = useAudit({
    entity: AuditedEntities.TOURNAMENTS,
  });

  const columns = useMemo(() => {
    const _columns: Column<Margin>[] = [
      {
        Header: t('category').toString(),
        accessor: 'outcomeCategoryId',
        Cell: ({ value }) => <>{categories[value]?.name}</>,
      },
    ];
    if (!(live || isCustomer)) {
      _columns.push(
        SourceMappingColumn<Margin>({
          header: t('source').toString(),
          accessor: 'outcomeCategoryId',
          categorySourceMappings,
          updateCategorySourceMappingValue,
          deleteCategorySourceMappingByCategory,
          isOperator: isOperator,
          sourcesOptions,
        }),
      );
    }
    _columns.push(
      MarginColumn<Margin>({
        header: t('margin').toString(),
        accessor: 'outcomeCategoryId' as keyof Margin,
        updateMarginValue,
        margins: marginsByCategory,
        isEditable: true,
        isOperator: isOperator,
      }),
    );
    _columns.push(
      MarginProgressionColumn<Margin>({
        header: t('progressions'),
        accessor: 'outcomeCategoryId' as keyof Margin,
        updateMarginProgression,
        marginProgressions,
        marginProgressionOutcomeCategories,
        isEditable: !isOperator,
      }),
    );
    _columns.push(
      MaxOddColumn<Margin>({
        header: t('maxOdd short').toString(),
        accessor: 'outcomeCategoryId' as keyof Margin,
        updateMaxOddValue,
        maxOdds,
        isEditable: true,
        isOperator: isOperator,
      }),
    );
    if (isCustomer) {
      _columns.push(
        BetpaymaxColumn<Margin>({
          header: t('maxbet short').toString(),
          accessor: 'outcomeCategoryId',
          updateBetPayMaxValue,
          betPayMaxes,
        }),
      );
      if (live) {
        _columns.push(
          DelayColumn<Margin>({
            header: t('delay').toString(),
            accessor: 'outcomeCategoryId',
            updateDelayValue,
            delays,
          }),
        );
      }
      _columns.push(
        ExclusionColumn<Margin>({
          header: t('exclusion').toString(),
          accessor: 'outcomeCategoryId',
          updateCategoryExclusion,
          categoryExclusions,
        }),
      );
      _columns.push(
        CashoutColumn<Margin>({
          header: t('cashout block').toString(),
          accessor: 'outcomeCategoryId',
          updateCategoryCashoutBlock,
          categoryCashoutBlocks,
        }),
      );
    }
    if (!isOperator) {
      _columns.push({
        Header: '',
        id: 'action',
        accessor: 'outcomeCategoryId' as keyof Margin,
        Cell: ({ value, row: { original: data } }) => {
          const marginProgressionOutcomeCategory =
            marginProgressionOutcomeCategories.find(
              el => el.outcomeCategoryId === value,
            );

          return (
            <S.ButtonsWrapper>
              <Button
                testId={getTestId(
                  TestIds.CategoriesTableDeleteButton,
                  data.categoryName,
                )}
                color="link"
                onClick={() => {
                  deleteMarginByCategory({ outcomeCategoryId: value });
                  deleteBetPayMaxByCategory({ outcomeCategoryId: value });
                  deleteDelayByCategory({ outcomeCategoryId: value });
                  deleteMaxOddByCategory({ outcomeCategoryId: value });
                  if (!!marginProgressionOutcomeCategory) {
                    updateMarginProgression('delete', {
                      outcomeCategoryId: value,
                      marginProgressionOutcomeCategory:
                        marginProgressionOutcomeCategory.id,
                    });
                  }
                  deleteCategoryExclusionByCategory({
                    outcomeCategoryId: value,
                  });
                  deleteCategorySourceMappingByCategory({
                    categoryId: value,
                  });
                  deleteCategoryCashoutBlockByCategory({
                    outcomeCategoryId: value,
                  });
                }}
              >
                {t('delete')}
              </Button>
            </S.ButtonsWrapper>
          );
        },
      });
    }
    return _columns;
  }, [
    t,
    live,
    isCustomer,
    categorySourceMappings,
    updateCategorySourceMappingValue,
    deleteCategorySourceMappingByCategory,
    isOperator,
    sourcesOptions,
    updateMarginValue,
    marginsByCategory,
    updateMaxOddValue,
    maxOdds,
    updateBetPayMaxValue,
    betPayMaxes,
    updateDelayValue,
    delays,
    updateCategoryExclusion,
    categoryExclusions,
    updateCategoryCashoutBlock,
    categoryCashoutBlocks,
    categories,
    deleteMarginByCategory,
    deleteBetPayMaxByCategory,
    deleteDelayByCategory,
    deleteMaxOddByCategory,
    deleteCategoryExclusionByCategory,
    deleteCategoryCashoutBlockByCategory,
    marginProgressions,
    marginProgressionOutcomeCategories,
    updateMarginProgression,
  ]);

  const columnsWidth: ColumnWidth[] = isCustomer
    ? ['auto', 20, 20, 20, 'min-content', 'fit-content', 'fit-content']
    : ['auto', 20, 20, 20, 'auto', 'fit-content'];

  if (!entity) return <LoadingIndicator type="full" />;

  return (
    <S.Content>
      <S.ContentBlock>
        <S.ContentHead>
          <Text size="h3">{entity.name}</Text>
          <S.Spacer />
          <RoleGuards
            roles={[
              UserGroupEnum.ADMIN,
              UserGroupEnum.OPERATOR,
              UserGroupEnum.SUPERVISOR,
            ]}
          >
            <S.ButtonsWrapper>
              {type === DirectoryEntitiesEnum.TOURNAMENTS && (
                <Button
                  color="action"
                  onClick={showTournamentDependency}
                  testId={TestIds.SportPageDependencyButton}
                >
                  {t('dependencies')}
                </Button>
              )}
              {type === DirectoryEntitiesEnum.TOURNAMENTS && (
                <RoleGuards roles={[UserGroupEnum.OPERATOR]}>
                  <Button
                    color="action"
                    onClick={showEditTournament}
                    testId={TestIds.SportPageEditButton}
                  >
                    <FontAwesomeIcon icon={faEdit} />
                    {t('edit')}
                  </Button>
                </RoleGuards>
              )}
              <RoleGuards
                roles={[UserGroupEnum.ADMIN, UserGroupEnum.SUPERVISOR]}
              >
                <Button
                  testId={TestIds.SportPageEditButton}
                  color="action"
                  onClick={() => {
                    if (type === DirectoryEntitiesEnum.SPORTS) {
                      showEditSport();
                    } else if (type === DirectoryEntitiesEnum.TOURNAMENTS) {
                      showEditTournament();
                    } else if (type === DirectoryEntitiesEnum.GROUPS) {
                      showEditGroup();
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faEdit} />
                  {t('edit')}
                </Button>
              </RoleGuards>
              <Button
                testId={TestIds.SportPageAuditButton}
                color="action"
                onClick={() => {
                  switch (type) {
                    case DirectoryEntitiesEnum.SPORTS:
                      openSportsAudit({
                        id: String(entity.id),
                        includeRelated: false,
                      });
                      break;
                    case DirectoryEntitiesEnum.GROUPS:
                      openGroupsAudit({
                        id: String(entity.id),
                        includeRelated: false,
                      });
                      break;
                    case DirectoryEntitiesEnum.TOURNAMENTS:
                      openTournamentsAudit({
                        id: String(entity.id),
                        includeRelated: false,
                      });
                      break;
                  }
                }}
              >
                {t('audit')}
              </Button>
            </S.ButtonsWrapper>
          </RoleGuards>
          <RoleGuards roles={[UserGroupEnum.CUSTOMER]}>
            <ExclusionPopup
              customerId={customerId!}
              type={matchToEntityType[type]}
              id={id}
              excluded={!!exclusion?.excluded}
            />
          </RoleGuards>
        </S.ContentHead>
        <S.ContentInfoBlockWrapper>
          <S.ContentInfoBlock>
            {fields.map(field => {
              const entityField = entity[field.key];
              if (!entityField) return null;
              return (
                <Fragment key={field.key}>
                  <S.InfoLabel
                    size="l"
                    testId={`${TestIds.SportPageFieldName}--${field.key}`}
                  >
                    {t(field.translationKey)}
                  </S.InfoLabel>
                  <S.InfoValue
                    size="l"
                    testId={`${TestIds.SportPageFieldValue}--${entityField}`}
                  >
                    {field.type
                      ? get(
                          get(entities[field.type].entries, `${[entityField]}`),
                          'name',
                        )
                      : entityField}
                  </S.InfoValue>
                </Fragment>
              );
            })}
          </S.ContentInfoBlock>
          <RoleGuards roles={[UserGroupEnum.CUSTOMER]}>
            <S.ContentInfoBlock>
              <S.InfoLabel size="l">{t('tags')}</S.InfoLabel>
              <TagsSelect entityId={id} entityType={matchToEntityType[type]} />
            </S.ContentInfoBlock>
          </RoleGuards>
          <RoleGuards
            roles={[
              UserGroupEnum.ADMIN,
              UserGroupEnum.SUPERVISOR,
              UserGroupEnum.OPERATOR,
            ]}
          >
            <S.ContentInfoBlock>
              <S.InfoLabel size="l">{t('comments')}</S.InfoLabel>
              <CommentsSelect
                entityId={id}
                entityType={matchToEntityType[type]}
              />
            </S.ContentInfoBlock>
          </RoleGuards>
        </S.ContentInfoBlockWrapper>
      </S.ContentBlock>
      <RoleGuards
        roles={[
          UserGroupEnum.ADMIN,
          UserGroupEnum.CUSTOMER,
          UserGroupEnum.OPERATOR,
          UserGroupEnum.SUPERVISOR,
        ]}
      >
        <S.TabsHeading size="h4">
          {t('outcome categories and margin')}
        </S.TabsHeading>
        <Tabs tabs={tabs} onChange={setLive} />
        {isLoading ? (
          <LoadingIndicator type="full" />
        ) : (
          <S.ContentBottomBlock>
            <RoleGuards
              roles={[
                UserGroupEnum.ADMIN,
                UserGroupEnum.CUSTOMER,
                UserGroupEnum.SUPERVISOR,
              ]}
            >
              <S.ButtonsWrapper>
                <Button
                  color="action"
                  onClick={showCreateMargin}
                  testId={TestIds.SportPageAddCategoryButton}
                >
                  <FontAwesomeIcon icon={faPlus} />
                  {t('add category')}
                </Button>
                <RoleGuards roles={[UserGroupEnum.CUSTOMER]}>
                  <Popup
                    placement="bottom-end"
                    button={
                      <Button
                        color="action"
                        testId={TestIds.GeneralValuesPopupDropdown}
                      >
                        {t('set general values')}
                        <FontAwesomeIcon icon={faChevronDown} />
                      </Button>
                    }
                    body={
                      <GeneralValuesPopup
                        entityId={entity.id}
                        entityType={matchToEntityType[type]}
                        live={live}
                        outcomeCategoriesIds={sortedCategories.map(
                          c => c.outcomeCategoryId,
                        )}
                      />
                    }
                  />
                </RoleGuards>
              </S.ButtonsWrapper>
            </RoleGuards>
            <Table
              columns={columns}
              data={sortedCategories}
              columnsWidth={columnsWidth}
              testId={TestIds.SportPageCategoriesTable}
              testFieldName="categoryName"
            />
          </S.ContentBottomBlock>
        )}
      </RoleGuards>
      {isEditSportVisible && (
        <SportEditPanel onClose={hideEditSport} id={entity.id} />
      )}
      {isEditTournamentVisible && (
        <TournamentEditPanel onClose={hideEditTournament} id={entity.id} />
      )}
      {isEditGroupVisible && (
        <GroupEditPanel onClose={hideEditGroup} id={entity.id} />
      )}
      {isCreateMarginVisible && sportId && (
        <MarginCreatePanel
          onClose={hideCreateMargin}
          sportId={sportId}
          live={live}
          id={id}
          type={matchToEntityType[type]}
          existingCategories={marginItems.map(
            margin => margin.outcomeCategoryId,
          )}
        />
      )}
      {isTournamentDependencyVisible && (
        <TournamentDependencyPanel id={id} onClose={hideTournamentDependency} />
      )}
    </S.Content>
  );
};
