import { uniqueId } from 'lodash-es';

export const finalPeriodsInitialState = [
  {
    id: uniqueId(),
    score: {
      team1: 0,
      team2: 0,
    },
  },
  {
    id: uniqueId(),
    score: {
      team1: 0,
      team2: 0,
    },
  },
];
export const partialPeriodsInitialState = [
  {
    id: uniqueId(),
    score: {
      team1: 0,
      team2: 0,
    },
  },
  {
    id: uniqueId(),
    score: {
      team1: 0,
      team2: 0,
    },
  },
  {
    id: uniqueId(),
    score: {
      team1: 0,
      team2: 0,
    },
  },
];
