import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Column } from 'react-table';
import { SOURCES_WITH_CALCULATORS } from 'app/pages/Dictionary/Calculators/constants';
import { OutcomeType } from 'sportsbook-openapi-react';

import { useRequestState } from 'hooks/useRequestState';
import { FETCH_ALL_LIMIT } from 'consts';
import { RootState } from 'types';

import { CalculatorsPanel } from 'app/components/panels';
import { Button, LoadingIndicator, Span, Table } from 'app/components/ui';
import { ColumnWidth } from 'app/components/ui/Table/Table.types';

import { actionsNT, Entities } from 'app/providers/EntitiesProvider';
import { getCategoryById } from 'app/providers/EntitiesProvider/categories';
import { selectCalculatorsByOutcomeIdTypeAndSource } from 'app/providers/EntitiesProvider/oddOutcomeCalculatorRules';
import { selectTypesArrayByCategoryId } from 'app/providers/EntitiesProvider/type';

import * as S from './CalculatorsContent.styles';

type Match = {
  sportId: string;
  categoryId: string;
};

export const CalculatorsContent: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useParams<Match>();
  const { sportId, categoryId } = params;

  const [outcomeType, setOutcomeType] = useState<OutcomeType | null>(null);

  const { isLoading } = useRequestState(Entities.TYPES, 'fetchItems');

  const category = useSelector((state: RootState) =>
    getCategoryById(state, +categoryId!),
  );
  const types = useSelector((state: RootState) =>
    selectTypesArrayByCategoryId(state, +categoryId!),
  );

  useEffect(() => {
    const typesIds = types.map(type => type.id);

    dispatch(actionsNT.oddOutcomeCalculatorRulesReset());
    typesIds.map(id =>
      dispatch(
        actionsNT.oddOutcomeCalculatorRulesFetchItems({
          outcomeTypeId: id,
          limit: FETCH_ALL_LIMIT,
          sportId: +sportId!,
        }),
      ),
    );
  }, [dispatch, sportId, types]);

  useEffect(() => {
    if (!!categoryId) {
      dispatch(
        actionsNT.typesFetchItems({
          categoryId: +categoryId,
          limit: FETCH_ALL_LIMIT,
        }),
      );
    }
  }, [dispatch, categoryId]);

  const columns: Column<OutcomeType>[] = [
    {
      Header: t('name').toString(),
      accessor: 'code',
    },
    ...SOURCES_WITH_CALCULATORS.map(
      source =>
        ({
          id: source,
          Header: source.replace('SOURCE_', ''),
          accessor: 'id',
          Cell: ({ value }) => {
            const calculator = useSelector((state: RootState) =>
              selectCalculatorsByOutcomeIdTypeAndSource(state, value, source),
            );
            const calculatorType = calculator?.calculatorType
              .replace('TYPE_', '')
              .toLowerCase();

            return <S.CalculatorType>{calculatorType ?? ''}</S.CalculatorType>;
          },
        }) as Column<OutcomeType>,
    ),
    {
      Header: '',
      id: 'action',
      Cell: ({ row: { original: data } }) => (
        <Button
          color="action"
          onClick={() => {
            setOutcomeType(data);
          }}
        >
          {t('params')}
        </Button>
      ),
    },
  ];

  const columnsWidth: ColumnWidth[] = [
    'auto',
    ...Array(columns.length - 1).fill(8),
    'auto',
  ];

  return (
    <S.Content>
      <S.Title>
        <Span fontSize={18} fontWeight={500}>
          {category && category.name}
        </Span>
      </S.Title>
      {isLoading ? (
        <LoadingIndicator type="full" />
      ) : (
        <Table<OutcomeType>
          columns={columns}
          data={types}
          columnsWidth={columnsWidth}
        />
      )}
      {!!outcomeType && (
        <CalculatorsPanel
          onClose={() => {
            setOutcomeType(null);
          }}
          outcomeType={outcomeType}
          sportId={+sportId!}
        />
      )}
    </S.Content>
  );
};
