import { forwardRef } from 'react';

import { getTestId } from 'utils/getTestId';

import { IconWrapper, InputWrapper, StyledInput } from './TextInput.styles';
import { TextInputProps } from './TextInput.types';

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  ({ size = 's', icon, testId, ...props }, ref) => {
    return (
      <InputWrapper size={size}>
        <StyledInput
          {...props}
          ref={ref}
          data-test-id={getTestId(testId, 'input')}
        />
        {icon && <IconWrapper>{icon}</IconWrapper>}
      </InputWrapper>
    );
  },
);
