import { TestIds } from 'types/testIds.types';

import { Checkbox } from 'app/components/forms';

interface Props {
  categoryId: number;
  blocked: boolean;
  updateCategoryCashoutBlock: (
    categoryId: number,
    cashoutBlocked: boolean,
  ) => void;
}

const CashoutCell = ({
  categoryId,
  blocked,
  updateCategoryCashoutBlock,
}: Props) => {
  const onChange = (checked: boolean) => {
    updateCategoryCashoutBlock(categoryId, checked);
  };

  return (
    <div onClick={event => event.stopPropagation()}>
      <Checkbox
        checked={blocked}
        onChange={onChange}
        label=""
        testId={TestIds.CategoriesTableCashoutCheckbox}
      />
    </div>
  );
};

export default CashoutCell;
