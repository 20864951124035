import { useTranslation } from 'react-i18next';
import { Event } from 'sportsbook-openapi-react';

import { useUpdateEvent } from 'hooks/events';
import { useRequestState } from 'hooks/useRequestState';
import { TestIds } from 'types/testIds.types';

import { Button } from 'app/components/ui';

import { Entities } from 'app/providers/EntitiesProvider';

interface Props {
  event: Event;
}

export function AutocalculationButton({ event }: Props) {
  const { t } = useTranslation();
  const updateEvent = useUpdateEvent();
  const { isLoading } = useRequestState(Entities.EVENTS, 'update');

  const handleClick = () => {
    updateEvent({
      ...event,
      resultsAutoCalculationEnabled: !event.resultsAutoCalculationEnabled,
    });
  };

  return (
    <Button
      color="action"
      onClick={handleClick}
      testId={TestIds.EventPageAutocalculationButton}
      disabled={isLoading}
    >
      {t(
        event.resultsAutoCalculationEnabled
          ? 'disable autocalculation'
          : 'enable autocalculation',
      )}
    </Button>
  );
}
