import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Column } from 'react-table';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { keyBy } from 'lodash-es';
import values from 'lodash-es/values';
import { OutcomeType, UserGroupEnum } from 'sportsbook-openapi-react';

import { useAudit } from 'hooks/audit/useAudit';
import useBooleanState from 'hooks/useBooleanState';
import { useRequestState } from 'hooks/useRequestState';
import { FETCH_ALL_LIMIT, RU_LANG_CODE } from 'consts';
import { RootState } from 'types';
import { TestIds } from 'types/testIds.types';
import { useTheme } from 'styles';

import { OutcomeCreatePanel, OutcomeEditPanel } from 'app/components/panels';
import { RoleGuards } from 'app/components/RoleGuards';
import { Button, LoadingIndicator, Span, Table } from 'app/components/ui';

import { AuditedEntities } from 'app/providers/AuditProvider';
import { actionsNT, Entities } from 'app/providers/EntitiesProvider';
import { getCategoryById } from 'app/providers/EntitiesProvider/categories';
import { getMarketsEntries } from 'app/providers/EntitiesProvider/markets';
import { getTypesByCategoryId } from 'app/providers/EntitiesProvider/type';

import { useUserStore } from 'store';
import { getIsOperator, getIsTranslator } from 'store/user';

import * as S from './Content.styles';

type Match = {
  id: string;
  outcomeId?: string;
};

export const OutcomesContent: FC = () => {
  const { t } = useTranslation();
  const params = useParams<Match>();
  const dispatch = useDispatch();
  const theme = useTheme();

  const [isCreateOutcomeVisible, showCreateOutcome, hideCreateOutcome] =
    useBooleanState();
  const [editOutcomeId, setEditOutcomeId] = useState<null | number>(null);

  const { id } = params;
  const isOperator = useUserStore(getIsOperator);
  const isTranslator = useUserStore(getIsTranslator);

  if (!params.id) {
    throw new Error('Provide id and type path parameters');
  }

  const category = useSelector((state: RootState) =>
    getCategoryById(state, +id!),
  );

  useEffect(() => {
    if (!isTranslator) {
      dispatch(
        actionsNT.marketsFetchItems({
          categoryId: category?.id,
          limit: FETCH_ALL_LIMIT,
        }),
      );
    }
  }, [category?.id, dispatch, isTranslator]);

  useEffect(() => {
    dispatch(
      actionsNT.typesFetchItems({ categoryId: +id!, limit: FETCH_ALL_LIMIT }),
    );
  }, [dispatch, id]);

  const { isLoading } = useRequestState(Entities.TYPES, 'fetchItems');

  const types = useSelector((state: RootState) =>
    values(getTypesByCategoryId(state, +id!)),
  );

  const markets = useSelector(getMarketsEntries);

  const openCategoriesAudit = useAudit({
    entity: AuditedEntities.OUTCOME_CATEGORIES,
  });

  const openOutcomeTypesAudit = useAudit({
    entity: AuditedEntities.OUTCOME_TYPES,
  });

  const columns: Column<OutcomeType>[] = useMemo(
    () => [
      { Header: t('outcome code').toString(), accessor: 'code' },
      {
        Header: t('short name').toString(),
        accessor: 'shortNames',
        Cell: ({ value }) => {
          const shortNames = keyBy(value, 'langCode');
          return <>{shortNames[RU_LANG_CODE]?.value}</>;
        },
      },
      { Header: t('name').toString(), accessor: 'name' },
      ...(!isTranslator
        ? [
            {
              Header: t('market').toString(),
              accessor: 'marketId' as keyof OutcomeType,
              Cell: ({ value }) => {
                return <>{markets[value]?.name}</>;
              },
            },
            {
              Header: t('market type').toString(),
              accessor: 'marketLink' as keyof OutcomeType,
            },
            ...(!isOperator
              ? [
                  {
                    Header: '',
                    id: 'action',
                    Cell: ({ row: { original: data } }) => (
                      <S.ButtonsWrapper>
                        <Button
                          testId={`${TestIds.OutcomesPageOutcomeEditButton}--${data.name}`}
                          color="action"
                          onClick={() => {
                            setEditOutcomeId(data.id);
                          }}
                        >
                          {t('edit')}
                        </Button>
                        <Button
                          testId={`${TestIds.OutcomesPageOutcomeAuditButton}--${data.name}`}
                          color="action"
                          onClick={() => {
                            openOutcomeTypesAudit({
                              id: String(data.id),
                              includeRelated: false,
                            });
                          }}
                        >
                          {t('audit')}
                        </Button>
                      </S.ButtonsWrapper>
                    ),
                  },
                ]
              : []),
          ]
        : []),
    ],
    [isOperator, isTranslator, markets, openOutcomeTypesAudit, t],
  );

  return (
    <S.Content>
      <S.Title>
        <Span fontSize={18} fontWeight={500}>
          {category && category.name}
        </Span>
        <RoleGuards
          roles={[
            UserGroupEnum.ADMIN,
            UserGroupEnum.CUSTOMER,
            UserGroupEnum.SUPERVISOR,
          ]}
        >
          <Button
            testId={TestIds.OutcomesPageCategoryAuditButton}
            color="action"
            onClick={() => {
              openCategoriesAudit({
                id: String(category.id),
                includeRelated: false,
              });
            }}
          >
            {t('audit')}
          </Button>
        </RoleGuards>
      </S.Title>
      <RoleGuards
        roles={[
          UserGroupEnum.ADMIN,
          UserGroupEnum.CUSTOMER,
          UserGroupEnum.SUPERVISOR,
        ]}
      >
        <S.StyledButton
          color="action"
          onClick={showCreateOutcome}
          testId={TestIds.OutcomesPageAddOutcomeButton}
        >
          <FontAwesomeIcon icon={faPlus} />
          {t('add outcome')}
        </S.StyledButton>
      </RoleGuards>
      {isLoading ? (
        <LoadingIndicator type="full" />
      ) : (
        <Table
          testId={TestIds.OutcomesPageOutcomesTable}
          testFieldName="name"
          columns={columns}
          data={types}
          columnsWidth={
            !isTranslator
              ? ['auto', 20, 20, 20, 20, 'auto']
              : ['fit-content', 'fit-content', 'auto']
          }
          rowStyles={row =>
            row.disabled
              ? `background-color: ${theme.colors.primary[50]} !important;
            &:hover {
              background-color: ${theme.colors.primary[50]} !important;
            }
            `
              : ''
          }
        />
      )}
      {isCreateOutcomeVisible && (
        <OutcomeCreatePanel
          onClose={hideCreateOutcome}
          categoryId={category.id}
        />
      )}
      {!!editOutcomeId && (
        <OutcomeEditPanel
          onClose={() => {
            setEditOutcomeId(null);
          }}
          id={editOutcomeId}
        />
      )}
    </S.Content>
  );
};
