export enum TestIds {
  SidebarMenuButton = 'sidebar-menu-button',
  HeaderDeleteFavoriteButton = 'header-delete-favorite-button',
  HeaderLogoutButton = 'header-logout-button',

  EventFormSportSelect = 'event-form-sport-select',
  EventFormGroupSelect = 'event-form-group-select',
  EventFormDateDatepicker = 'event-form-date-datepicker',
  EventFormTimeDatepicker = 'event-form-time-datepicker',
  EventFormTournamentSelect = 'event-form-tournament-select',
  EventFormHomeCompetitorSelect = 'event-form-home-competitor-select',
  EventFormAwayCompetitorSelect = 'event-form-away-competitor-select',
  EventFormStatusSelect = 'event-form-status-select',
  EventFormMatchTypeSelect = 'event-form-match-type-select',
  EventFormKindSelect = 'event-form-kind-select',
  EventFormNoteField = 'event-form-note-field',
  EventFormCancelButton = 'event-form-cancel-field',
  EventFormSubmitButton = 'event-form-submit-field',

  EventPageHeader = 'event-page-header',
  EventPageEventId = 'event-page-event-id',
  EventPageStatusButton = 'event-page-status-button',
  EventPageStatusOption = 'event-page-status-option',
  EventPageBackButton = 'event-page-back-button',
  EventPageEditButton = 'event-page-edit-button',
  EventPageFavoriteButton = 'event-page-favorite-button',
  EventPageAutocalculationButton = 'event-page-autocalculation-button',
  EventPageLiveButton = 'event-page-live-button',
  EventPageAuditButton = 'event-page-audit-button',
  EventPageResultsButton = 'event-page-results-button',
  EventPageUpdateButton = 'event-page-update-button',
  EventPageModelsCalculateSelect = 'event-page-models-calculate-select',

  EventPageLiveInfo = 'event-page-live-info',
  EventPageLiveInfoCompetitors = 'event-page-live-info-competitors',
  EventPageLiveInfoMatchPhase = 'event-page-live-info-match-phase',
  EventPageLiveInfoMatchTime = 'event-page-live-info-match-time',
  EventPageLiveInfoPeriodScore = 'event-page-live-info-period-score',
  EventPageLiveInfoGameScore = 'event-page-live-info-game-score',
  EventPageLiveInfoRemainingTime = 'event-page-live-info-remaining-time',
  EventPageLiveInfoCurrentServer = 'event-page-live-info-current-server',
  EventPageLiveInfoCT = 'event-page-live-info-CT',

  EventPageInfo = 'event-page-info',
  EventPageInfoSport = 'event-page-info-sport',
  EventPageInfoCountry = 'event-page-info-country',
  EventPageInfoGroup = 'event-page-info-group',
  EventPageInfoDatetime = 'event-page-info-datetime',
  EventPageInfoTournament = 'event-page-info-tournament',
  EventPageInfoCompetitors = 'event-page-info-competitors',
  EventPageInfoMatchType = 'event-page-info-match-type',
  EventPageInfoDependencies = 'event-page-info-dependencies',
  EventPageInfoNote = 'event-page-info-note',
  EventPageInfoTags = 'event-page-info-tags',
  EventPageInfoComments = 'event-page-info-comments',
  EventPageInfoType = 'event-page-info-type',

  EventsPageDateFilter = 'events-page-date-filter',
  EventsPageNotBeganCheckbox = 'events-page-not-began-checkbox',
  EventsPageFavoriteCheckbox = 'events-page-favorite-checkbox',
  EventsPageResetFiltersButton = 'events-page-reset-filters-button',
  EventsPageTable = 'events-page-table',
  ResultsPageNotResultedCheckbox = 'events-page-not-resulted-checkbox',

  DictionaryDropqownWrapper = 'dictionary-dropdown-wrapper',
  DictionaryDropdownAddButton = 'dictionary-dropdown-add-button',
  DictionaryDropqownList = 'dictionary-dropdown-list',

  SportPanelNameInput = 'sport-panel-name-input',
  SportPanelShortNameInput = 'sport-panel-short-name-input',
  SportPanelMainSportSelect = 'sport-panel-main-sport-select',
  GroupPanelNameInput = 'group-panel-name-input',
  GroupPanelSportSelect = 'group-panel-sport-select',

  TournamentPanelName = 'tournament-panel-name',
  TournamentPanelSportSelect = 'tournament-panel-sport-select',
  TournamentPanelGroupSelect = 'tournament-panel-group-select',
  TournamentPanelCountrySelect = 'tournament-panel-country-select',
  TournamentPanelKindSelect = 'tournament-panel-kind-select',
  TournamentPanelMainTournamentSelect = 'tournament-panel-main-tournament-select',

  SportPageSearch = 'sport-page-search',
  SportPageNestedList = 'sport-page-nested-list',
  SportPageNestedListSearchResult = 'sport-page-nested-list-search-result',
  SportPageNestedListAddButton = 'sport-page-nested-list-add-button',
  SportPageNestedListChildrenWrapper = 'sport-page-nested-list-children-wrapper',
  SportPageEditButton = 'sport-page-edit-button',
  SportPageDependencyButton = 'sport-page-dependency-button',
  SportPageAuditButton = 'sport-page-audit-button',
  SportPageCommentsSelect = 'sport-page-comments-select',
  SportPageTabType = 'sport-page-tab-type',
  SportPageAddCategoryButton = 'sport-page-add-category-button',
  SportPageCategoriesTable = 'sport-page-categories-table',
  SportPageFieldName = 'sport-page-field-name',
  SportPageFieldValue = 'sport-page-field-value',
  SportPageIncludedButton = 'sport-page-included-button',
  SportPageExcludedButton = 'sport-page-excluded-button',
  SportPageTagsSelect = 'sport-page-tags-select',

  CategoriesTableProgressionSelect = 'categories-table-progression-select',
  CategoriesTableMaxbetCheckbox = 'categories-table-maxbet-checkbox',
  CategoriesTableExclusionCheckbox = 'categories-table-exclusion-checkbox',
  CategoriesTableCashoutCheckbox = 'categories-table-cashout-checkbox',
  CategoriesTableDeleteButton = 'categories-table-delete-button',

  PanelWrapper = 'panel-wrapper',
  PanelCloseButton = 'panel-close-button',
  PanelSubmitButton = 'panel-submit-button',
  PanelDisableButton = 'panel-disable-button',
  TableSortingUpButton = 'table-sorting-up-button',
  TableSortingDownButton = 'table-sorting-down-button',
  SelectOption = 'select-option',
  DialogWrapper = 'dialog-wrapper',
  DialogConfirmButton = 'dialog-confirm-button',
  DialogCancelButton = 'dialog-cancel-button',

  CompetitorsPageAddButton = 'sompetitors-page-add-button',
  CompetitorsPageSearchInput = 'competitors-page-search',
  CompetitorsPageSportSelect = 'competitors-page-sport-select',
  CompetitorsPageCountrySelect = 'competitors-page-country-select',
  CompetitorsPageTable = 'competitors-page-table',
  CompetitorsPageEditButton = 'competitors-page-edit-button',
  CompetitorsPageAuditButton = 'competitors-page-audit-button',
  CompetitorsPanelNameInput = 'competitors-panel-name',
  CompetitorsPanelSportSelect = 'competitors-panel-sport-select',
  CompetitorsPanelCountrySelect = 'competitors-panel-country-select',
  CompetitorsPanelTypeSelect = 'competitors-panel-type-select',
  CompetitorsPanelPlayerSelect = 'competitors-panel-player-select',

  MatchTypesPageAddButton = 'match-types-page-add-button',
  MatchTypesPageSearchInput = 'match-types-page-search',
  MatchTypesPageTable = 'match-types-page-table',
  MatchTypesPanelName = 'match-types-panel-name',
  MatchTypesPanelCode = 'match-types-panel-code',
  MatchTypesPanelSportList = 'match-types-panel-sport-list',
  MatchTypesPageEditButton = 'match-types-page-edit-button',
  MatchTypesPageAuditButton = 'match-types-page-audit-button',

  MatchPhasesPageAddButton = 'match-phases-page-add-button',
  MatchPhasesPageSearchInput = 'match-phases-page-search',
  MatchPhasesPageTable = 'match-phases-page-table',
  MatchPhasesPanelName = 'match-phases-panel-name',
  MatchPhasesPanelCode = 'match-phases-panel-code',
  MatchPhasesPanelSportList = 'match-phases-panel-sport-list',
  MatchPhasesPageEditButton = 'match-phases-page-edit-button',
  MatchPhasesPageAuditButton = 'match-phases-page-audit-button',

  CountriesPageAddButton = 'countries-page-add-button',
  CountriesPageSearchInput = 'countries-page-search',
  CountriesPageTable = 'countries-page-table',
  CountriesPageLogosButton = 'countries-page-logos-button',
  CountriesPageAuditButton = 'countries-page-audit-button',
  CountriesPanelNameInput = 'countries-panel-name-input',
  CountriesPanelShortNameInput = 'countries-panel-short-name-input',
  CountriesPanelCodeInput = 'countries-panel-code-input',
  CountriesPanelSportList = 'countries-panel-sport-list',

  MarketsPageAddButton = 'markets-page-add-button',
  MarketsPageSearchInput = 'markets-page-search',
  MarketsPageTable = 'markets-page-table',
  MarketsPageEditButton = 'markets-page-edit-button',
  MarketsPageAuditButton = 'markets-page-audit-button',
  MarketsPanelName = 'markets-panel-name',
  MarketsPanelMarkerTypeSelect = 'markets-panel-market-type-select',
  MarketsPanelCategorySelect = 'markets-panel-category-select',
  MarketsPanelParamsSelect = 'markets-panel-params-select',

  LanguagesPageTable = 'languages-page-table',
  LanguagesPageSearch = 'languages-page-search',
  LanguagesPageAddButton = 'languages-page-add-button',
  LanguagesPageAuditButton = 'languages-page-audit-button',
  LanguagesPanelName = 'languages-panel-name',
  LanguagesPanelCode = 'languages-panel-code',

  OutcomesPageCreateCategoryButton = 'outcomes-page-create-category-button',
  OutcomesPageSearch = 'outcomes-page-search',
  OutcomesPageSportSelect = 'outcomes-page-sport-select',
  OutcomesPageCategoriesList = 'outcomes-page-categories-list',
  OutcomesPageCategoryEditButton = 'outcomes-page-category-edit-button',
  OutcomesPageCategoryAuditButton = 'outcomes-page-category-audit-button',
  OutcomesPageAddOutcomeButton = 'outcomes-page-add-outcome-button',
  OutcomesPageOutcomesTable = 'outcomes-page-outcomes-table',
  OutcomesPageOutcomeEditButton = 'outcomes-page-edit-button',
  OutcomesPageOutcomeAuditButton = 'outcomes-page-outcome-audit-button',

  OutcomePanelName = 'outcome-panel-name',
  OutcomePanelCategorySelect = 'outcome-panel-category-select',
  OutcomePanelMarketSelect = 'outcome-panel-market-select',
  OutcomePanelMarketTypeSelect = 'outcome-panel-market-type-select',
  OutcomePanelCode = 'outcome-panel-code',
  OutcomePanelShortName = 'outcome-panel-short-name',
  OutcomePanelPriorityOrder = 'outcome-panel-priority-order',

  CategoryPanelName = 'category-panel-name',
  CategoryPanelPriorityOrder = 'category-panel-priority-order',
  CategoryPanelSportsList = 'category-panel-sports-list',

  ActiveCategoryPanelCreateButton = 'active-category-panel-create-button',
  ActiveCategoryPanelList = 'active-category-panel-list',
  ActiveCategoryPanelSearch = 'active-category-panel-search',
  ActiveCategoryPanelMargin = 'active-category-panel-margin',
  ActiveCategoryPanelMaxodd = 'active-category-panel-maxodd',
  ActiveCategoryPanelDelay = 'active-category-panel-delay',
  ActiveCategoryPanelMaxbet = 'active-category-panel-maxbet',
  ActiveCategoryPanelMainSource = 'active-category-panel-main-source',

  TagsPageTable = 'tags-page-table',
  TagsPageSearch = 'tags-page-search',
  TagsPageAddButton = 'tags-page-add-button',
  TagsPageAuditButton = 'tags-page-audit-button',
  TagsPageDeleteButton = 'tags-page-delete-button',
  TagsPageEditButton = 'tags-page-edit-button',
  TagsPanelName = 'tags-panel-name',

  CommentsPageTable = 'comments-page-table',
  CommentsPageSearch = 'comments-page-search',
  CommentsPageAddButton = 'comments-page-add-button',
  CommentsPageAuditButton = 'comments-page-audit-button',
  CommentsPageEditButton = 'comments-page-edit-button',
  CommentsPanelName = 'comments-panel-name',

  UsersPageTable = 'users-page-table',
  UsersPageSearch = 'users-page-search',
  UsersPageAddButton = 'users-page-add-button',
  UsersPageAuditButton = 'users-page-audit-button',
  UsersPageEditButton = 'users-page-edit-button',
  UsersPageDeleteButton = 'users-page-delete-button',
  UsersPageUpdateButton = 'users-page-update-button',
  UsersPageAccessSelect = 'users-page-access-select',
  UsersPanelName = 'users-panel-name',
  UsersPanelEmail = 'users-panel-email',
  UsersPanelAccessSelect = 'users-panel-access-select',
  UsersPanelCustomerSelect = 'users-panel-customer-select',

  SourcesPageTable = 'sources-page-table',
  SourcesPageSportFilter = 'sources-page-sport-filter',
  SourcesPageEventsFilter = 'sources-page-events-filter',
  SourcesPageResetButton = 'sources-page-reset-button',
  MappedEventTable = 'mapped-event-table',
  MappedEventFormSport = 'mapped-event-form-sport',
  MappedEventFormTournament = 'mapped-event-form-tournament',
  MappedEventFormTournamentAdd = 'mapped-event-form-tournament-add',
  MappedEventFormCompetitorA = 'mapped-event-form-competitorA',
  MappedEventFormCompetitorAAdd = 'mapped-event-form-competitorA-add',
  MappedEventFormCompetitorB = 'mapped-event-form-competitorB',
  MappedEventFormCompetitorBAdd = 'mapped-event-form-competitorB-add',
  MappedEventFormCreatCrossButton = 'mapped-event-form-create-cross-button',
  MappedEventFormCreateButton = 'mapped-event-form-create-button',
  MappedEventFormCrossMapButton = 'mapped-event-form-cross-map-button',
  MappedEventFormMapButton = 'mapped-event-form-map-button',

  ProgressionsPageSportsList = 'progressions-page-sport-list',
  ProgressionsPageSearch = 'progressions-page-sport-search',
  ProgressionsPageTable = 'progressions-page-table',
  ProgressionsPageParamsButton = 'progressions-page-params-button',
  ProgressionPanelName = 'progression-panel-name',
  ProgressionPanelSport = 'progression-panel-sport',
  ProgressionPanelStep = 'progression-panel-step',
  ProgressionPanelStepValue = 'progression-panel-step-value',

  GeneralValuesPopupDropdown = 'general-values-popup-dropdown',
  GeneralValuesPopupOption = 'general-values-popup-option',
  GeneralValuesFormField = 'general-values-form-field',
  GeneralValuesFormButton = 'general-values-form-button',
}
