import { useTranslation } from 'react-i18next';
import { FormikProps, useFormikContext } from 'formik';
import { OutcomeCategory, OutcomeMarket } from 'sportsbook-openapi-react';

import { getAsyncLoadOptions } from 'utils/select';
import useBooleanState from 'hooks/useBooleanState';
import { TestIds } from 'types/testIds.types';

import {
  AsyncSelectField,
  SelectField,
  TextInputField,
} from 'app/components/forms';
import { CategoryCreatePanel } from 'app/components/panels/category';
import { MarketCreatePanel } from 'app/components/panels/market';
import { Span } from 'app/components/ui';

import { FormValues, marketLinkOptions } from './constants';
import * as S from './styles';

interface Props extends FormikProps<FormValues> {
  categories: Dictionary<OutcomeCategory>;
}

export const OutcomeForm = ({ categories, setFieldValue }: Props) => {
  const { t } = useTranslation();
  const [isCreateCategoryVisible, showCreateCategory, hideCreateCategory] =
    useBooleanState();
  const [isCreateMarketVisible, showCreateMarket, hideCreateMarket] =
    useBooleanState();

  const {
    values: { category },
  } = useFormikContext<FormValues>();

  const createCategoryOption: any = {
    label: t('create'),
    isButton: true,
    notSelectable: true,
    onClick: showCreateCategory,
  };
  const createMarketOption = {
    label: t('create'),
    isButton: true,
    notSelectable: true,
    onClick: showCreateMarket,
  };
  const categoriesOptions = [
    createCategoryOption,
    ...Object.values(categories),
  ];

  const handleCategorySelect = (newCategory: OutcomeCategory) => {
    setFieldValue('category', newCategory);
  };
  const handleMarketSelect = (newMarket: OutcomeMarket) => {
    setFieldValue('market', newMarket);
  };

  return (
    <>
      <S.FormBlock>
        <Span>{t('name')}</Span>
        <TextInputField name="name" testId={TestIds.OutcomePanelName} />
      </S.FormBlock>
      <S.FormBlock>
        <Span>{t('category')}</Span>
        <SelectField
          name="category"
          options={categoriesOptions}
          testId={TestIds.OutcomePanelCategorySelect}
        />
      </S.FormBlock>
      <S.FormBlock>
        <Span>{t('market')}</Span>
        <AsyncSelectField
          testId={TestIds.OutcomePanelMarketSelect}
          name="market"
          loadOptionsOnMenuOpen
          debounceTimeout={200}
          loadOptions={getAsyncLoadOptions({
            entity: 'markets',
            createOption: createMarketOption,
            categoryId: category?.id,
          })}
          cacheUniqs={[category?.id]}
        />
      </S.FormBlock>
      <S.FormBlock>
        <Span>{t('market link')}</Span>
        <SelectField
          name="marketLink"
          options={marketLinkOptions}
          testId={TestIds.OutcomePanelMarketTypeSelect}
        />
      </S.FormBlock>
      <S.FormBlock>
        <Span>{t('outcome code')}</Span>
        <TextInputField name="code" testId={TestIds.OutcomePanelCode} />
      </S.FormBlock>
      <S.FormBlock>
        <Span>{t('short name')}</Span>
        <TextInputField
          name="shortName"
          testId={TestIds.OutcomePanelShortName}
        />
      </S.FormBlock>
      <S.FormBlock>
        <Span>{t('priority order')}</Span>
        <TextInputField
          name="priorityOrder"
          type="number"
          testId={TestIds.OutcomePanelPriorityOrder}
        />
      </S.FormBlock>
      {isCreateCategoryVisible && (
        <CategoryCreatePanel
          onClose={hideCreateCategory}
          onSuccess={handleCategorySelect}
        />
      )}
      {isCreateMarketVisible && (
        <MarketCreatePanel
          onClose={hideCreateMarket}
          onSuccess={handleMarketSelect}
        />
      )}
    </>
  );
};
