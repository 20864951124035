import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Column } from 'react-table';
import { MarginProgression } from 'sportsbook-openapi-react';

import useBooleanState from 'hooks/useBooleanState';
import { TestIds } from 'types/testIds.types';
import { useTheme } from 'styles';

import {
  ProgressionCreatePanel,
  ProgressionEditPanel,
} from 'app/components/panels/progressions';
import { Button, Table, Text } from 'app/components/ui';

import { actionsNT } from 'app/providers/EntitiesProvider';
import { selectMarginProgressionItemsBySport } from 'app/providers/EntitiesProvider/marginProgressions';
import { getSportById } from 'app/providers/EntitiesProvider/sports';

import useUserStore, { getIsAdmin } from 'store/user';

import * as S from './Content.styles';

type Match = {
  sportId?: string;
};

export const ProgressionsContent = () => {
  const params = useParams<Match>();
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const isAdmin = useUserStore(getIsAdmin);
  const [
    isCreateProgressionVisible,
    showCreateProgression,
    hideCreateProgression,
  ] = useBooleanState();

  const [editProgressionPanel, setEditProgressionPanel] = useState<null | {
    id: number;
  }>(null);

  const { sportId } = params;

  const items = useSelector(state => {
    if (!sportId) return [];
    return selectMarginProgressionItemsBySport(state, +sportId);
  });

  const sport = useSelector(state => {
    if (!sportId) return undefined;
    return getSportById(state, +sportId);
  });

  useEffect(() => {
    if (sportId) {
      dispatch(
        actionsNT.marginProgressionsFetchItems({
          sportId: [+sportId],
        }),
      );
    }
  }, [sportId, dispatch]);

  const columns = useMemo<Column<MarginProgression>[]>(
    () => [
      {
        Header: t('name').toString(),
        accessor: 'name',
      },
      {
        Header: t('step 1').toString(),
        Cell: ({ row: { original: data } }) => <span>{data.steps[0]}</span>,
      },
      {
        Header: t('step 2').toString(),
        Cell: ({ row: { original: data } }) => <span>{data.steps[1]}</span>,
      },
      {
        Header: t('step 3').toString(),
        Cell: ({ row: { original: data } }) => <span>{data.steps[2]}</span>,
      },
      {
        Header: t('step 4').toString(),
        Cell: ({ row: { original: data } }) => <span>{data.steps[3]}</span>,
      },
      {
        Header: t('step 5').toString(),
        Cell: ({ row: { original: data } }) => <span>{data.steps[4]}</span>,
      },
      {
        Header: '',
        accessor: 'id',
        id: 'action',
        Cell: ({ row: { original: data } }) => (
          <S.StyledButton
            testId={`${TestIds.ProgressionsPageParamsButton}--${data.name}`}
            color="action"
            onClick={() => {
              setEditProgressionPanel({
                id: data.id,
              });
            }}
          >
            {t('params')}
          </S.StyledButton>
        ),
      },
    ],
    [t],
  );

  if (!sport) return null;

  return (
    <>
      <S.Content>
        <S.ContentBlock>
          <S.ContentHead>
            <Text size="h3">{sport.name}</Text>
            <S.Spacer />
            {isAdmin && (
              <S.ButtonsWrapper>
                <Button color="action" onClick={showCreateProgression}>
                  {t('create progression')}
                </Button>
              </S.ButtonsWrapper>
            )}
          </S.ContentHead>
        </S.ContentBlock>
        <Table
          testId={TestIds.ProgressionsPageTable}
          testFieldName="name"
          columns={columns}
          data={items}
          rowStyles={() =>
            `&:hover {
              background-color: ${theme.colors.primary[50]} !important;
            }
            `
          }
          columnsWidth={['auto', 15, 15, 15, 15, 15, 'fit-content']}
        />
      </S.Content>
      {isCreateProgressionVisible && (
        <ProgressionCreatePanel onClose={hideCreateProgression} />
      )}
      {!!editProgressionPanel && (
        <ProgressionEditPanel
          onClose={() => {
            setEditProgressionPanel(null);
          }}
          id={editProgressionPanel.id}
        />
      )}
    </>
  );
};
