import { marginProgressionApi } from 'app/api';

const api = {
  endpoint: marginProgressionApi,
  methods: {
    fetchItems: 'getMarginProgressionOutcomeCategories',
    create: 'createMarginProgressionToOutcomeCategory',
    update: 'updateMarginProgressionOutcomeCategory',
    delete: 'deleteMarginProgressionOutcomeCategory',
  },
};

export default api;
