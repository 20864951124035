import styled from 'styled-components';

import { TextInputField } from 'app/components/forms';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 908px;
  gap: 16px;
`;

export const StatisticsFormWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  flex-wrap: wrap;
`;

export const InputWraper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const StyledInput = styled(TextInputField)`
  width: 100%;
`;

export const PeriodItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const PeriodInputsBlock = styled.div`
  display: flex;
  width: 292px;
  gap: 8px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const InputItem = styled.div`
  display: flex;
  gap: 8px;
`;

export const OddsTableContainer = styled.div`
  display: grid;
  height: 296px;
  grid-template-columns: 180px 1fr;
  grid-template-rows: minmax(0, 1fr);
  border-top: 1px solid ${p => p.theme.colors.primary[40]};
`;

export const OddsTable = styled.div`
  display: grid;
  grid-template-rows: minmax(0, 1fr);
`;

export const Footer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
`;

export const CloseButton = styled.div`
  display: flex;
  justify-content: center;
`;
