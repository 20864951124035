import { useTranslation } from 'react-i18next';

import { TestIds } from 'types/testIds.types';

import { TextInputField } from 'app/components/forms';
import { Span } from 'app/components/ui';

import * as S from './styles';

export const CommentForm = () => {
  const { t } = useTranslation();

  return (
    <S.FormBlock>
      <Span>{t('name')}</Span>
      <TextInputField name="name" testId={TestIds.CommentsPanelName} />
    </S.FormBlock>
  );
};
