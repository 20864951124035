import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { MarginProgression } from 'sportsbook-openapi-react';

import { TestIds } from 'types/testIds.types';

import {
  CheckboxField,
  SelectField,
  TextInputField,
} from 'app/components/forms';
import { Span } from 'app/components/ui';
import EqualIcon from 'assets/images/EqualIcon.svg?react';

import { getSportsEntries } from 'app/providers/EntitiesProvider/sports';

import useUserStore, { getIsAdmin } from 'store/user';

import { MAX_STEP_VALUE, MIN_STEP_VALUE, STEP } from './constants';
import * as S from './styles';

interface Props {
  actionType: 'create' | 'edit';
}

export const ProgressionForm = ({ actionType }: Props) => {
  const { t } = useTranslation();
  const sports = useSelector(getSportsEntries);
  const isAdmin = useUserStore(getIsAdmin);
  const {
    values: { steps },
    errors,
  } = useFormikContext<MarginProgression>();

  const readonly = !isAdmin;

  return (
    <>
      {actionType === 'edit' && (
        <S.FormBlock>
          <CheckboxField
            name="enabled"
            label={t('progression is enabled')}
            readOnly={readonly}
          />
        </S.FormBlock>
      )}

      <S.FormBlock>
        <Span>{t('name')}</Span>
        <TextInputField
          name="name"
          readOnly={readonly}
          testId={TestIds.ProgressionPanelName}
        />
      </S.FormBlock>
      <S.FormBlock>
        <Span>{t('sport')}</Span>
        <SelectField
          name="sport"
          options={Object.values(sports)}
          readOnly
          testId={TestIds.ProgressionPanelSport}
        />
      </S.FormBlock>
      <S.Flex>
        <Span color="#6C7993">{t('step')}</Span>
        <div />
        <Span color="#6C7993">{t('margin value')}</Span>
      </S.Flex>
      {[...Array(5).keys()].map(step => (
        <StepRow
          key={step}
          step={step}
          disabled={
            readonly ||
            step > steps.length ||
            steps.slice(0, step).some(s => !s) ||
            (Array.isArray(errors.steps) &&
              errors.steps?.slice(0, step).some(stepError => !!stepError))
          }
        />
      ))}
    </>
  );
};

interface StepRowProps {
  step: number;
  disabled?: boolean;
}

const StepRow = ({ step, disabled }: StepRowProps) => {
  const { t } = useTranslation();
  return (
    <S.StepWrapper>
      <S.StepLabel
        disabled={disabled}
        data-test-id={`${TestIds.ProgressionPanelStep}-${step + 1}`}
      >
        {t(`step ${step + 1}`)}
      </S.StepLabel>
      <S.Spacer>
        <EqualIcon />
      </S.Spacer>
      <S.TextInput
        name={`steps[${step}]`}
        type="number"
        min={MIN_STEP_VALUE}
        max={MAX_STEP_VALUE}
        step={STEP}
        placeholder={t('enter value')}
        disabled={disabled}
        testId={`${TestIds.ProgressionPanelStepValue}-${STEP}`}
      />
    </S.StepWrapper>
  );
};
