import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';

import { getEntities } from 'app/providers/EntitiesProvider/selectors';
import { getEntityItems } from 'app/providers/EntitiesProvider/utils';

const getProgressionOutcomeCategoriesSubstate = (state: RootState) =>
  getEntities(state).marginProgressionOutcomeCategories;

export const getMarginProgressionOutcomeCategoriesEntries = (
  state: RootState,
) => getProgressionOutcomeCategoriesSubstate(state).entries;

export const selectMarginProgressionOutcomeCategoriesItems = createSelector(
  [getProgressionOutcomeCategoriesSubstate],
  marginProgressionOutcomeCategories =>
    getEntityItems(marginProgressionOutcomeCategories),
);
