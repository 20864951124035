import { createSelector } from '@reduxjs/toolkit';
import { OutcomeCategory, Sport } from 'sportsbook-openapi-react';

import { RootState } from 'types';

import { getEntities } from 'app/providers/EntitiesProvider/selectors';
import { getSportsIds } from 'app/providers/EntitiesProvider/sports';
import { getEntityItems } from 'app/providers/EntitiesProvider/utils';

const getCategories = (state: RootState) => getEntities(state).categories;

export const getCategoriesEntries = (state: RootState) =>
  getCategories(state).entries;

export const selectCategoriesItems = createSelector(
  [getCategories],
  categories => getEntityItems(categories),
);

export const selectCategoriesBySportId = createSelector(
  [getCategories, getSportsIds],
  (categories, sports) => {
    const categoriesBySportMap: Record<Sport['id'], OutcomeCategory[]> = {};

    const categoryItems = getEntityItems(categories);

    categoryItems.forEach(category => {
      category.sports.forEach(sportId => {
        if (!!categoriesBySportMap[sportId]) {
          categoriesBySportMap[sportId].push(category);
        } else {
          categoriesBySportMap[sportId] = [category];
        }
      });
    });

    return categoriesBySportMap;
  },
);

export const getCategoryById = (state: RootState, id: number) =>
  getCategoriesEntries(state)[id];
