import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DictionaryDropdown } from 'app/pages/Dictionary/components/DictionaryDropdown';
import { Search } from 'app/pages/Dictionary/Directory/components/Search';
import { groupBy } from 'lodash-es';
import { UserGroupEnum } from 'sportsbook-openapi-react';

import useBooleanState from 'hooks/useBooleanState';
import { FETCH_ALL_LIMIT } from 'consts';
import { TestIds } from 'types/testIds.types';
import { useTheme } from 'styles';

import { NestedList } from 'app/components/forms';
import {
  GroupCreatePanel,
  SportCreatePanel,
  TournamentCreatePanel,
} from 'app/components/panels';
import { RoleGuards } from 'app/components/RoleGuards';
import { Button } from 'app/components/ui';

import { actionsNT } from 'app/providers/EntitiesProvider';
import { selectGroupsItems } from 'app/providers/EntitiesProvider/groups';
import { selectSportsItems } from 'app/providers/EntitiesProvider/sports';
import { selectTournamentsItems } from 'app/providers/EntitiesProvider/tournaments';

import { useUserStore } from 'store';
import { getIsCustomer, getIsOperator, getIsTranslator } from 'store/user';

import * as S from './Panel.styles';

const TOURNAMENTS_LIMIT_TO_CHECK_EXISTENCE = 1;
const TOURNAMENTS_PAGINATION_LIMIT = 100;

interface Props {}

export const Panel: FC<Props> = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tournaments = useSelector(selectTournamentsItems);
  const sports = useSelector(selectSportsItems);
  const groups = useSelector(selectGroupsItems);
  const { isCustomer } = useUserStore(getIsCustomer);
  const isOperator = useUserStore(getIsOperator);
  const isTranslator = useUserStore(getIsTranslator);
  const theme = useTheme();
  const [sportId, setSportId] = useState<number>();
  const [groupId, setGroupId] = useState<number>();

  const [isCreateSportPanelVisible, , , toggleCreateSportPanelVisibility] =
    useBooleanState();

  const [
    isCreateTournamentVisible,
    showCreateTournament,
    hideCreateTournament,
  ] = useBooleanState();

  const [isCreateGroupVisible, showCreateGroup, hideCreateGroup] =
    useBooleanState();

  useEffect(() => {
    dispatch(actionsNT.sportsFetchItems({ limit: FETCH_ALL_LIMIT }));
    dispatch(actionsNT.groupsFetchItems({ limit: FETCH_ALL_LIMIT }));
    dispatch(actionsNT.countriesFetchItems({ limit: FETCH_ALL_LIMIT }));
  }, [dispatch]);

  const groupsBySport = useMemo(() => groupBy(groups, 'sportId'), [groups]);
  const tournamentsByGroup = useMemo(
    () => groupBy(tournaments, 'groupId'),
    [tournaments],
  );

  const config = useMemo(
    () =>
      sports.map(sport => ({
        label: sport.name,
        id: sport.id,
        testId: `sport-${sport.name}`,
        buttonAction:
          isCustomer || isTranslator || isOperator
            ? undefined
            : () => {
                showCreateGroup();
                setSportId(sport.id);
              },
        onSelect: () => {
          navigate(`/dictionary/sports/${sport.id}`, { replace: true });
        },
        children: groupsBySport[sport.id]?.map(group => ({
          label: group.name,
          id: group.id,
          testId: `group-${group.name}`,
          buttonAction:
            isCustomer || isTranslator
              ? undefined
              : () => {
                  showCreateTournament();
                  setSportId(sport.id);
                  setGroupId(group.id);
                },
          onMount: () => {
            if (!tournamentsByGroup[group.id]?.length) {
              dispatch(
                actionsNT.tournamentsFetchItems({
                  withPagination: true,
                  groupId: group.id,
                  limit: TOURNAMENTS_LIMIT_TO_CHECK_EXISTENCE,
                  offset: 0,
                }),
              );
            }
          },
          sideEffectsDeps: !!tournamentsByGroup[group.id]?.length,
          onBottom: () => {
            dispatch(
              actionsNT.tournamentsFetchItems({
                groupId: group.id,
                limit: TOURNAMENTS_PAGINATION_LIMIT,
                offset: tournamentsByGroup[group.id]?.length,
                withPagination: true,
              }),
            );
          },
          onSelect: () => {
            navigate(`/dictionary/groups/${group.id}`, { replace: true });
          },
          onOpen: () => {
            dispatch(
              actionsNT.tournamentsFetchItems({
                withPagination: true,
                groupId: group.id,
                limit: TOURNAMENTS_PAGINATION_LIMIT,
                offset: 0,
              }),
            );
          },
          children: tournamentsByGroup[group.id]?.map(tournament => ({
            label: tournament.name,
            testId: `tournament-${tournament.name}`,
            id: tournament.id,
            onSelect: () => {
              navigate(`/dictionary/tournaments/${tournament.id}`, {
                replace: true,
              });
            },
            style: tournament.disabled
              ? {
                  backgroundColor: theme.colors.primary[50],
                }
              : {},
          })),
        })),
      })),
    [
      dispatch,
      groupsBySport,
      isCustomer,
      isOperator,
      isTranslator,
      navigate,
      sports,
      theme.colors.primary,
      tournamentsByGroup,
      showCreateTournament,
      showCreateGroup,
    ],
  );

  return (
    <S.Panel>
      <S.HeaderWrapper>
        <DictionaryDropdown value={'sport'} />
        <RoleGuards roles={[UserGroupEnum.ADMIN, UserGroupEnum.SUPERVISOR]}>
          <Button
            onClick={toggleCreateSportPanelVisibility}
            testId={TestIds.DictionaryDropdownAddButton}
          >
            {t('add')}
          </Button>
        </RoleGuards>
      </S.HeaderWrapper>
      <S.PanelContent>
        <S.SearchWrapper>
          <Search />
        </S.SearchWrapper>
        <S.ScrollingWrapper>
          <NestedList config={config} />
        </S.ScrollingWrapper>
      </S.PanelContent>
      {isCreateSportPanelVisible && (
        <SportCreatePanel onClose={toggleCreateSportPanelVisibility} />
      )}
      {isCreateTournamentVisible && (
        <TournamentCreatePanel
          onClose={hideCreateTournament}
          sportId={sportId}
          groupId={groupId}
        />
      )}
      {isCreateGroupVisible && (
        <GroupCreatePanel onClose={hideCreateGroup} sportId={sportId} />
      )}
    </S.Panel>
  );
};
