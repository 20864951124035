import { BetPayMax } from 'sportsbook-openapi-react';

import { parseFloatFormatted } from 'utils/number';
import { moreOrEqOne } from 'utils/validation';
import { TestIds } from 'types/testIds.types';

import { Checkbox, TextInputCell } from 'app/components/forms';

import { BETPAYMAX_TRANSMISSION_COEFFICIENT } from 'app/providers/EntitiesProvider/hooks';

interface Props {
  betPayMax: BetPayMax;
  outcomeCategoryId: number;
  handleCellChange: (props: {
    outcomeCategoryId: number;
    betPayMax?: BetPayMax;
    maxPay?: boolean;
    value?: number;
  }) => void;
}

const BetpaymaxCell = ({
  betPayMax,
  handleCellChange,
  outcomeCategoryId,
}: Props) => {
  return (
    <div onClick={event => event.stopPropagation()}>
      <Checkbox
        testId={TestIds.CategoriesTableMaxbetCheckbox}
        checked={betPayMax?.maxPay}
        onChange={maxPay => {
          handleCellChange({ outcomeCategoryId, betPayMax, maxPay });
        }}
        label={
          <TextInputCell
            // @ts-ignore
            min={0}
            defaultValue={
              betPayMax?.value
                ? betPayMax.value / BETPAYMAX_TRANSMISSION_COEFFICIENT
                : '1000'
            }
            onSave={newValue => {
              handleCellChange({
                betPayMax,
                value: parseFloatFormatted(newValue),
                outcomeCategoryId,
              });
            }}
            type="number"
            validate={moreOrEqOne}
          />
        }
      />
    </div>
  );
};

export default BetpaymaxCell;
