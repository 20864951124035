import { createSelector } from '@reduxjs/toolkit';
import { keyBy } from 'lodash-es';
import { EventSource, OutcomeType } from 'sportsbook-openapi-react';

import { RootState } from 'types';

const getCalculators = (state: RootState) =>
  state.entities.oddOutcomeCalculatorRules;
const getCalculatorsEntries = (state: RootState) =>
  getCalculators(state).entries;

const getOutcomeTypeIdArgument = (
  _state: RootState,
  outcomeTypeId: OutcomeType['id'],
) => outcomeTypeId;

const getSourceArgument = (
  _state: RootState,
  _outcomeTypeId: OutcomeType['id'],
  source: EventSource,
) => source;

export const selectCalculatorsByOutcomeTypeId = createSelector(
  [getCalculatorsEntries, getOutcomeTypeIdArgument],
  (calculators, outcomeTypeId) =>
    keyBy(
      Object.values(calculators).filter(
        calculator => calculator.outcomeTypeId === outcomeTypeId,
      ),
      'source',
    ),
);

export const selectCalculatorsByOutcomeIdTypeAndSource = createSelector(
  [selectCalculatorsByOutcomeTypeId, getSourceArgument],
  (calculatorsByOutcomeTypeId, source) => {
    return calculatorsByOutcomeTypeId[source];
  },
);
