import { Helmet } from 'react-helmet-async';
import { Outlet } from 'react-router-dom';

import { CategoriesNestedList } from './components';

export const Calculators = () => {
  return (
    <>
      <Helmet>
        <title>Calculators</title>
        <meta name="description" content="Description of Calculators" />
      </Helmet>
      <CategoriesNestedList />
      <Outlet />
    </>
  );
};
