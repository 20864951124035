import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useMatch } from 'react-router-dom';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { uniq } from 'lodash-es';
import {
  EventStatus,
  SportEventOddsLineType,
  UserGroupEnum,
} from 'sportsbook-openapi-react';

import { useChangeEventStatus } from 'hooks/events';

import { RoleGuards } from 'app/components/RoleGuards';
import { Button, Popup } from 'app/components/ui';

import { actionsNT } from 'app/providers/EntitiesProvider';

import { useUserStore } from 'store';
import { getUser } from 'store/user';

import * as S from './ActionsPopup.styles';

export interface PanelInfo {
  eventId: number;
  selectedEvents: number[];
}

interface Props {
  selectedEvents: number[];
  eventId: number;
  resetEvents: () => void;
  setCreateRelationPanel?: (panel: PanelInfo) => void;
  setCreateDependencyPanel?: (panel: PanelInfo) => void;
  showDependencies?: boolean;
  showSuspends?: boolean;
  showRelations?: boolean;
  showFavorites?: boolean;
  showRefresh?: boolean;
}

const eventStatuses = [
  EventStatus.OPEN,
  EventStatus.CLOSED,
  EventStatus.SUSPENDED,
  EventStatus.TRADING,
  EventStatus.PLANNING,
  EventStatus.INTERRUPTED,
];

export const ActionsPopup: FC<Props> = ({
  selectedEvents,
  eventId,
  resetEvents,
  setCreateRelationPanel,
  setCreateDependencyPanel,
  showDependencies = true,
  showRelations = true,
  showFavorites = true,
  showRefresh = false,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const changeEventStatus = useChangeEventStatus();
  const user = useUserStore(getUser);
  const isLive = !!useMatch('/live');

  const changeEventsStatus = (status: EventStatus) => {
    uniq([...selectedEvents, eventId]).forEach(changeEventStatus(status));
    resetEvents();
  };

  const moveEventsToFavorite = () => {
    uniq([...selectedEvents, eventId]).forEach((eventId: number) => {
      if (!user) return;
      dispatch(
        actionsNT.eventFavoritesCreate({
          userId: user.id,
          eventFavoriteMapping: {
            userId: user.id,
            eventId,
          },
        }),
      );
    });
    resetEvents();
  };

  const deleteFromFavorite = () => {
    uniq([...selectedEvents, eventId]).forEach((eventId: number) => {
      if (!user) return;
      dispatch(
        actionsNT.eventFavoritesDelete({
          userId: user.id,
          eventId,
          deleteEvent: true,
        }),
      );
    });
    resetEvents();
  };

  const handleRefreshClick = () => {
    uniq([...selectedEvents, eventId]).forEach((eventId: number) => {
      dispatch(
        actionsNT.eventsOddsUpdate({
          eventId,
          lineType: isLive
            ? SportEventOddsLineType.LIVE
            : SportEventOddsLineType.PRE,
        }),
      );
    });
    resetEvents();
  };

  const showDivider = showDependencies || showRelations || showFavorites;

  return (
    <Popup
      placement="bottom-end"
      button={
        <Button color="action">
          <FontAwesomeIcon icon={faEllipsisV} />
        </Button>
      }
      body={
        <S.Column>
          <RoleGuards
            roles={[
              UserGroupEnum.ADMIN,
              UserGroupEnum.SUPERVISOR,
              UserGroupEnum.OPERATOR,
            ]}
          >
            {eventStatuses.map(status => (
              <S.StyledSpan
                key={status}
                onClick={() => changeEventsStatus(status)}
              >
                {t(`statuses.actions.${status}`)}
              </S.StyledSpan>
            ))}
            {showDivider && <S.Divider />}
            {showRefresh && (
              <S.StyledSpan onClick={handleRefreshClick}>
                {t('refresh')}
              </S.StyledSpan>
            )}
            {showDependencies && (
              <S.StyledSpan
                onClick={() => {
                  if (setCreateDependencyPanel) {
                    setCreateDependencyPanel({ eventId, selectedEvents });
                  }
                }}
              >
                {t('add relation')}
              </S.StyledSpan>
            )}
            {showRelations && (
              <S.StyledSpan
                onClick={() => {
                  if (setCreateRelationPanel) {
                    setCreateRelationPanel({ eventId, selectedEvents });
                  }
                }}
              >
                {t('link with event')}
              </S.StyledSpan>
            )}
          </RoleGuards>
          {showFavorites && (
            <S.StyledSpan onClick={moveEventsToFavorite}>
              {t('add to favorite')}
            </S.StyledSpan>
          )}
          {showFavorites && (
            <S.StyledSpan onClick={deleteFromFavorite}>
              {t('delete from favorite')}
            </S.StyledSpan>
          )}
        </S.Column>
      }
    />
  );
};
