import { FC, MouseEvent, ReactNode, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

import { useEnter } from 'hooks/useEnter';
import { useEscape } from 'hooks/useEscape';
import { TestIds } from 'types/testIds.types';

import { Button } from '..';

import * as S from './Dialog.styles';

interface DialogProps {
  text: string;
  onClose: () => void;
  onConfirm?: () => void;
  onCancel?: () => void;
  withoutActions?: boolean;
  children?: ReactNode;
  strictWidth?: boolean;
}

const handleDialogClick = (event: MouseEvent) => {
  event.stopPropagation();
};

export const Dialog: FC<DialogProps> = ({
  text,
  onConfirm,
  onCancel,
  onClose,
  withoutActions,
  children,
  strictWidth = true,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirm = async () => {
    setIsLoading(true);
    try {
      await onConfirm?.();
      onClose();
    } finally {
      setIsLoading(false);
    }
  };
  const handleCancel = async () => {
    setIsLoading(true);
    try {
      await onCancel?.();
      onClose();
    } finally {
      setIsLoading(false);
    }
  };

  useEnter(handleConfirm);
  useEscape(handleCancel);

  return createPortal(
    <S.Wrapper onClick={handleCancel}>
      <S.DialogWrapper
        onClick={handleDialogClick}
        strictWidth={strictWidth}
        data-test-id={TestIds.DialogWrapper}
      >
        <S.StyledText size="title">{text}</S.StyledText>
        {!!children && <S.ChildrenWrapper>{children}</S.ChildrenWrapper>}
        {!withoutActions && (
          <>
            <Button
              disabled={isLoading}
              onClick={handleConfirm}
              testId={TestIds.DialogConfirmButton}
            >
              {t('confirm')}
            </Button>
            <Button
              disabled={isLoading}
              color="action"
              onClick={handleCancel}
              testId={TestIds.DialogCancelButton}
            >
              {t('cancel')}
            </Button>
          </>
        )}
      </S.DialogWrapper>
    </S.Wrapper>,
    document.getElementById('root') as HTMLElement,
  );
};
