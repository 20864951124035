import styled from 'styled-components';

export const Header = styled.div`
  display: grid;
  grid-template-columns: 1fr 40px 1fr;
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 40px 1fr;
  > div {
    height: 42px;
  }
`;

export const Spacer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Source = styled.div`
  padding: 10px 12px;
  border: 1px solid #e2e4e9;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  color: #414958;
`;
