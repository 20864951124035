import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Column } from 'react-table';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DictionaryDropdown } from 'app/pages/Dictionary/components/DictionaryDropdown';
import {
  OutcomeCategory,
  Sport,
  UserGroupEnum,
} from 'sportsbook-openapi-react';

import useBooleanState from 'hooks/useBooleanState';
import { useSearch } from 'hooks/useSearch';
import { FETCH_ALL_LIMIT } from 'consts';
import { TestIds } from 'types/testIds.types';
import { useTheme } from 'styles';

import { Select, TextInput } from 'app/components/forms';
import { CategoryCreatePanel, CategoryEditPanel } from 'app/components/panels';
import { RoleGuards } from 'app/components/RoleGuards';
import { Button, Table } from 'app/components/ui';

import { actionsNT } from 'app/providers/EntitiesProvider';
import { useGetCategoryList } from 'app/providers/EntitiesProvider/categories/queries';
import { selectSportsItems } from 'app/providers/EntitiesProvider/sports';

import { useUserStore } from 'store';
import { getIsOperator, getIsTranslator } from 'store/user';

import * as S from './OutcomesPanel.styles';

export const Panel: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { searchQuery, setSearchQueryDebounced } = useSearch();
  const navigate = useNavigate();
  const allSportsOption = { id: 0, name: t('all'), code: '' };
  const [sport, setSport] = useState<Sport>(allSportsOption);
  const isOperator = useUserStore(getIsOperator);
  const isTranslator = useUserStore(getIsTranslator);
  const [isCreateCategoryVisible, showCreateCategory, hideCreateCategory] =
    useBooleanState();
  const [editCategoryId, setEditCategoryId] = useState<null | number>(null);

  const { data, fetchNextPage } = useGetCategoryList({
    name: searchQuery.length ? searchQuery.toLowerCase() : undefined,
  });

  const sports = useSelector(selectSportsItems);

  useEffect(() => {
    dispatch(actionsNT.sportsFetchItems({ limit: FETCH_ALL_LIMIT }));
    dispatch(actionsNT.categoriesFetchItems({ limit: FETCH_ALL_LIMIT }));
    if (!isTranslator) {
      dispatch(actionsNT.marketsFetchItems({ limit: FETCH_ALL_LIMIT }));
    }
  }, [dispatch, isTranslator]);

  const theme = useTheme();

  const filterOptions = [allSportsOption, ...sports];

  const columns: Column<OutcomeCategory>[] = useMemo(
    () => [
      { Header: t('name').toString(), accessor: 'name' },
      ...(!(isTranslator || isOperator)
        ? [
            {
              Header: '',
              id: 'action',
              Cell: ({ row: { original: data } }) => (
                <Button
                  testId={`${TestIds.OutcomesPageCategoryEditButton}--${data.name}`}
                  color="action"
                  onClick={() => {
                    setEditCategoryId(data.id);
                  }}
                >
                  {t('edit')}
                </Button>
              ),
            },
          ]
        : []),
    ],
    [isOperator, isTranslator, t],
  );

  const filteredData = useMemo(
    () =>
      data?.pages
        .map(page => page.items)
        .flat()
        .filter(category => {
          if (sport?.id) {
            return category.sports.includes(sport.id);
          }
          return true;
        }) ?? [],
    [data?.pages, sport.id],
  );

  return (
    <S.Panel>
      <S.DropdownWrapper>
        <DictionaryDropdown value={'outcomes'} />
        <RoleGuards
          roles={[
            UserGroupEnum.ADMIN,
            UserGroupEnum.CUSTOMER,
            UserGroupEnum.SUPERVISOR,
          ]}
        >
          <Button
            onClick={showCreateCategory}
            testId={TestIds.OutcomesPageCreateCategoryButton}
          >
            {t('create category')}
          </Button>
        </RoleGuards>
      </S.DropdownWrapper>
      <S.PanelHeading>
        <TextInput
          placeholder={t('search')}
          onChange={e => setSearchQueryDebounced(e.target.value)}
          icon={<FontAwesomeIcon icon={faSearch} />}
          testId={TestIds.OutcomesPageSearch}
        />
        <Select
          placeholder={t('sport type')}
          value={sport}
          options={filterOptions}
          onChange={element => setSport(element as Sport)}
          testId={TestIds.OutcomesPageSportSelect}
        />
      </S.PanelHeading>
      <S.PanelBlock>
        <Table
          testId={TestIds.OutcomesPageOutcomesTable}
          testFieldName="name"
          columns={columns}
          data={filteredData}
          columnsWidth={['auto', 'fit-content']}
          onRowClick={data => {
            navigate(`/dictionary/outcomes/${data.id}`, { replace: true });
          }}
          onBottom={fetchNextPage}
          rowStyles={row => {
            return row.disabled
              ? `background-color: ${theme.colors.primary[50]} !important;
              &:hover {
                background-color: ${theme.colors.primary[50]} !important;
              }
              `
              : '';
          }}
        />
      </S.PanelBlock>
      {isCreateCategoryVisible && (
        <CategoryCreatePanel onClose={hideCreateCategory} />
      )}
      {!!editCategoryId && (
        <CategoryEditPanel
          onClose={() => {
            setEditCategoryId(null);
          }}
          id={editCategoryId}
        />
      )}
    </S.Panel>
  );
};
