import styled from 'styled-components';

import { Button } from 'app/components/ui';

export const Content = styled.div`
  padding: 20px;

  display: grid;
  grid-template-rows: auto auto 1fr;
  grid-row-gap: 15px;
`;

export const ContentBlock = styled.div`
  padding: 20px;
  display: grid;
  grid-template-rows: repeat(2, min-content);
  grid-row-gap: 20px;
`;

export const ContentHead = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr auto;
  grid-column-gap: 20px;
`;

export const Spacer = styled.div``;

export const ButtonsWrapper = styled.div`
  display: flex;
  column-gap: 10px;
`;

export const StyledButton = styled(Button)`
  width: 100%;
`;
