import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';

import { getEntities } from 'app/providers/EntitiesProvider/selectors';
import {
  getEntityItems,
  populateWithItems,
} from 'app/providers/EntitiesProvider/utils';

const getMatchtypesSubstate = (state: RootState) =>
  getEntities(state).matchTypes;

export const getMatchtypesEntries = (state: RootState) =>
  getMatchtypesSubstate(state).entries;

export const getMatchType = (state, id?: number) =>
  id ? getMatchtypesEntries(state)[id] : undefined;

export const selectMatchTypesItems = createSelector(
  [getMatchtypesSubstate],
  matchTypes => getEntityItems(matchTypes),
);

export const selectMatchTypes = createSelector(
  [getMatchtypesSubstate],
  entity => populateWithItems(entity),
);

export const selectMatchTypesEntries = getMatchtypesEntries;
