import { PayloadAction } from '@reduxjs/toolkit';
import {
  MaxOdd,
  MaxOddList,
  MaxOddsApiGetMaxOddEventListRequest,
} from 'sportsbook-openapi-react';

import {
  EntitiesErrorType,
  PaginatedGetRequest,
} from 'app/providers/EntitiesProvider/types';
import {
  entityReducersGenerator,
  toIdsArray,
  toIdsMap,
} from 'app/providers/EntitiesProvider/utils';

const reducer = {
  ...entityReducersGenerator<MaxOdd[], MaxOdd, void, EntitiesErrorType>({
    entity: 'maxOdds',
  }),
  maxOddsByEventFetchItems(
    state,
    action: PayloadAction<
      PaginatedGetRequest<MaxOddsApiGetMaxOddEventListRequest>
    >,
  ) {
    state.maxOdds.fetchItems.error = null;
    state.maxOdds.fetchItems.loading = true;
    state.maxOdds.withPagination = action.payload?.withPagination;
    state.maxOdds.onlyEntries = action.payload?.onlyEntries;
  },
  maxOddsByEventFetchItemsSuccess(state, action: PayloadAction<MaxOddList>) {
    const { items, hasMore } = action.payload;
    if (!state.maxOdds.onlyEntries) {
      if (state.maxOdds.withPagination) {
        state.maxOdds.sortedIds = toIdsArray(items, state.maxOdds.sortedIds);
      } else {
        state.maxOdds.sortedIds = toIdsArray(items);
      }
    }
    state.maxOdds.entries = {
      ...state.maxOdds.entries,
      ...toIdsMap(items),
    };
    state.maxOdds.fetchItems.loading = false;
    state.maxOdds.hasMore = hasMore;
  },
  maxOddsByEventFetchItemsError(state, action: PayloadAction<Error>) {
    state.maxOdds.fetchItems.error = action.payload;
    state.maxOdds.fetchItems.loading = false;
  },
};

export default reducer;
