/**
 *
 * Competitors
 *
 */

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'react-table';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DictionaryDropdown } from 'app/pages/Dictionary/components/DictionaryDropdown';
import { omit } from 'lodash-es';
import {
  Competitor,
  Country,
  Sport,
  UserGroupEnum,
} from 'sportsbook-openapi-react';

import { useAudit } from 'hooks/audit/useAudit';
import useBooleanState from 'hooks/useBooleanState';
import { useLazyLoading } from 'hooks/useLazyLoading';
import { useRequestState } from 'hooks/useRequestState';
import { useSearch } from 'hooks/useSearch';
import { FETCH_ALL_LIMIT } from 'consts';
import { TestIds } from 'types/testIds.types';
import { useTheme } from 'styles';

import { Select, TextInput, TextInputCell } from 'app/components/forms';
import {
  CompetitorCreatePanel,
  CompetitorEditPanel,
} from 'app/components/panels';
import { RoleGuards } from 'app/components/RoleGuards';
import { Button, LoadingIndicator, Table } from 'app/components/ui';

import { AuditedEntities } from 'app/providers/AuditProvider';
import { actionsNT, Entities } from 'app/providers/EntitiesProvider';
import {
  getCompetitorsHasMore,
  selectCompetitorsItems,
} from 'app/providers/EntitiesProvider/competitors';
import { selectCountries } from 'app/providers/EntitiesProvider/countries';
import { getPlayersEntries } from 'app/providers/EntitiesProvider/players';
import {
  getSportsEntries,
  selectSportsItems,
} from 'app/providers/EntitiesProvider/sports';

import { useUserStore } from 'store';
import { getIsTranslator } from 'store/user';

import * as S from './Competitors.styles';

export function Competitors() {
  const items = useSelector(selectCompetitorsItems);
  const hasMore = useSelector(getCompetitorsHasMore);

  const { isLoading } = useRequestState(Entities.COMPETITORS, 'fetchItems');
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const sports = useSelector(selectSportsItems);
  const entries = useSelector(getSportsEntries);
  const countries = useSelector(selectCountries);
  const isTranslator = useUserStore(getIsTranslator);

  const [sport, setSport] = useState<Sport>();
  const [country, setCountry] = useState<Country>();

  const [
    isCreateCompetitorVisible,
    showCreateCompetitor,
    hideCreateCompetitor,
  ] = useBooleanState();
  const [editCompetitorId, setEditCompetitorId] = useState<null | number>(null);

  const { searchQuery, setSearchQueryDebounced } = useSearch();
  const requestedIds = useRef<number[]>([]);

  useEffect(() => {
    dispatch(actionsNT.sportsFetchItems({ limit: FETCH_ALL_LIMIT }));
  }, [dispatch]);
  useEffect(() => {
    dispatch(actionsNT.countriesFetchItems({ limit: FETCH_ALL_LIMIT }));
  }, [dispatch]);
  useEffect(() => {
    const idsToFetch = items
      .map(item => item.referenceIds as number[])
      .flat()
      .filter(Boolean)
      .filter(id => !requestedIds.current?.includes(id));
    requestedIds.current?.push(...idsToFetch);

    if (idsToFetch.length) {
      dispatch(
        actionsNT.playersFetchItems({
          ids: idsToFetch,
          limit: idsToFetch.length,
        }),
      );
    }
  }, [dispatch, items]);

  const onLazyLoad = useCallback(
    ({ limit, offset }) => {
      dispatch(
        actionsNT.competitorsFetchItems({
          limit,
          offset,
          withPagination: true,
          name: searchQuery.length ? searchQuery.toLowerCase() : undefined,
          sportId: sport?.id,
          countryId: country?.id,
        }),
      );
    },
    [country?.id, dispatch, searchQuery, sport?.id],
  );

  const onBottom = useLazyLoading({
    onLazyLoad,
    hasMore,
    extraDeps: [searchQuery, sport, country],
    onPaginationReset: () => {
      dispatch(actionsNT.competitorsReset());
    },
  });

  const handleUpdateCompetitor = useCallback(
    (competitor: Competitor) => {
      dispatch(
        actionsNT.competitorsUpdate({
          competitorId: competitor.id,
          competitorWithOutId: {
            ...omit(competitor, 'id'),
            type: competitor?.type,
          },
        }),
      );
    },
    [dispatch],
  );

  const openAudit = useAudit({
    entity: AuditedEntities.COMPETITORS,
  });

  const columns: Column<Competitor>[] = useMemo(
    () => [
      {
        Header: t('name').toString(),
        Cell: ({ value, row: { original: data } }) => {
          return !isTranslator ? (
            <TextInputCell
              defaultValue={value}
              onSave={newValue => {
                handleUpdateCompetitor({
                  ...data,
                  name: newValue,
                });
              }}
              validate={value => value.length > 3}
            />
          ) : (
            <>{value}</>
          );
        },
        accessor: 'name',
      },
      {
        Header: t('type').toString(),
        accessor: 'type',
      },
      {
        Header: t('player').toString(),
        accessor: 'referenceIds',
        Cell: ({ value }) => {
          const players = useSelector(getPlayersEntries);
          return <>{value?.map(id => players[id]?.name).join(', ')}</>;
        },
      },
      {
        Header: t('sport').toString(),
        accessor: 'sportId',
        Cell: ({ value }) => <>{entries[value]?.name}</>,
      },
      ...(!isTranslator
        ? [
            {
              Header: '',
              Cell: ({ row: { original: data } }) => (
                <S.ButtonsWrapper>
                  <Button
                    testId={`${TestIds.CompetitorsPageEditButton}--${data.name}`}
                    color="action"
                    onClick={() => {
                      setEditCompetitorId(data.id);
                    }}
                  >
                    {t('edit')}
                  </Button>
                  <Button
                    testId={`${TestIds.CompetitorsPageAuditButton}--${data.name}`}
                    color="action"
                    onClick={() => {
                      openAudit({ id: data.id, includeRelated: false });
                    }}
                  >
                    {t('audit')}
                  </Button>
                </S.ButtonsWrapper>
              ),
              id: 'action',
            },
          ]
        : []),
    ],
    [t, isTranslator, handleUpdateCompetitor, entries, openAudit],
  );

  useEffect(() => {
    dispatch(actionsNT.countriesFetchItems({ limit: FETCH_ALL_LIMIT }));
    dispatch(actionsNT.sportsFetchItems({ limit: FETCH_ALL_LIMIT }));
  }, [dispatch]);

  return (
    <S.Wrapper>
      <S.DropdownWrapper>
        <DictionaryDropdown value={'competitors'} />
      </S.DropdownWrapper>
      <S.ContentWrapper>
        <S.Header>
          <RoleGuards
            roles={[
              UserGroupEnum.ADMIN,
              UserGroupEnum.CUSTOMER,
              UserGroupEnum.OPERATOR,
              UserGroupEnum.SUPERVISOR,
            ]}
          >
            <Button
              onClick={showCreateCompetitor}
              testId={TestIds.CompetitorsPageAddButton}
            >
              {t('add competitor')}
            </Button>
          </RoleGuards>
          <TextInput
            placeholder={t('search')}
            onChange={e => setSearchQueryDebounced(e.target.value)}
            icon={<FontAwesomeIcon icon={faSearch} />}
            testId={TestIds.CompetitorsPageSearchInput}
          />
          <Select
            placeholder={t('sport type')}
            value={sport}
            options={sports}
            onChange={element => setSport(element as Sport)}
            isClearable
            testId={TestIds.CompetitorsPageSportSelect}
          />
          <Select
            placeholder={t('country')}
            value={country}
            options={countries}
            onChange={element => setCountry(element as Country)}
            isClearable
            testId={TestIds.CompetitorsPageCountrySelect}
          />
        </S.Header>
        {items.length === 0 && isLoading ? (
          <LoadingIndicator type="full" />
        ) : (
          <>
            <Table
              columns={columns}
              data={items}
              onBottom={onBottom}
              testId={TestIds.CompetitorsPageTable}
              testFieldName="name"
              columnsWidth={['auto', 'auto', 'auto', 'auto', 'fit-content']}
              rowStyles={row =>
                row.disabled
                  ? `background-color: ${theme.colors.primary[50]} !important;
              &:hover {
                background-color: ${theme.colors.primary[50]} !important;
              }
              `
                  : ''
              }
            />
            {items.length > 0 && isLoading && (
              <LoadingIndicator type="absolute" />
            )}
          </>
        )}
      </S.ContentWrapper>
      {isCreateCompetitorVisible && (
        <CompetitorCreatePanel onClose={hideCreateCompetitor} />
      )}
      {!!editCompetitorId && (
        <CompetitorEditPanel
          onClose={() => {
            setEditCompetitorId(null);
          }}
          id={editCompetitorId}
        />
      )}
    </S.Wrapper>
  );
}
