import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getHomeAwayCompetitors, getStats } from 'app/pages/Events/utils';
import { Event } from 'sportsbook-openapi-react';

import { useRequestState } from 'hooks/useRequestState';

import { actionsNT } from 'app/providers/EntitiesProvider';
import { getEventStats } from 'app/providers/EntitiesProvider/eventStats';
import { getSportById } from 'app/providers/EntitiesProvider/sports';

import * as S from './EventResultInfo.styles';

interface Props {
  event: Event;
}

export function EventResultInfo({ event }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const eventStats = useSelector(state => getEventStats(state, event.id));
  const { isLoading: loading } = useRequestState('eventStats', 'fetchItem');
  const { homeCompetitor, awayCompetitor } = getHomeAwayCompetitors(event);

  useEffect(() => {
    dispatch(actionsNT.sportsFetchItem({ sportId: event.tournament!.sportId }));
    dispatch(
      actionsNT.competitorsFetchItems({
        ids: event.competitors.map(c => c.id),
        limit: event.competitors.length,
      }),
    );
    dispatch(actionsNT.eventStatsFetchItem({ eventId: event.id }));
  }, [dispatch, event]);

  const sport = useSelector(state =>
    getSportById(state, event.tournament!.sportId),
  );

  const stats = useMemo(() => getStats(eventStats?.v2), [eventStats?.v2]);

  if (loading) {
    return (
      <S.Wrapper>
        <S.Loader />
      </S.Wrapper>
    );
  }
  return (
    <S.Wrapper>
      <S.EventInfo>
        <S.InfoBlock>
          <S.Label>{t('sport type')}</S.Label>
          <S.Value>{sport?.name}</S.Value>
        </S.InfoBlock>
        <S.InfoBlock>
          <S.Label>{t('tournament')}</S.Label>
          <S.Value>{event.tournament?.name}</S.Value>
        </S.InfoBlock>
        <S.CompetitorsBlock>
          <S.CompetitorName>{homeCompetitor?.name}</S.CompetitorName>
          <S.ScoreBlock>
            <S.Value>{stats?.homeScore ?? '-'}</S.Value>
            <S.Separator>:</S.Separator>
            <S.Value>{stats?.awayScore ?? '-'}</S.Value>
          </S.ScoreBlock>
          <S.CompetitorName>{awayCompetitor?.name}</S.CompetitorName>
        </S.CompetitorsBlock>
        {stats ? (
          <S.InfoBlock>
            <S.Label>{t('period score')}</S.Label>
            <div>
              {stats.homePeriodStats.map((periodStat, index) => (
                <S.InfoScore key={periodStat.periodNumber}>
                  {periodStat.stat.value.numberValue}:
                  {stats.awayPeriodStats[index].stat.value.numberValue}
                </S.InfoScore>
              ))}
            </div>
          </S.InfoBlock>
        ) : (
          <S.StatsEmpty>{t('stats is not available')}</S.StatsEmpty>
        )}
      </S.EventInfo>
    </S.Wrapper>
  );
}
