import { useTranslation } from 'react-i18next';
import values from 'lodash-es/values';
import { Sport } from 'sportsbook-openapi-react';

import { TestIds } from 'types/testIds.types';

import { SelectField, TextInputField } from 'app/components/forms';
import { Span } from 'app/components/ui';

import * as S from './styles';

interface Props {
  sports: Dictionary<Sport>;
  actionType: 'create' | 'edit';
  sportId?: number;
}

export const GroupForm = ({ sports, sportId, actionType }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <S.FormBlock>
        <Span>{t('name')}</Span>
        <TextInputField name="name" testId={TestIds.GroupPanelNameInput} />
      </S.FormBlock>
      <S.FormBlock>
        <Span>{t('sport type')}</Span>
        <SelectField
          name="sport"
          options={values(sports)}
          readOnly={!!(sportId && actionType === 'create')}
          testId={TestIds.GroupPanelSportSelect}
        />
      </S.FormBlock>
    </>
  );
};
