/**
 *
 * SlidePanel
 *
 */
import { FC } from 'react';

import { useEscape } from 'hooks/useEscape';
import { TestIds } from 'types/testIds.types';

import Close from './assets/images/close.svg?react';
import * as S from './SlidePanel.styles';
import { SlidePanelProps } from './SlidePanel.types';

export const SlidePanel: FC<SlidePanelProps> = ({
  children,
  title,
  footer,
  onClose: handleClose,
  onSubmit,
  panelWidth,
}) => {
  useEscape(handleClose);

  return (
    <>
      <S.SlidePanelWrapper onClick={handleClose} panelWidth={panelWidth} />
      {onSubmit ? (
        <S.FormPanel
          onSubmit={onSubmit}
          action="#"
          panelWidth={panelWidth}
          data-test-id={TestIds.PanelWrapper}
        >
          <S.Header>
            <S.H1>{title}</S.H1>
            <S.Close
              onClick={handleClose}
              data-test-id={TestIds.PanelCloseButton}
            >
              <Close />
            </S.Close>
          </S.Header>
          <S.Content>{children}</S.Content>
          <S.Footer>{footer}</S.Footer>
        </S.FormPanel>
      ) : (
        <S.BlockPanel panelWidth={panelWidth}>
          <S.Header>
            <S.H1>{title}</S.H1>
            <S.Close onClick={handleClose}>
              <Close />
            </S.Close>
          </S.Header>
          <S.Content>{children}</S.Content>
        </S.BlockPanel>
      )}
    </>
  );
};
