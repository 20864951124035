import { Helmet } from 'react-helmet-async';
import { Outlet } from 'react-router-dom';

import { ProgressionsPanel } from './components/Panel';

export function Progressions() {
  return (
    <>
      <Helmet>
        <title>Progressions</title>
        <meta name="Progressions" content="Progressions" />
      </Helmet>
      <ProgressionsPanel />
      <Outlet />
    </>
  );
}
