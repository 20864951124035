import { createSelector } from '@reduxjs/toolkit';
import { Sport } from 'sportsbook-openapi-react';

import { RootState } from 'types';

import { getEntities } from 'app/providers/EntitiesProvider/selectors';
import { getEntityItems } from 'app/providers/EntitiesProvider/utils';

const getSportsSubstate = (state: RootState) => getEntities(state).sports;

export const getSportsEntries = (state: RootState) =>
  getSportsSubstate(state).entries;

export const getSportsIds = (state: RootState) =>
  getSportsSubstate(state).sortedIds;

export const selectSportsItems = createSelector([getSportsSubstate], sports =>
  getEntityItems(sports),
);

export const getSportById = (state, id: Sport['id']): Sport | undefined =>
  getSportsSubstate(state).entries[id];
