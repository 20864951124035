import { ComponentProps, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DictionaryDropdown } from 'app/pages/Dictionary/components/DictionaryDropdown';
import { Sport } from 'sportsbook-openapi-react';

import { useSearch } from 'hooks/useSearch';
import { FETCH_ALL_LIMIT } from 'consts';

import { NestedList } from 'app/components/forms';

import { actionsNT } from 'app/providers/EntitiesProvider';
import { selectCategoriesBySportId } from 'app/providers/EntitiesProvider/categories';
import { selectSportsItems } from 'app/providers/EntitiesProvider/sports';

import { Search } from '..';

import * as S from './CategoriesNestedList.styles';

const CATEGORIES_PAGINATION_LIMIT = 100;

export const CategoriesNestedList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sports = useSelector(selectSportsItems);
  const categoriesBySport = useSelector(selectCategoriesBySportId);
  const { searchQuery, setSearchQueryDebounced } = useSearch('');

  useEffect(() => {
    dispatch(actionsNT.sportsFetchItems({ limit: FETCH_ALL_LIMIT }));
    dispatch(actionsNT.countriesFetchItems({ limit: FETCH_ALL_LIMIT }));
  }, [dispatch]);

  const fetchFirstPage = useCallback(
    (sportId: Sport['id'], searchQuery: string) => {
      dispatch(actionsNT.categoriesReset());
      dispatch(
        actionsNT.categoriesFetchItems({
          limit: CATEGORIES_PAGINATION_LIMIT,
          offset: 0,
          sportId,
          withPagination: true,
          name: searchQuery,
        }),
      );
    },
    [dispatch],
  );

  const config = useMemo(
    () =>
      sports.map(sport => ({
        defaultFilled: true,
        label: sport.name,
        id: sport.id,
        onOpen: () => {
          fetchFirstPage(sport.id, searchQuery);
        },
        onMount: opened => {
          if (opened) {
            fetchFirstPage(sport.id, searchQuery);
          }
        },
        extraDeps: [searchQuery],
        onBottom: () => {
          dispatch(
            actionsNT.categoriesFetchItems({
              limit: CATEGORIES_PAGINATION_LIMIT,
              offset: categoriesBySport[sport.id].length,
              sportId: sport.id,
              withPagination: true,
              name: searchQuery,
            }),
          );
        },
        children: categoriesBySport[sport.id]
          ?.filter(category =>
            category.name.toUpperCase().includes(searchQuery.toUpperCase()),
          )
          ?.map(category => ({
            label: category.name,
            id: `${sport.id}/${category.id}`,
            sideEffectsDeps: !!categoriesBySport[sport.id]?.length,
            onSelect: () => {
              navigate(`/dictionary/calculators/${sport.id}/${category.id}`, {
                replace: true,
              });
            },
          })),
      })) as ComponentProps<typeof NestedList>['config'],
    [
      sports,
      searchQuery,
      categoriesBySport,
      fetchFirstPage,
      dispatch,
      navigate,
    ],
  );

  return (
    <S.Panel>
      <S.HeaderWrapper>
        <DictionaryDropdown value={'calculators'} />
      </S.HeaderWrapper>
      <S.PanelContent>
        <S.SearchWrapper>
          <Search
            searchQuery={searchQuery}
            setSearchQuery={setSearchQueryDebounced}
          />
        </S.SearchWrapper>
        <S.ScrollingWrapper>
          <NestedList config={config} defaultFilled />
        </S.ScrollingWrapper>
      </S.PanelContent>
    </S.Panel>
  );
};
