import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ExclusionPopup } from 'app/pages/Dictionary/Directory/components/ExclusionPopup';
import { AutocalculationButton } from 'app/pages/Events/components/EventHeader/AutocalculationButton';
import { BackButton } from 'app/pages/Events/components/EventHeader/BackButton';
import { FavoriteButton } from 'app/pages/Events/components/EventHeader/FavoriteButton';
import { StatusPopup } from 'app/pages/Events/components/EventHeader/StatusPopup';
import { EventLiveStream } from 'app/pages/Events/components/EventLiveStream';
import { RESULT_STATUSES } from 'app/pages/Events/components/EventsList';
import { EntityType, UserGroupEnum } from 'sportsbook-openapi-react';
import { Event } from 'sportsbook-openapi-react';

import { useAudit } from 'hooks/audit/useAudit';
import useBooleanState from 'hooks/useBooleanState';
import { TestIds } from 'types/testIds.types';

import { ChangeEventLiveModal } from 'app/components/modals';
import { RoleGuards } from 'app/components/RoleGuards';
import { Button } from 'app/components/ui';

import { AuditedEntities } from 'app/providers/AuditProvider';
import { actionsNT } from 'app/providers/EntitiesProvider';
import { getExclusion } from 'app/providers/EntitiesProvider/exclusions';

import { useUserStore } from 'store';
import { getIsCustomer } from 'store/user';

import * as S from './EventLiveHeader.styles';

interface Props {
  event: Event;
}

export function EventLiveHeader({ event }: Props) {
  const [isChangeLiveModalVisible, showChangeLiveModal, hideChangeLiveModal] =
    useBooleanState();

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const openAdminAudit = useAudit({
    entity: [
      AuditedEntities.EVENTS,
      AuditedEntities.ENTITY_COMMENTS,
      AuditedEntities.ENTITY_TAGS,
    ],
  });
  const openCustomerAudit = useAudit({
    entity: AuditedEntities.CUSTOMER_EVENT_STATUS,
  });

  const { isCustomer, customerId } = useUserStore(getIsCustomer);
  const exclusion = useSelector(state =>
    getExclusion(state, EntityType.SPORT_EVENT, event.id),
  );

  const navigate = useNavigate();

  const eventPath = `/results/${event.id}`;

  useEffect(() => {
    if (isCustomer && customerId) {
      dispatch(
        actionsNT.exclusionsFetchItems({
          customerId: customerId,
          entityId: event.id,
          entityType: EntityType.SPORT_EVENT,
        }),
      );
    }
  }, [dispatch, isCustomer, customerId, event.id]);

  return (
    <S.Wrapper>
      <S.EventHeader data-test-id={TestIds.EventPageHeader}>
        <BackButton event={event} />
        <S.H2 data-test-id={`${TestIds.EventPageEventId}--${event.id}`}>{`${t(
          'event',
        )} ID ${event.id}`}</S.H2>
        <StatusPopup event={event} />
        <div />

        <AutocalculationButton event={event} />
        <RoleGuards
          roles={[
            UserGroupEnum.ADMIN,
            UserGroupEnum.OPERATOR,
            UserGroupEnum.SUPERVISOR,
            UserGroupEnum.OPERATOR,
          ]}
        >
          <Button
            color="action"
            testId={TestIds.EventPageEditButton}
            onClick={() => navigate(`/events/${event.id}/edit`)}
          >
            <FontAwesomeIcon icon={faEdit} />
            {t('edit')}
          </Button>
        </RoleGuards>
        {RESULT_STATUSES.includes(event.status) && (
          <S.StyledLink to={eventPath} target="_blank">
            <Button color="action" testId={TestIds.EventPageResultsButton}>
              {t('results')}
            </Button>
          </S.StyledLink>
        )}
        <FavoriteButton event={event} />
        <RoleGuards roles={[UserGroupEnum.CUSTOMER]}>
          <Button
            color="action"
            onClick={() => {
              openCustomerAudit({
                eventId: String(event.id),
                includeRelated: false,
                customerId: String(customerId),
              });
            }}
          >
            {t('audit')}
          </Button>
          <ExclusionPopup
            customerId={customerId!}
            type={EntityType.SPORT_EVENT}
            id={event.id}
            excluded={!!exclusion?.excluded}
          />
        </RoleGuards>
        <RoleGuards
          roles={[
            UserGroupEnum.ADMIN,
            UserGroupEnum.SUPERVISOR,
            UserGroupEnum.OPERATOR,
          ]}
        >
          <Button
            color="secondary"
            onClick={showChangeLiveModal}
            testId={TestIds.EventPageLiveButton}
          >
            {t('remove from live')}
          </Button>
          <Button
            color="action"
            testId={TestIds.EventPageAuditButton}
            onClick={() => {
              openAdminAudit({
                id: String(event.id),
                includeRelated: true,
              });
            }}
          >
            {t('audit')}
          </Button>
        </RoleGuards>
      </S.EventHeader>
      <EventLiveStream event={event} />
      {isChangeLiveModalVisible && (
        <ChangeEventLiveModal
          closeModal={hideChangeLiveModal}
          events={[event]}
        />
      )}
    </S.Wrapper>
  );
}
