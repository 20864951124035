/**
 *
 * MatchTypes
 *
 */

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'react-table';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DictionaryDropdown } from 'app/pages/Dictionary/components/DictionaryDropdown';
import { omit } from 'lodash-es';
import { MatchType, UserGroupEnum } from 'sportsbook-openapi-react';

import { useAudit } from 'hooks/audit/useAudit';
import useBooleanState from 'hooks/useBooleanState';
import { useSearch } from 'hooks/useSearch';
import { FETCH_ALL_LIMIT } from 'consts';
import { TestIds } from 'types/testIds.types';

import { TextInput, TextInputCell } from 'app/components/forms';
import {
  MatchTypeCreatePanel,
  MatchTypeEditPanel,
} from 'app/components/panels';
import { RoleGuards } from 'app/components/RoleGuards';
import { Button, Table } from 'app/components/ui';

import { AuditedEntities } from 'app/providers/AuditProvider';
import { actionsNT } from 'app/providers/EntitiesProvider';
import { selectMatchTypesItems } from 'app/providers/EntitiesProvider/matchTypes';

import { useUserStore } from 'store';
import { getIsTranslator } from 'store/user';

import * as S from './MatchTypes.styles';

export function MatchTypes() {
  const items = useSelector(selectMatchTypesItems);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { searchQuery, setSearchQueryDebounced } = useSearch();
  const isTranslator = useUserStore(getIsTranslator);

  const [isCreateMatchTypeVisible, showCreateMatchType, hideCreateMatchType] =
    useBooleanState();
  const [editMatchTypeId, setEditMatchTypeId] = useState<null | number>(null);

  useEffect(() => {
    dispatch(actionsNT.sportsFetchItems({ limit: FETCH_ALL_LIMIT }));
  }, [dispatch]);

  const handleUpdateMatchType = useCallback(
    (matchType: MatchType) => {
      dispatch(
        actionsNT.matchTypesUpdate({
          matchTypeId: matchType.id,
          matchTypeWithOutId: omit(matchType, 'id'),
        }),
      );
    },
    [dispatch],
  );

  const openAudit = useAudit({
    entity: AuditedEntities.MATCH_TYPES,
  });

  const columns: Column<MatchType>[] = useMemo(
    () => [
      {
        Header: t('name').toString(),
        accessor: 'name',
        Cell: ({ value, row: { original: data } }) => {
          return !isTranslator ? (
            <TextInputCell
              defaultValue={value}
              onSave={newValue => {
                handleUpdateMatchType({
                  ...data,
                  name: newValue,
                });
              }}
              validate={value => value.length > 3}
            />
          ) : (
            <>{value}</>
          );
        },
      },
      ...(!isTranslator
        ? [
            {
              Header: '',
              id: 'action',
              Cell: ({ row: { original: data } }) => (
                <S.ButtonsWrapper>
                  <Button
                    testId={`${TestIds.MatchTypesPageEditButton}--${data.name}`}
                    color="action"
                    onClick={() => {
                      setEditMatchTypeId(data.id);
                    }}
                  >
                    {t('edit')}
                  </Button>
                </S.ButtonsWrapper>
              ),
            },
            {
              Header: '',
              accessor: 'id' as keyof MatchType,
              id: 'audit',
              Cell: ({ row: { original: data } }) => (
                <Button
                  testId={`${TestIds.MatchTypesPageAuditButton}--${data.name}`}
                  color="action"
                  onClick={() => {
                    openAudit({ id: String(data.id), includeRelated: false });
                  }}
                >
                  {t('audit')}
                </Button>
              ),
            },
          ]
        : []),
    ],
    [t, isTranslator, handleUpdateMatchType, openAudit],
  );

  useEffect(() => {
    dispatch(
      actionsNT.matchTypesFetchItems({
        limit: FETCH_ALL_LIMIT,
        name: searchQuery.length ? searchQuery.toLowerCase() : undefined,
      }),
    );
  }, [dispatch, searchQuery]);

  return (
    <S.Wrapper>
      <S.DropdownWrapper>
        <DictionaryDropdown value={'match types'} />
      </S.DropdownWrapper>
      <S.ContentWrapper>
        <S.Header>
          <RoleGuards
            roles={[
              UserGroupEnum.ADMIN,
              UserGroupEnum.CUSTOMER,
              UserGroupEnum.OPERATOR,
              UserGroupEnum.SUPERVISOR,
            ]}
          >
            <Button
              onClick={showCreateMatchType}
              testId={TestIds.MatchTypesPageAddButton}
            >
              {t('add match type')}
            </Button>
          </RoleGuards>
          <TextInput
            placeholder={t('search')}
            onChange={e => setSearchQueryDebounced(e.target.value)}
            icon={<FontAwesomeIcon icon={faSearch} />}
            testId={TestIds.MatchTypesPageSearchInput}
          />
        </S.Header>
        <Table
          testId={TestIds.MatchTypesPageTable}
          testFieldName="name"
          columns={columns}
          data={items}
          columnsWidth={['auto', 'fit-content', 'fit-content']}
        />
      </S.ContentWrapper>
      {isCreateMatchTypeVisible && (
        <MatchTypeCreatePanel onClose={hideCreateMatchType} />
      )}
      {!!editMatchTypeId && (
        <MatchTypeEditPanel
          onClose={() => {
            setEditMatchTypeId(null);
          }}
          id={editMatchTypeId}
        />
      )}
    </S.Wrapper>
  );
}
