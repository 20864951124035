import { PropsWithChildren, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { SortingContext } from 'app/components/ui/Table';
import { SortableHeaderProps } from 'app/components/ui/Table/Table.types';

import { SortableIcon } from '..';

export function SortableHeader<D extends object = {}>({
  children,
  column: { id },
  columnName,
}: PropsWithChildren<SortableHeaderProps<D>>) {
  const { t } = useTranslation();
  const { sorting, handleSortingChange } = useContext(SortingContext);

  const handleClick = (asc: boolean | undefined) => {
    handleSortingChange(id)(asc);
  };

  return (
    <SortableIcon
      asc={sorting[id]}
      onClick={handleClick}
      columnName={columnName}
    >
      {children ? children : t(id)}
    </SortableIcon>
  );
}
