import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormikContext } from 'formik';
import isEqual from 'lodash-es/isEqual';
import { OutcomeCategory, UserGroupEnum } from 'sportsbook-openapi-react';

import useBooleanState from 'hooks/useBooleanState';
import { useSearch } from 'hooks/useSearch';
import { TestIds } from 'types/testIds.types';

import { TextInput } from 'app/components/forms';
import { CategoryCreatePanel } from 'app/components/panels/category';
import { RoleGuards } from 'app/components/RoleGuards';
import { Button, Table } from 'app/components/ui';

import { FormValues, sourcesOptions } from './constants';
import * as S from './styles';

interface Props {
  live: boolean;
  categories: OutcomeCategory[];
}

export const MarginForm = ({ categories, live }: Props) => {
  const { t } = useTranslation();
  const [isCreateCategoryVisible, showCreateCategory, hideCreateCategory] =
    useBooleanState();
  const { searchQuery, setSearchQueryDebounced } = useSearch();
  const { values, setFieldValue } = useFormikContext<FormValues>();

  const columns = [
    {
      Header: t('category').toString(),
      accessor: 'name',
    },
  ];

  const filteredCategories = useMemo(() => {
    if (!searchQuery) return categories;
    return categories.filter(category =>
      new RegExp('^' + searchQuery, 'i').test(category.name),
    );
  }, [categories, searchQuery]);

  return (
    <S.StyledFormContent>
      <S.StyledFilterWrapper>
        <S.StyledFilterBlock>
          <TextInput
            autoFocus={true}
            placeholder={t('search')}
            onChange={e => setSearchQueryDebounced(e.target.value)}
            icon={<FontAwesomeIcon icon={faSearch} />}
            testId={TestIds.ActiveCategoryPanelSearch}
          />
          <S.StyledInputField
            name="margin"
            type="number"
            placeholder={t('margin')}
            testId={TestIds.ActiveCategoryPanelMargin}
          />
          <S.StyledInputField
            name="maxOdd"
            type="number"
            placeholder={t('maxOdd short')}
            testId={TestIds.ActiveCategoryPanelMaxodd}
          />
          <RoleGuards
            roles={[
              UserGroupEnum.ADMIN,
              UserGroupEnum.OPERATOR,
              UserGroupEnum.SUPERVISOR,
            ]}
          >
            {!live && (
              <S.StyledSelectField
                name="mainSource"
                placeholder={t('active source')}
                options={sourcesOptions}
                isClearable
                testId={TestIds.ActiveCategoryPanelMainSource}
              />
            )}
          </RoleGuards>

          <RoleGuards roles={[UserGroupEnum.CUSTOMER]}>
            <S.StyledInputField
              name="delay"
              type="number"
              placeholder={t('delay')}
              testId={TestIds.ActiveCategoryPanelDelay}
            />
            <S.StyledInputField
              name="maxbet"
              type="number"
              placeholder={t('maxbet short')}
              testId={TestIds.ActiveCategoryPanelMaxbet}
            />
          </RoleGuards>
        </S.StyledFilterBlock>
        <RoleGuards
          roles={[
            UserGroupEnum.ADMIN,
            UserGroupEnum.OPERATOR,
            UserGroupEnum.SUPERVISOR,
          ]}
        >
          <Button
            type="button"
            onClick={showCreateCategory}
            color="link"
            testId={TestIds.ActiveCategoryPanelCreateButton}
          >
            {t('create category')}
          </Button>
        </RoleGuards>
      </S.StyledFilterWrapper>
      <S.TableWrapper>
        <Table
          testId={TestIds.ActiveCategoryPanelList}
          testFieldName="name"
          withCheckboxes
          columns={columns}
          columnsWidth={['fit-content', 'auto']}
          data={filteredCategories}
          onSelectCheckbox={selectedRows => {
            const selectedCategories = selectedRows.map(row => row.original.id);
            if (!isEqual(selectedCategories, values.categories)) {
              setFieldValue('categories', selectedCategories);
            }
          }}
        />
      </S.TableWrapper>
      {isCreateCategoryVisible && (
        <CategoryCreatePanel onClose={hideCreateCategory} />
      )}
    </S.StyledFormContent>
  );
};
