import { createSelector } from '@reduxjs/toolkit';
import { MarginProgression, Sport } from 'sportsbook-openapi-react';

import { RootState } from 'types';

import { getEntities } from 'app/providers/EntitiesProvider/selectors';
import { getEntityItems } from 'app/providers/EntitiesProvider/utils';

const getMarginProgressionsSubstate = (state: RootState) =>
  getEntities(state).marginProgressions;

export const getMarginProgressionsEntries = (state: RootState) =>
  getMarginProgressionsSubstate(state).entries;

export const getMarginProgressionById = (
  state,
  id: MarginProgression['id'],
): MarginProgression | undefined => getMarginProgressionsEntries(state)[id];

export const selectMarginProgressionItems = createSelector(
  [getMarginProgressionsSubstate],
  marginProgressions => getEntityItems(marginProgressions),
);

export const selectMarginProgressionItemsBySport = createSelector(
  [getMarginProgressionsSubstate, (_, sportId: Sport['id']) => sportId],
  (marginProgressions, sportId) =>
    getEntityItems(marginProgressions).filter(el => el.sportId === sportId),
);
