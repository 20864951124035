import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: '/',
  }),
  endpoints: builder => ({}),
});

export const {
  reducer: apiReducer,
  middleware: apiMiddleware,
  reducerPath,
} = api;
