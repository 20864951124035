import { useContext } from 'react';
import ReactSelect, {
  components,
  DropdownIndicatorProps,
  InputProps,
  MultiValueProps,
  SingleValueProps,
  Theme,
} from 'react-select';
import { ThemeContext } from 'styled-components';

import { getTestId } from 'utils/getTestId';
import { TestIds } from 'types/testIds.types';

import { Button } from 'app/components/ui';

import { getStyles, SelectWrapper } from './Select.styles';
import { BaseOptionType, SelectProps, Size } from './Select.types';

const sizeToHeight: Record<Size, number> = {
  s: 32,
  m: 40,
};

const CustomOption = props => {
  const { data } = props;

  return data.isButton ? (
    <components.Option
      {...props}
      innerProps={{
        ...props.innerProps,
        onClick: data.onClick,
        'data-test-id': `${TestIds.SelectOption}--${data.label}`,
      }}
    >
      <Button
        color="link"
        type="button"
        onClick={event => {
          event.stopPropagation();
          data.onClick();
        }}
      >
        {data.label}
      </Button>
    </components.Option>
  ) : (
    <components.Option
      {...props}
      innerProps={{
        ...props.innerProps,
        'data-test-id': `${TestIds.SelectOption}--${data.name}`,
      }}
    />
  );
};

const CustomInput = <OptionType extends BaseOptionType>({
  children,
  ...props
}: InputProps<OptionType>) => {
  return (
    <components.Input
      {...props}
      data-test-id={getTestId(
        (props.selectProps as SelectProps<OptionType>).testId,
        'input',
      )}
    />
  );
};

const SingleValueRenderer = <OptionType extends BaseOptionType>({
  children,
  ...props
}: SingleValueProps<OptionType>) => (
  <components.SingleValue {...props}>
    <span
      style={{
        width: 'fit-content',
      }}
      onMouseDown={event => {
        event.stopPropagation();
      }}
      data-test-id={getTestId(
        (props.selectProps as SelectProps<OptionType>).testId,
        'value',
      )}
    >
      {children}
    </span>
  </components.SingleValue>
);

const MultiValueRenderer = <OptionType extends BaseOptionType>({
  children,
  ...props
}: MultiValueProps<OptionType>) => (
  <components.MultiValue {...props}>
    <span
      data-test-id={getTestId(
        (props.selectProps as SelectProps<OptionType>).testId,
        'value',
      )}
    >
      {children}
    </span>
  </components.MultiValue>
);

const DropdownIndicator = <OptionType extends BaseOptionType>({
  children,
  ...props
}: DropdownIndicatorProps<OptionType>) => {
  return (
    <components.DropdownIndicator
      {...props}
      innerProps={{
        ...props.innerProps,
        // @ts-ignore
        'data-test-id': getTestId(
          (props.selectProps as SelectProps<OptionType>).testId,
          'dropdown-button',
        ),
      }}
    />
  );
};

export const Select = <
  OptionType extends BaseOptionType = BaseOptionType,
  isMulti extends boolean = false,
>({
  invalid,
  success,
  disabled,
  readOnly,
  size = 's',
  className,
  ...props
}: SelectProps<OptionType, isMulti>) => {
  const theme = useContext(ThemeContext);

  return (
    <SelectWrapper className={className} data-test-id={props.testId}>
      <ReactSelect
        {...props}
        styles={getStyles({ invalid, success, disabled, readOnly }, theme)}
        isDisabled={disabled}
        isSearchable={props.isSearchable ?? !readOnly}
        menuIsOpen={readOnly ? false : undefined}
        components={{
          IndicatorSeparator: null,
          Option: CustomOption,
          SingleValue: SingleValueRenderer,
          Input: CustomInput,
          MultiValue: MultiValueRenderer,
          DropdownIndicator,
          ...(props.components || {}),
        }}
        getOptionLabel={option => option.name}
        getOptionValue={option => String(option.id)}
        maxMenuHeight={150}
        theme={(selectTheme: Theme) => {
          return {
            ...selectTheme,
            colors: {
              ...selectTheme.colors,
              primary: theme.colors.primary[40],
              primary25: theme.colors.primary[20],
              primary50: theme.colors.primary[40],
              neutral5: theme.colors.primary[20],
              neutral0: theme.colors.primary[0],
            },
            borderRadius: 4,
            spacing: {
              ...selectTheme.spacing,
              controlHeight: sizeToHeight[size],
            },
          };
        }}
      />
    </SelectWrapper>
  );
};
