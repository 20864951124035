import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch } from 'react-router-dom';
import { Column } from 'react-table';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ActionsPopup,
  PanelInfo,
} from 'app/pages/Events/components/ActionsPopup';
import { EventsListFilters } from 'app/pages/Events/components/EventsListFilters';
import { getHomeAwayCompetitors } from 'app/pages/Events/utils';
import dayjs from 'dayjs';
import { isEqual } from 'lodash-es';
import {
  Competitor,
  Event,
  EventsApiGetEventsRequest,
  EventSource,
  EventStatus,
  Group,
  MatchType,
  SortingParameter,
  Sport,
  Tournament,
  UserGroupEnum,
} from 'sportsbook-openapi-react';

import { parseDate } from 'utils/datetime';
import { FilterCacheKeys } from 'utils/filters';
import { getAsyncLoadOptions } from 'utils/select';
import { cache, uncache } from 'utils/sessionStorage';
import { SortingCacheKeys, transformSortingMap } from 'utils/sorting';
import { useLazyLoading } from 'hooks/useLazyLoading';
import { useRequestState } from 'hooks/useRequestState';
import { FETCH_ALL_LIMIT } from 'consts';
import { TestIds } from 'types/testIds.types';

import { Checkbox } from 'app/components/forms';
import { ChangeEventLiveModal } from 'app/components/modals';
import { DependencyCreatePanel, RelationPanel } from 'app/components/panels';
import { RoleGuards } from 'app/components/RoleGuards';
import { Button, LoadingIndicator, Table } from 'app/components/ui';
import {
  AsyncSelectTableFilter,
  SelectTableFilter,
  SortableAsyncSelectTableFilter,
  SortableHeader,
  SortableSelectTableFilter,
} from 'app/components/ui/Table/components';
import { ColumnWidth } from 'app/components/ui/Table/Table.types';

import { actionsNT, Entities } from 'app/providers/EntitiesProvider';
import { getFavoriteEvents } from 'app/providers/EntitiesProvider/eventFavorites';
import {
  getEventsHasMore,
  getEventsSortedIds,
  selectEventsItems,
} from 'app/providers/EntitiesProvider/events';
import { getGroupsEntries } from 'app/providers/EntitiesProvider/groups';
import {
  getMatchtypesEntries,
  selectMatchTypesItems,
} from 'app/providers/EntitiesProvider/matchTypes';
import { selectSourcesOptions } from 'app/providers/EntitiesProvider/sources';
import {
  getSportsEntries,
  selectSportsItems,
} from 'app/providers/EntitiesProvider/sports';

import { initialEndDay, initialStartDay } from './constants';
import * as S from './EventsList.styles';

export const RESULT_STATUSES = [
  EventStatus.TRADING,
  EventStatus.SUSPENDED,
  EventStatus.CLOSED,
  EventStatus.ENDED,
  EventStatus.CANCELLED,
  EventStatus.POSTPONED,
  EventStatus.RETIRED,
];

const NOT_FINISHED_STATUSES = Object.values(EventStatus).filter(
  status => status !== EventStatus.ENDED,
);

const liveOptions = [
  {
    id: false,
    name: 'line',
  },
  {
    id: true,
    name: 'live',
  },
];

export interface EventFilters {
  sport?: Maybe<Sport>;
  group?: Maybe<Group>;
  tournament?: Maybe<Tournament>;
  source?: Maybe<{
    id: EventSource;
    name: string;
  }>;
  live?: Maybe<{
    id: boolean;
    name: string;
  }>;
  homeCompetitors?: Maybe<Competitor[]>;
  awayCompetitors?: Maybe<Competitor[]>;
  status?: Maybe<EnumOption<EventStatus>>;
  matchType?: Maybe<MatchType>;
  scheduledFrom?: Date;
  scheduledTo?: Date;
  notBegan?: boolean;
  favorite?: boolean;
  unresulted?: boolean;
}

export interface EventSorting {
  id?: boolean;
  sport?: boolean;
  source?: boolean;
  group?: boolean;
  tournament?: boolean;
}

const filtersToPayload = (
  filters: EventFilters,
  statuses: EventStatus[] | undefined = undefined,
): Partial<EventsApiGetEventsRequest> => ({
  live: filters.live?.id,
  sportId: filters.sport?.id,
  groupId: filters.group?.id,
  tournamentId: filters.tournament?.id,
  source: filters.source?.id!,
  scheduledFrom: parseDate(filters.scheduledFrom),
  scheduledTo: parseDate(filters.scheduledTo),
  homeCompetitorIds: filters.homeCompetitors
    ? filters.homeCompetitors.map(c => c.id)
    : undefined,
  awayCompetitorIds: filters.awayCompetitors
    ? filters.awayCompetitors.map(c => c.id)
    : undefined,
  statuses: filters.status ? [filters.status.id] : statuses,
  matchTypeId: filters.matchType?.id,
  resulted: filters.unresulted ? false : undefined,
});

const initialTableState = {
  selectedEvents: [],
};
interface SourcesTableState {
  selectedEvents: number[];
}

const getEventPath = (eventId, isResults) => {
  if (isResults) {
    return `/results/${eventId}`;
  } else {
    return `/events/${eventId}`;
  }
};

export const EventsList: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [changeLiveEvents, setChangeLiveEvents] = useState<Event[]>([]);

  const isLive = useMatch('/live');
  const isResults = useMatch('/results');

  const filterCacheKey = isResults
    ? FilterCacheKeys.RESULTS
    : isLive
      ? FilterCacheKeys.LIVE
      : FilterCacheKeys.LINE;

  const sortingCacheKey = isResults
    ? SortingCacheKeys.RESULTS
    : isLive
      ? SortingCacheKeys.LIVE
      : SortingCacheKeys.LINE;

  useEffect(() => {
    dispatch(actionsNT.sportsFetchItems({ limit: FETCH_ALL_LIMIT }));
    dispatch(actionsNT.groupsFetchItems({ limit: FETCH_ALL_LIMIT }));
    dispatch(actionsNT.sourcesFetchItems());
    dispatch(actionsNT.matchTypesFetchItems({ limit: FETCH_ALL_LIMIT }));
  }, [dispatch]);

  const events = useSelector(selectEventsItems);
  const sortedIds = useSelector(getEventsSortedIds);
  const hasMore = useSelector(getEventsHasMore);
  const { isLoading: eventsLoading, error } = useRequestState(
    Entities.EVENTS,
    'fetchItems',
  );

  const groups = useSelector(getGroupsEntries);
  const { isLoading: groupsLoading } = useRequestState(
    Entities.GROUPS,
    'fetchItems',
  );

  const sportsItems = useSelector(selectSportsItems);
  const { isLoading: sportsLoading } = useRequestState(
    Entities.SPORTS,
    'fetchItems',
  );
  const sports = useSelector(getSportsEntries);
  const sourcesOptions = useSelector(selectSourcesOptions);
  const { isLoading: sourcesLoading } = useRequestState(
    Entities.SOURCES,
    'fetchItems',
  );
  const matchTypesOptions = useSelector(selectMatchTypesItems);
  const matchTypesEntries = useSelector(getMatchtypesEntries);
  const [createRelationPanel, setCreateRelationPanel] =
    useState<null | PanelInfo>(null);
  const [createDependencyPanel, setCreateDependencyPanel] =
    useState<null | PanelInfo>(null);

  const favorites = useSelector(getFavoriteEvents);
  const [filters, setFilters] = useState<EventFilters>({
    scheduledFrom: initialStartDay,
    scheduledTo: initialEndDay,
    ...uncache<EventFilters>(filterCacheKey, ['scheduledFrom', 'scheduledTo']),
  });
  const [sorting, setSorting] = useState<EventSorting>(
    uncache<EventSorting>(sortingCacheKey) ?? {},
  );

  useEffect(() => {
    const rehydratedFilters = uncache<EventFilters>(filterCacheKey, [
      'scheduledFrom',
      'scheduledTo',
    ]);
    if (!isEqual(rehydratedFilters, filters)) {
      cache(filterCacheKey, filters);
    }
  }, [filterCacheKey, filters]);

  useEffect(() => {
    const rehydratedSorting = uncache<SortingParameter[]>(sortingCacheKey);
    if (!isEqual(rehydratedSorting, sorting)) {
      cache(sortingCacheKey, sorting);
    }
  }, [sorting, sortingCacheKey]);

  const onLazyLoad = useCallback(
    ({ limit, offset }) => {
      const sort = transformSortingMap<SortingParameter>(sorting);
      const ids = filters.favorite
        ? Object.values(favorites).map(c => c.eventId)
        : undefined;

      if (ids && !ids.length) {
        dispatch(actionsNT.eventsReset());
        return;
      }
      if (isResults) {
        dispatch(
          actionsNT.eventsFetchItems({
            limit,
            offset,
            withPagination: true,
            ...filtersToPayload(filters, RESULT_STATUSES),
            ids,
            sort,
          }),
        );
      } else {
        dispatch(
          actionsNT.eventsFetchItems({
            limit,
            offset,
            withPagination: true,
            ...filtersToPayload(filters, NOT_FINISHED_STATUSES),
            live: filters.live?.id ? filters.live.id : !!isLive,
            ids,
            sort,
          }),
        );
      }
    },
    [sorting, filters, favorites, isResults, dispatch, isLive],
  );

  const handleResetAll = () => {
    setFilters({
      scheduledFrom: initialStartDay,
      scheduledTo: initialEndDay,
    });
    setSorting({});
  };

  const onBottom = useLazyLoading({
    onLazyLoad,
    hasMore,
    extraDeps: [filters, sorting],
    onPaginationReset: () => {
      dispatch(actionsNT.eventsReset());
    },
  });

  const [tableState, setTableState] =
    useState<SourcesTableState>(initialTableState);

  const handleCheckboxClick = useCallback(
    (id: number) => (value: boolean) => {
      if (id) {
        if (value) {
          setTableState(prev => ({
            ...prev,
            selectedEvents: [...prev.selectedEvents, id],
          }));
        } else {
          setTableState(prev => ({
            ...prev,
            selectedEvents: prev.selectedEvents.filter(item => item !== id),
          }));
        }
      }
    },
    [],
  );

  const handleCheckAllClick = useCallback(() => {
    if (tableState.selectedEvents.length !== sortedIds.length) {
      setTableState({
        selectedEvents: sortedIds,
      });
    } else {
      setTableState({
        selectedEvents: [],
      });
    }
  }, [sortedIds, tableState.selectedEvents.length]);

  const idColumn: Column<Event> = useMemo(
    () => ({
      Header: props => (
        <>
          <SortableHeader {...props} columnName="id">
            <Checkbox
              onChange={handleCheckAllClick}
              checked={tableState.selectedEvents.length === sortedIds.length}
              label={
                <>
                  {t('id')}({tableState.selectedEvents.length})
                </>
              }
            />
          </SortableHeader>
        </>
      ),
      accessor: 'id',
      Cell: ({ value }) => (
        <>
          <Checkbox
            onChange={handleCheckboxClick(value)}
            checked={tableState.selectedEvents.includes(value!)}
            label={
              <S.StyledLink target="_blank" to={getEventPath(value, isResults)}>
                {value}
              </S.StyledLink>
            }
          />
        </>
      ),
    }),
    [
      handleCheckAllClick,
      tableState.selectedEvents,
      sortedIds.length,
      t,
      handleCheckboxClick,
      isResults,
    ],
  );

  const sportColumn: Column<Event> = useMemo(
    () => ({
      Header: props => (
        <SortableSelectTableFilter
          options={sportsItems}
          {...props}
          columnName="sport"
        />
      ),
      id: 'sport',
      accessor: 'tournament',
      Cell: ({ value }) => (
        <>
          {value?.sportId &&
            sports[value?.sportId] &&
            sports[value?.sportId].name}
        </>
      ),
    }),
    [sports, sportsItems],
  );

  const dateColumn: Column<Event> = {
    Header: props => <SortableHeader {...props} columnName="date" />,
    id: 'date',
    accessor: 'eventDate',
    Cell: ({ value }) => <>{dayjs(value).format('HH:mm DD.MM.YYYY')}</>,
  };

  const sourceColumn: Column<Event> = useMemo(
    () => ({
      Header: props => (
        <SortableSelectTableFilter
          options={sourcesOptions}
          {...props}
          columnName="source"
        />
      ),
      id: 'source',
      accessor: 'mainSource',
      Cell: ({ value }) => <>{value?.replace('SOURCE_', '')}</>,
    }),
    [sourcesOptions],
  );

  const groupColumn: Column<Event> = useMemo(
    () => ({
      Header: props => (
        <SortableAsyncSelectTableFilter
          loadOptions={getAsyncLoadOptions({
            entity: 'groups',
            sportId: filters.sport?.id,
            scheduledFrom: filters.scheduledFrom,
            scheduledTo: filters.scheduledTo,
          })}
          {...props}
          columnName="group"
        />
      ),
      id: 'group',
      accessor: 'tournament',
      Cell: ({ value }) => (
        <>
          {value?.groupId &&
            groups[value?.groupId] &&
            groups[value?.groupId].name}
        </>
      ),
    }),
    [filters.scheduledFrom, filters.scheduledTo, filters.sport?.id, groups],
  );

  const competitorAColumn: Column<Event> = useMemo(
    () => ({
      Header: props => (
        <AsyncSelectTableFilter
          isMulti
          loadOptions={getAsyncLoadOptions({
            entity: 'competitors',
            sportId: filters.sport?.id,
            groupId: filters.group?.id,
            scheduledFrom: filters.scheduledFrom,
            scheduledTo: filters.scheduledTo,
            excludeOptions: [filters.awayCompetitors],
          })}
          {...props}
        />
      ),
      id: 'homeCompetitors',
      accessor: 'competitors',
      Cell: ({ row: { original } }) => (
        <>{getHomeAwayCompetitors(original).homeCompetitor?.name}</>
      ),
    }),
    [
      filters.awayCompetitors,
      filters.group,
      filters.scheduledFrom,
      filters.scheduledTo,
      filters.sport,
    ],
  );

  const competitorBColumn: Column<Event> = useMemo(
    () => ({
      Header: props => (
        <AsyncSelectTableFilter
          isMulti
          loadOptions={getAsyncLoadOptions({
            entity: 'competitors',
            sportId: filters.sport?.id,
            groupId: filters.group?.id,
            scheduledFrom: filters.scheduledFrom,
            scheduledTo: filters.scheduledTo,
            excludeOptions: [filters.homeCompetitors],
          })}
          {...props}
        />
      ),
      id: 'awayCompetitors',
      accessor: 'competitors',
      Cell: ({ row: { original } }) => (
        <>{getHomeAwayCompetitors(original).awayCompetitor?.name}</>
      ),
    }),
    [
      filters.group,
      filters.homeCompetitors,
      filters.scheduledFrom,
      filters.scheduledTo,
      filters.sport,
    ],
  );

  const tournamentColumn: Column<Event> = useMemo(
    () => ({
      Header: props => (
        <SortableAsyncSelectTableFilter
          columnName="tournament"
          loadOptions={getAsyncLoadOptions({
            entity: 'tournaments',
            sportId: filters.sport?.id,
            groupId: filters.group?.id,
            scheduledFrom: filters.scheduledFrom,
            scheduledTo: filters.scheduledTo,
          })}
          {...props}
        />
      ),
      accessor: 'tournament',
      Cell: ({ value }) => <>{value?.name}</>,
    }),
    [filters.group, filters.scheduledFrom, filters.scheduledTo, filters.sport],
  );

  const lineActionsColumn: Column<Event> = useMemo(
    () => ({
      Header: '',
      Cell: ({ value, row: { original }, data }) => (
        <S.ButtonsWrapper>
          <RoleGuards
            roles={[
              UserGroupEnum.ADMIN,
              UserGroupEnum.SUPERVISOR,
              UserGroupEnum.OPERATOR,
            ]}
          >
            <Button
              color="action"
              onClick={event => {
                event.stopPropagation();
                if (tableState.selectedEvents.length) {
                  const populatedEvents = data.filter(row =>
                    tableState.selectedEvents.includes(row.id),
                  );
                  setChangeLiveEvents(populatedEvents);
                  setTableState({ selectedEvents: [] });
                } else {
                  setChangeLiveEvents([original]);
                }
              }}
            >
              <FontAwesomeIcon icon={faLongArrowAltRight} />

              {t('to live')}
            </Button>
          </RoleGuards>
          <ActionsPopup
            selectedEvents={tableState.selectedEvents}
            eventId={value}
            resetEvents={() => setTableState({ selectedEvents: [] })}
            setCreateRelationPanel={setCreateRelationPanel}
            setCreateDependencyPanel={setCreateDependencyPanel}
            showSuspends={!isResults}
            showRefresh={true}
          />
        </S.ButtonsWrapper>
      ),
      accessor: 'id',
      id: 'actions',
    }),
    [isResults, t, tableState.selectedEvents],
  );

  const liveActionsColumn: Column<Event> = useMemo(
    () => ({
      Header: '',
      Cell: ({ value, row: { original }, data }) => (
        <S.ButtonsWrapper>
          <RoleGuards
            roles={[
              UserGroupEnum.ADMIN,
              UserGroupEnum.SUPERVISOR,
              UserGroupEnum.OPERATOR,
            ]}
          >
            <Button
              color="action"
              onClick={event => {
                event.stopPropagation();
                if (tableState.selectedEvents.length) {
                  const populatedEvents = data.filter(row =>
                    tableState.selectedEvents.includes(row.id),
                  );
                  setChangeLiveEvents(populatedEvents);
                } else {
                  setChangeLiveEvents([original]);
                }
              }}
            >
              <FontAwesomeIcon icon={faLongArrowAltRight} />

              {t('to line')}
            </Button>
          </RoleGuards>
          <ActionsPopup
            selectedEvents={tableState.selectedEvents}
            eventId={value}
            resetEvents={() => setTableState({ selectedEvents: [] })}
            setCreateRelationPanel={setCreateRelationPanel}
            setCreateDependencyPanel={setCreateDependencyPanel}
            showRefresh={true}
          />
        </S.ButtonsWrapper>
      ),
      accessor: 'id',
      id: 'actions',
    }),
    [tableState.selectedEvents, t],
  );

  const resultActionsColumn: Column<Event> = useMemo(
    () => ({
      Header: '',
      Cell: ({ value }) => (
        <ActionsPopup
          selectedEvents={tableState.selectedEvents}
          eventId={value}
          resetEvents={() => setTableState({ selectedEvents: [] })}
          showDependencies={false}
          showRelations={false}
        />
      ),
      accessor: 'id',
      id: 'actions',
    }),
    [tableState.selectedEvents],
  );

  const liveColumn: Column<Event> = {
    Header: SortableHeader,
    accessor: 'scheduledLive',
    Cell: ({ value }) => <>{dayjs(value).format('HH:mm DD.MM.YYYY')}</>,
  };

  const typeColumn: Column<Event> = {
    Header: props => <SelectTableFilter options={liveOptions} {...props} />,
    accessor: 'live',
    Cell: ({ value }) => <>{value ? t('live') : t('line')}</>,
  };

  const statusColumn: Column<Event> = {
    Header: props => (
      <SelectTableFilter
        options={(isResults ? RESULT_STATUSES : NOT_FINISHED_STATUSES).map(
          status => ({
            name: t(`statuses.labels.${status}`),
            id: status,
          }),
        )}
        {...props}
      />
    ),
    accessor: 'status',
    Cell: ({ value }) => <>{t(`statuses.labels.${value}`)}</>,
  };

  const matchTypeColumn: Column<Event> = useMemo(
    () => ({
      Header: props => (
        <SelectTableFilter options={matchTypesOptions} {...props} />
      ),
      accessor: 'matchTypeId',
      id: 'matchType',
      Cell: ({ value }) => <>{matchTypesEntries[value]?.name}</>,
    }),
    [matchTypesEntries, matchTypesOptions],
  );

  const lineColumns: Column<Event>[] = [
    idColumn,
    sportColumn,
    dateColumn,
    sourceColumn,
    groupColumn,
    competitorAColumn,
    competitorBColumn,
    tournamentColumn,
    statusColumn,
    matchTypeColumn,
    lineActionsColumn,
  ];

  const liveColumns: Column<Event>[] = [
    idColumn,
    sportColumn,
    liveColumn,
    sourceColumn,
    groupColumn,
    competitorAColumn,
    competitorBColumn,
    tournamentColumn,
    statusColumn,
    matchTypeColumn,
    liveActionsColumn,
  ];

  const resultsColumns: Column<Event>[] = [
    idColumn,
    sportColumn,
    typeColumn,
    liveColumn,
    sourceColumn,
    groupColumn,
    competitorAColumn,
    competitorBColumn,
    tournamentColumn,
    statusColumn,
    matchTypeColumn,
    resultActionsColumn,
  ];

  const columns: Column<Event>[] = isResults
    ? resultsColumns
    : isLive
      ? liveColumns
      : lineColumns;

  const initialLoading = groupsLoading || sportsLoading || sourcesLoading;

  if (initialLoading) {
    return <LoadingIndicator type="full" />;
  }

  const columnsWidth = isResults
    ? [7, 10, 5, 10, 10, 10, 10, 10, 10, 10, 8, 5]
    : [7, 10, 10, 10, 10, 10, 10, 10, 8, 8, 'min-content'];

  return (
    <>
      {error ? (
        <S.EventsListError>{t('events error')}</S.EventsListError>
      ) : (
        <S.EventsList>
          <EventsListFilters
            filters={filters}
            onFilterChange={setFilters}
            resetAll={handleResetAll}
          />
          <S.EventsTableWrapper>
            <Table<Event, EventFilters, EventSorting>
              testId={TestIds.EventsPageTable}
              columns={columns}
              data={events}
              onBottom={onBottom}
              filters={{
                value: filters,
                onFilterChange: setFilters,
              }}
              sorting={{
                value: sorting,
                onSortingChange: setSorting,
              }}
              columnsWidth={columnsWidth as ColumnWidth[]}
            />
            {eventsLoading && <LoadingIndicator type="absolute" />}
          </S.EventsTableWrapper>
        </S.EventsList>
      )}
      {changeLiveEvents.length > 0 && (
        <ChangeEventLiveModal
          closeModal={() => setChangeLiveEvents([])}
          events={changeLiveEvents}
          withDeletion
        />
      )}
      {!!createRelationPanel && (
        <RelationPanel
          onClose={() => {
            setCreateRelationPanel(null);
          }}
          actionType="create"
          selectedEvents={createRelationPanel.selectedEvents}
          id={createRelationPanel.eventId}
          onSuccess={() => setTableState({ selectedEvents: [] })}
        />
      )}
      {!!createDependencyPanel && (
        <DependencyCreatePanel
          id={createDependencyPanel.eventId}
          selectedEvents={createDependencyPanel.selectedEvents}
          onClose={() => {
            setCreateDependencyPanel(null);
          }}
          onSuccess={() => setTableState({ selectedEvents: [] })}
        />
      )}
    </>
  );
};
