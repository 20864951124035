import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Dropdown, Text } from 'app/components/ui';

import { useUserStore } from 'store';
import { getIsCustomer, getIsOperator, getIsTranslator } from 'store/user';

interface Props {
  value: string;
}

export const DictionaryDropdown = ({ value }: Props) => {
  const { t } = useTranslation();
  const { isCustomer } = useUserStore(getIsCustomer);
  const isOperator = useUserStore(getIsOperator);
  const isTranslator = useUserStore(getIsTranslator);

  const dropdownOptions = useMemo(() => {
    switch (true) {
      case isTranslator: {
        return [
          <Link to={'/dictionary'}>{t('sport')}</Link>,
          <Link to={'/dictionary/outcomes'}>{t('outcomes')}</Link>,
          <Link to={'/dictionary/countries'}>{t('countries')}</Link>,
          <Link to={'/dictionary/competitors'}>{t('competitors')}</Link>,
          <Link to={'/dictionary/players'}>{t('players')}</Link>,
          <Link to={'/dictionary/languages'}>{t('languages')}</Link>,
          <Link to={'/dictionary/matchTypes'}>{t('match types')}</Link>,
          <Link to={'/dictionary/matchPhases'}>{t('match phases')}</Link>,
          <Link to={'/dictionary/comments'}>{t('comments')}</Link>,
          <Link to={'/dictionary/currencies'}>{t('currencies')}</Link>,
        ];
      }
      case isOperator: {
        return [
          <Link to={'/dictionary'}>{t('sport')}</Link>,
          <Link to={'/dictionary/outcomes'}>{t('outcomes')}</Link>,
          <Link to={'/dictionary/countries'}>{t('countries')}</Link>,
          <Link to={'/dictionary/competitors'}>{t('competitors')}</Link>,
          <Link to={'/dictionary/players'}>{t('players')}</Link>,
          <Link to={'/dictionary/languages'}>{t('languages')}</Link>,
          <Link to={'/dictionary/comments'}>{t('comments')}</Link>,
        ];
      }
      case isCustomer: {
        return [
          <Link to={'/dictionary'}>{t('sport')}</Link>,
          <Link to={'/dictionary/progressions'}>{t('progressions')}</Link>,
        ];
      }
      default: {
        return [
          <Link to={'/dictionary'}>{t('sport')}</Link>,
          <Link to={'/dictionary/outcomes'}>{t('outcomes')}</Link>,
          <Link to={'/dictionary/countries'}>{t('countries')}</Link>,
          <Link to={'/dictionary/competitors'}>{t('competitors')}</Link>,
          <Link to={'/dictionary/players'}>{t('players')}</Link>,
          <Link to={'/dictionary/markets'}>{t('markets')}</Link>,
          <Link to={'/dictionary/languages'}>{t('languages')}</Link>,
          <Link to={'/dictionary/matchTypes'}>{t('match types')}</Link>,
          <Link to={'/dictionary/matchPhases'}>{t('match phases')}</Link>,
          <Link to={'/dictionary/tags'}>{t('tags')}</Link>,
          <Link to={'/dictionary/comments'}>{t('comments')}</Link>,
          <Link to={'/dictionary/cross-mappings'}>{t('cross-mapping')}</Link>,
          <Link to={'/dictionary/progressions'}>{t('progressions')}</Link>,
          <Link to={'/dictionary/currencies'}>{t('currencies')}</Link>,
          <Link to={'/dictionary/calculators'}>{t('calculators')}</Link>,
        ];
      }
    }
  }, [isCustomer, isOperator, isTranslator, t]);

  return (
    <Dropdown
      component={
        <Text size="title" weight={500}>
          {t(value)}
        </Text>
      }
      options={dropdownOptions}
    />
  );
};
