import { maxOddsApi } from 'app/api';

const api = {
  endpoint: maxOddsApi,
  methods: {
    fetchItems: 'getMaxOdds',
    fetchItem: 'getMaxOdd',
    createList: 'createMaxOddList',
    update: 'updateMaxOdd',
    delete: 'deleteMaxOdd',
  },
};

export default api;

export const maxOddsByEventApi = {
  endpoint: maxOddsApi,
  methods: {
    fetchItems: 'getMaxOddEventList',
  },
};
