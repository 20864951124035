import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { eventsApi } from 'app/api';
import { getSelectedCategory, getStats } from 'app/pages/Events/utils';
import { ResultCategoriesTable } from 'app/pages/Results/components/EventResultsTable/components/ResultCategoriesTable';
import { FieldArray, Form, FormikProvider, useFormik } from 'formik';
import { HTTPError } from 'ky';
import { groupBy, isEqual, uniq, uniqueId } from 'lodash-es';
import { ErrorCode, SportEventResult } from 'sportsbook-openapi-react';

import { FETCH_ALL_LIMIT } from 'consts';
import { RootState } from 'types';

import { CheckboxField } from 'app/components/forms';
import { Button, Dialog, Text } from 'app/components/ui';

import { actionsNT } from 'app/providers/EntitiesProvider';
import { getCategoriesEntries } from 'app/providers/EntitiesProvider/categories';
import { getEventOddsByEventId } from 'app/providers/EntitiesProvider/eventsOdds';
import { getEventStats } from 'app/providers/EntitiesProvider/eventStats';
import { selectMatchPhaseByCode } from 'app/providers/EntitiesProvider/matchPhases';
import { getTypesEntries } from 'app/providers/EntitiesProvider/type';

import * as S from './AutocalculateModal.styles';
import { finalCalculate, partialCalculate } from './AutocalculateModal.utils';
import {
  AutocalculationModalProps,
  AutocalculationTypeEnum,
  StatisticsValues,
} from './AutocalculationModal.types';
import {
  finalPeriodsInitialState,
  partialPeriodsInitialState,
} from './constants';
import { PreviewResultsTable } from './PreviewResultsTable';

const VALIDATION_ERRORS = [
  ErrorCode.RESULTS_AUTO_CALCULATION_INVALID_STATS,
  ErrorCode.RESULTS_AUTO_CALCULATION_STATS_AND_RESULTS_MISMATCH,
  ErrorCode.RESULTS_AUTO_CALCULATION_STATS_MISMATCH,
];

export const AutocalculateModal = ({
  event,
  closeModal,
  autocalculationType,
}: AutocalculationModalProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const categories = useSelector(getCategoriesEntries);
  const [isLoading, setIsLoading] = useState(false);
  const [validationError, setValidationError] = useState<ErrorCode>();
  const odds = useSelector((state: RootState) =>
    getEventOddsByEventId(state, event.id),
  );
  const types = useSelector(getTypesEntries);
  const eventStats = useSelector(state => getEventStats(state, event.id));
  const endedPhase = useSelector(state =>
    selectMatchPhaseByCode(state, 'Ended'),
  );

  const stats = useMemo(() => getStats(eventStats?.v2), [eventStats?.v2]);
  const [results, setResults] = useState<{
    raw: SportEventResult[];
    mapped: Dictionary<SportEventResult[]>;
  }>({
    raw: [],
    mapped: {},
  });
  const [selectedCategoryId, selectCategoryId] = useState(0);

  useEffect(() => {
    if (autocalculationType === AutocalculationTypeEnum.FINAL) {
      dispatch(actionsNT.matchPhasesFetchItems({ limit: FETCH_ALL_LIMIT }));
    }
  }, [autocalculationType, dispatch]);

  const selectedCategory = useMemo(
    () => getSelectedCategory(selectedCategoryId, categories, odds),
    [selectedCategoryId, categories, odds],
  );

  const calculate = async (values: StatisticsValues) => {
    let sportEventResult: Array<SportEventResult> = [];
    switch (autocalculationType) {
      case AutocalculationTypeEnum.FINAL: {
        try {
          sportEventResult = await eventsApi.autoCalculateEventResults({
            eventId: event.id,
            sportEventStatsV2: finalCalculate(values, event.id),
          });
        } catch (err) {
          if (err instanceof HTTPError) {
            const body = await err.response.json();
            const errorCode = body.errors[0].code;
            if (VALIDATION_ERRORS.includes(errorCode)) {
              setValidationError(errorCode);
            }
          }
        }
        break;
      }
      case AutocalculationTypeEnum.PARTIAL: {
        sportEventResult = await eventsApi.partiallyAutoCalculateEventResults({
          eventId: event.id,
          resultsPartialAutoCalculationInput: partialCalculate(
            values,
            event.id,
          ),
        });
        break;
      }
    }

    setResults({
      raw: sportEventResult,
      mapped: groupBy(sportEventResult, result => String(result.outcomeTypeId)),
    });
  };

  const onConfirm = async (skipStatsValidation = false) => {
    setIsLoading(true);
    try {
      if (autocalculationType === AutocalculationTypeEnum.FINAL) {
        await eventsApi.submitAutoCalculatedResults({
          eventId: event.id,
          resultsAutoCalculationSubmission: {
            results: results.raw,
            stats: finalCalculate(form.values, event.id, endedPhase),
            skipStatsValidation,
          },
        });
      } else {
        await eventsApi.submitPartialAutoCalculatedResults({
          eventId: event.id,
          partialResultsAutoCalculationSubmission: {
            results: results.raw,
            partialStats: partialCalculate(form.values, event.id),
            skipStatsValidation,
          },
        });
      }
      dispatch(actionsNT.eventsResultsFetchItem({ eventId: event.id }));
      closeModal();
    } catch (err) {
      if (err instanceof HTTPError) {
        const body = await err.response.json();
        const errorCode = body.errors[0].code;
        if (VALIDATION_ERRORS.includes(errorCode)) {
          setValidationError(errorCode);
        }
      }
    }
    setIsLoading(false);
  };

  const filteredResults = useMemo(() => {
    let filteredResults = [
      ...(selectedCategory?.sources[0]?.probabilities ?? []),
    ];

    selectedCategory?.sources.forEach(source => {
      source.probabilities.forEach(odd => {
        if (
          !filteredResults.some(
            resultOdd =>
              resultOdd.outcomeTypeId === odd.outcomeTypeId &&
              isEqual(new Set(resultOdd.parameters), new Set(odd.parameters)),
          )
        ) {
          filteredResults.push(odd);
        }
      });
    });

    return filteredResults;
  }, [selectedCategory?.sources]);

  useEffect(() => {
    const ids = uniq(filteredResults.map(p => p.outcomeTypeId));
    if (ids.length) {
      dispatch(
        actionsNT.typesFetchItems({
          ids,
          withPagination: true,
        }),
      );
    }
  }, [dispatch, filteredResults]);

  const form = useFormik<StatisticsValues>({
    onSubmit: calculate,
    initialValues: {
      match: {
        score: {
          team1: stats?.homeScore ?? 0,
          team2: stats?.awayScore ?? 0,
        },
      },
      periods: stats?.homePeriodStats.length
        ? stats.homePeriodStats.map((periodStat, index) => ({
            id: uniqueId(),
            score: {
              team1: periodStat.stat.value.numberValue ?? 0,
              team2: stats.awayPeriodStats[index].stat.value.numberValue ?? 0,
            },
          }))
        : autocalculationType === AutocalculationTypeEnum.FINAL
          ? finalPeriodsInitialState
          : partialPeriodsInitialState,
    },
  });

  useEffect(() => {
    if (odds) {
      const selectedCategory = odds.categories[0];
      const selectedCategoryId = selectedCategory?.outcomeCategoryId;
      if (selectedCategory) {
        selectCategoryId(selectedCategoryId);
      }
    }
  }, [odds, event.mainSource]);

  const withDeletion = (index: number) => {
    return (
      (index > 1 && autocalculationType === AutocalculationTypeEnum.FINAL) ||
      (index > 2 && autocalculationType === AutocalculationTypeEnum.PARTIAL)
    );
  };

  return (
    <Dialog
      text={t('auto-calculation')}
      onClose={closeModal}
      onConfirm={onConfirm}
      strictWidth={false}
      withoutActions
    >
      <FormikProvider value={form}>
        <Form>
          <S.Wrapper>
            <FieldArray
              name="periods"
              render={arrayHelpers => (
                <>
                  <S.StatisticsFormWrapper>
                    {autocalculationType === AutocalculationTypeEnum.FINAL && (
                      <S.PeriodItem>
                        <Text weight={600}>{t('match')}</Text>
                        <S.PeriodInputsBlock>
                          <S.InputWraper>
                            <Text>{t('team score')} 1</Text>
                            <S.StyledInput name={`match.score.team1`} />
                          </S.InputWraper>
                          <S.InputWraper>
                            <Text>{t('team score')} 2</Text>
                            <S.StyledInput name={`match.score.team2`} />
                          </S.InputWraper>
                        </S.PeriodInputsBlock>
                      </S.PeriodItem>
                    )}
                    <>
                      {form.values.periods.map((period, index) => (
                        <S.PeriodItem key={period.id}>
                          {autocalculationType ===
                          AutocalculationTypeEnum.FINAL ? (
                            <Text weight={600}>
                              {t('period')} {index + 1}
                            </Text>
                          ) : (
                            <CheckboxField
                              label={`${t('period')} ${index + 1}`}
                              name={`periods[${index}].enabled`}
                            />
                          )}

                          <S.PeriodInputsBlock>
                            <S.InputWraper>
                              <Text>{t('team score')} 1</Text>
                              <S.StyledInput
                                name={`periods[${index}].score.team1`}
                              />
                            </S.InputWraper>
                            <S.InputWraper>
                              <Text>{t('team score')} 2</Text>
                              <S.InputItem>
                                <S.StyledInput
                                  name={`periods[${index}].score.team2`}
                                />
                                {withDeletion(index) && (
                                  <Button
                                    type="button"
                                    color="action"
                                    onClick={arrayHelpers.handleRemove(index)}
                                  >
                                    <FontAwesomeIcon icon={faTrash} />
                                  </Button>
                                )}
                              </S.InputItem>
                            </S.InputWraper>
                          </S.PeriodInputsBlock>
                        </S.PeriodItem>
                      ))}
                    </>
                  </S.StatisticsFormWrapper>
                  <S.ButtonsWrapper>
                    <Button
                      type="button"
                      color="secondary"
                      disabled={form.values.periods.length === 14}
                      onClick={() =>
                        arrayHelpers.push({
                          id: uniqueId(),
                          score: {
                            team1: 0,
                            team2: 0,
                          },
                        })
                      }
                    >
                      {t('add')}
                    </Button>
                    <Button type="submit" color="primary">
                      {t('calculate')}
                    </Button>
                  </S.ButtonsWrapper>
                </>
              )}
            />
            <S.OddsTableContainer>
              <S.OddsTable>
                <ResultCategoriesTable
                  event={event}
                  selectCategoryId={selectCategoryId}
                  selectedCategory={selectedCategory}
                  settings={{ withSearch: false, withSource: false }}
                />
              </S.OddsTable>
              {!!selectedCategory && (
                <S.OddsTable>
                  <PreviewResultsTable
                    event={event}
                    results={filteredResults}
                    mappedResults={results.mapped}
                    types={types}
                  />
                </S.OddsTable>
              )}
            </S.OddsTableContainer>
            <S.Footer>
              <Button
                type="button"
                onClick={() => onConfirm()}
                disabled={isLoading || !results.raw.length}
              >
                {t('confirm')}
              </Button>
              <Button type="button" color="action" onClick={closeModal}>
                {t('cancel')}
              </Button>
            </S.Footer>
          </S.Wrapper>
        </Form>
      </FormikProvider>
      {validationError === ErrorCode.RESULTS_AUTO_CALCULATION_INVALID_STATS && (
        <Dialog
          text={t('Entered statistics is not valid')}
          onClose={() => {
            setValidationError(undefined);
          }}
          withoutActions
        >
          <S.CloseButton>
            <Button onClick={() => setValidationError(undefined)}>
              {t('close')}
            </Button>
          </S.CloseButton>
        </Dialog>
      )}
      {validationError ===
        ErrorCode.RESULTS_AUTO_CALCULATION_STATS_AND_RESULTS_MISMATCH && (
        <Dialog
          text={t('Entered statistics does not match the results')}
          onClose={() => {
            setValidationError(undefined);
          }}
          withoutActions
        >
          <S.CloseButton>
            <Button onClick={() => setValidationError(undefined)}>
              {t('close')}
            </Button>
          </S.CloseButton>
        </Dialog>
      )}
      {validationError ===
        ErrorCode.RESULTS_AUTO_CALCULATION_STATS_MISMATCH && (
        <Dialog
          text={t('Statistics does not match the system statistics')}
          onClose={() => {
            setValidationError(undefined);
          }}
          onConfirm={() => onConfirm(true)}
        />
      )}
    </Dialog>
  );
};
