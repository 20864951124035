import {
  api as betPayMax,
  betPayMaxByEventApi as betPayMaxByEvent,
} from './betPayMax';
import { api as categories } from './categories';
import { api as categoryCashoutBlocks } from './categoryCashoutBlocks';
import { api as categoryExclusions } from './categoryExclusions';
import { api as comments } from './comments';
import { api as commentsByEntity } from './commentsByEntity';
import { api as competitors } from './competitors';
import { api as countries } from './countries';
import { api as crossMappings } from './crossMappings';
import { api as currencies } from './currencies';
import { api as customers } from './customers';
import { api as customerTokens } from './customerTokens';
import { api as delays, delaysByEventApi as delaysByEvent } from './delays';
import { api as eventFavorites } from './eventFavorites';
import { api as eventLinkedIds } from './eventLinkedIds';
import { api as events } from './events';
import { api as eventsMappings } from './eventsMappings';
import {
  api as eventsOdds,
  eventsOddsByCustomerApi as eventsOddsByCustomer,
} from './eventsOdds';
import { api as eventsOddsReturns } from './eventsOddsReturns';
import { api as eventsResults } from './eventsResults';
import { api as eventStats } from './eventStats';
import { api as eventStatusByCustomer } from './eventStatusByCustomer';
import { api as eventSuspends } from './eventSuspends';
import { api as exclusions } from './exclusions';
import { api as groups } from './groups';
import { api as languages } from './languages';
import { api as logos } from './logos';
import { api as marginProgressionOutcomeCategories } from './marginProgressionOutcomeCategories';
import { api as marginProgressions } from './marginProgressions';
import { api as margins, marginsByEventApi as marginsByEvent } from './margins';
import { api as markets } from './markets';
import { api as matchPhases } from './matchPhases';
import { api as matchTypes } from './matchTypes';
import { api as maxOdds, maxOddsByEventApi as maxOddsByEvent } from './maxOdds';
import { api as oddOutcomeCalculatorRules } from './oddOutcomeCalculatorRules';
import { api as outcomeCategorySourceMappings } from './outcomeCategorySourceMappings';
import { api as players } from './players';
import { api as search } from './search';
import { api as sources } from './sources';
import { api as sports } from './sports';
import { api as tags } from './tags';
import { api as tagsByCustomer } from './tagsByCustomer';
import { api as tournaments } from './tournaments';
import { api as types } from './type';
import { api as users } from './users';

export const entitiesAPI = {
  categories,
  competitors,
  countries,
  currencies,
  customers,
  betPayMax,
  betPayMaxByEvent,
  customerTokens,
  users,
  events,
  eventsOdds,
  eventsOddsReturns,
  eventsOddsByCustomer,
  eventsMappings,
  eventsResults,
  eventStatusByCustomer,
  eventSuspends,
  eventStats,
  eventLinkedIds,
  eventFavorites,
  groups,
  margins,
  marginsByEvent,
  markets,
  matchTypes,
  sports,
  tournaments,
  types,
  players,
  sources,
  exclusions,
  categoryExclusions,
  categoryCashoutBlocks,
  languages,
  matchPhases,
  tags,
  tagsByCustomer,
  comments,
  commentsByEntity,
  delays,
  delaysByEvent,
  crossMappings,
  search,
  outcomeCategorySourceMappings,
  logos,
  maxOdds,
  oddOutcomeCalculatorRules,
  maxOddsByEvent,
  marginProgressions,
  marginProgressionOutcomeCategories,
};
