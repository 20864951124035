import { useTranslation } from 'react-i18next';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { TestIds } from 'types/testIds.types';

import { TextInput } from 'app/components/forms';

interface Props {
  searchQuery: string;
  setSearchQuery: (search: string) => void;
}

export const Search = ({ searchQuery, setSearchQuery }: Props) => {
  const { t } = useTranslation();

  const handleClear = () => {
    setSearchQuery('');
  };

  return (
    <TextInput
      onChange={e => {
        setSearchQuery(e.target.value);
      }}
      placeholder={t('search')}
      icon={
        !!searchQuery ? (
          <FontAwesomeIcon icon={faTimes} onClick={handleClear} />
        ) : (
          <FontAwesomeIcon icon={faSearch} />
        )
      }
      value={searchQuery}
      testId={TestIds.ProgressionsPageSearch}
    />
  );
};
