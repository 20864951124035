import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Column } from 'react-table';
import { useFormikContext } from 'formik';
import { Event } from 'sportsbook-openapi-react';

import { Button, Table } from 'app/components/ui';

import { getMatchtypesEntries } from 'app/providers/EntitiesProvider/matchTypes';

import { FormValues, getEventName } from './utils';

const EventsTable = () => {
  const { t } = useTranslation();

  const matchTypes = useSelector(getMatchtypesEntries);

  const {
    values: { mainEvent, events },
    setFieldValue,
  } = useFormikContext<FormValues>();

  const columns: Column<Event>[] = [
    {
      Header: t('id').toString(),
      accessor: 'id',
    },
    {
      Header: t('tournament').toString(),
      accessor: 'tournament',
      Cell: ({ value }) => value?.name,
    },
    {
      Header: t('name').toString(),
      id: 'name',
      Cell: ({ row: { original } }) => getEventName(original),
    },
    {
      Header: t('match type').toString(),
      accessor: 'matchTypeId',
      Cell: ({ value }) => (matchTypes[value] ? matchTypes[value].name : null),
    },
    {
      Header: '',
      accessor: 'id',
      id: 'actions',
      Cell: ({ value }) => (
        <Button
          color="link"
          disabled={mainEvent?.id === value}
          onClick={() => {
            setFieldValue(
              'events',
              events.filter(event => event.id !== value),
            );
          }}
        >
          {t('delete')}
        </Button>
      ),
    },
  ];

  return <Table columns={columns} data={events} />;
};

export default EventsTable;
