import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'react-table';
import { TFunction } from 'i18next';
import { sortBy, uniq } from 'lodash-es';
import {
  Event,
  mapValues,
  SportEventOdd,
  SportEventParameterType,
} from 'sportsbook-openapi-react';

import { SortableSelectTableFilter } from 'app/components/ui/Table/components';

import { actionsNT } from 'app/providers/EntitiesProvider';
import { getCompetitorsEntries } from 'app/providers/EntitiesProvider/competitors';
import {
  getPlayersEntries,
  selectPlayersItems,
} from 'app/providers/EntitiesProvider/players';

export const sportEventParameterTypesSorted = Object.values(
  SportEventParameterType,
);

export const paramsToColumns = (
  t: TFunction,
  event: Event,
  odds: SportEventOdd[],
  withSorting: boolean = true,
): Column<SportEventOdd>[] => {
  if (!odds) return [];

  const uniqParams: SportEventParameterType[] = uniq(
    odds
      .filter(odd => !!odd.parameters)
      .map(odd => odd.parameters!)
      .flat()
      .filter(param => !!param.type)
      .map(param => param.type!),
  ).sort(
    (a, b) =>
      sportEventParameterTypesSorted.indexOf(a) -
      sportEventParameterTypesSorted.indexOf(b),
  );

  const oddsParameters = odds
    .filter(odd => !!odd.parameters)
    .map(odd => odd.parameters!)
    .flat()
    .filter(param => !!param.value);

  const parameterOptions = mapValues(
    oddsParameters.reduce((acc, param) => {
      if (!acc[param.type!]) {
        acc[param.type!] = [{ id: param.value, name: param.value }];
      } else if (!acc[param.type!].some(item => item.id === param.value)) {
        acc[param.type!].push({ id: param.value, name: param.value });
      }
      return acc;
    }, {}),
    value => sortBy(value, item => Number(item.id)),
  );

  return uniqParams.reduce((acc, param) => {
    const paramColumn: Column<SportEventOdd> = {
      Header: props => {
        const players = useSelector(selectPlayersItems);
        return (
          <>
            {withSorting ? (
              <SortableSelectTableFilter
                options={
                  param === SportEventParameterType.PLAYER_ID
                    ? players.filter(player => player.id !== 0)
                    : parameterOptions[param]
                }
                {...props}
              />
            ) : (
              t(`parameters.${param}`)
            )}
          </>
        );
      },
      accessor: 'parameters',
      id: `parameters.${param}`,
      Cell: ({ value }) => {
        const dispatch = useDispatch();
        const players = useSelector(getPlayersEntries);
        const competitorsEntries = useSelector(getCompetitorsEntries);
        const paramValue = value?.find(p => p.type === param);
        const isRequested = useRef({ competitor: false, player: false });

        useEffect(() => {
          if (
            paramValue?.value &&
            param === SportEventParameterType.PLAYER_ID &&
            !players[paramValue.value] &&
            !isRequested.current.player
          ) {
            dispatch(
              actionsNT.playersFetchItem({ playerId: +paramValue.value }),
            );
            isRequested.current.player = true;
          }
        }, [dispatch, paramValue, players]);

        useEffect(() => {
          if (
            paramValue?.value &&
            param === SportEventParameterType.COMPETITOR_ID &&
            !competitorsEntries[paramValue.value] &&
            !isRequested.current.competitor
          ) {
            dispatch(
              actionsNT.competitorsFetchItem({
                competitorId: +paramValue.value,
              }),
            );
            isRequested.current.competitor = true;
          }
        }, [dispatch, paramValue, competitorsEntries]);

        if (paramValue?.value && param === SportEventParameterType.PLAYER_ID) {
          return <>{players[paramValue.value]?.name}</>;
        }
        if (
          paramValue?.value &&
          param === SportEventParameterType.COMPETITOR_ID
        ) {
          return <>{competitorsEntries[paramValue.value]?.name}</>;
        }

        return paramValue?.value ? <>{paramValue?.value}</> : null;
      },
    };
    if (param === SportEventParameterType.PLAYER_ID) {
      acc.push(
        ...[
          {
            Header: t('competitor'),
            accessor: 'parameters',
            id: 'competitor',
            Cell: ({ value }) => {
              const dispatch = useDispatch();
              const isRequested = useRef({ competitor: false, player: false });
              const paramValue = value?.find(p => p.type === param);
              const competitorsEntries = useSelector(getCompetitorsEntries);
              const players = useSelector(getPlayersEntries);

              const player = paramValue?.value
                ? players[paramValue.value]
                : undefined;

              useEffect(() => {
                if (
                  paramValue?.value &&
                  !player &&
                  !isRequested.current.player
                ) {
                  dispatch(
                    actionsNT.playersFetchItem({ playerId: +paramValue.value }),
                  );
                  isRequested.current.player = true;
                }
              }, [dispatch, paramValue, player]);

              useEffect(() => {
                const competitorsToRequest = player?.competitors.filter(
                  cId => !competitorsEntries[cId],
                );

                if (
                  player &&
                  competitorsToRequest?.length &&
                  !isRequested.current.competitor
                ) {
                  dispatch(
                    actionsNT.competitorsFetchItems({
                      ids: competitorsToRequest,
                    }),
                  );
                  isRequested.current.competitor = true;
                }
              }, [competitorsEntries, dispatch, player]);

              if (paramValue?.value) {
                return (
                  <>
                    {player?.competitors
                      .filter(id =>
                        event.competitors.find(
                          eventCompetitor => eventCompetitor.id === id,
                        ),
                      )
                      .map(cId => competitorsEntries[cId]?.name)
                      .join(', ')}
                  </>
                );
              }
            },
          } as Column<SportEventOdd>,
          paramColumn,
        ],
      );
    } else {
      acc.push(paramColumn);
    }
    return acc;
  }, [] as Column<SportEventOdd>[]);
};

export const getParameterName = (param: SportEventParameterType): string =>
  param.replace('PARAMETER_', '') ?? '';
