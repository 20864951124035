import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Formik } from 'formik';

import { useRequestState } from 'hooks/useRequestState';

import { PanelFooter } from 'app/components/panels/PanelFooter';
import { Panel } from 'app/components/ui';

import { actionsNT, Entities } from 'app/providers/EntitiesProvider';
import { getSportById } from 'app/providers/EntitiesProvider/sports';

import { createInitialValues, FormValues, validationSchema } from './constants';
import { ProgressionForm } from './ProgressionForm';
import { prepareData } from './validation';

interface Props {
  onClose: () => void;
}

type Match = {
  sportId?: string;
};

export const ProgressionCreatePanel = ({ onClose }: Props) => {
  const params = useParams<Match>();
  const { sportId } = params;

  const sport = useSelector(state => {
    if (!sportId) return undefined;
    return getSportById(state, +sportId);
  });

  const { t } = useTranslation();

  const dispatch = useDispatch();

  useRequestState(Entities.MARGIN_PROGRESSIONS, 'create', onClose);

  const onSubmit = (values: FormValues) => {
    dispatch(
      actionsNT.marginProgressionsCreate({
        marginProgressionWithOutId: prepareData(values),
      }),
    );
  };

  return (
    <Formik<FormValues>
      initialValues={{
        ...createInitialValues,
        sport,
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, dirty, isValid }) => (
        <Panel
          title={t('panels.create.progression')}
          onClose={onClose}
          onSubmit={handleSubmit}
          footer={
            <PanelFooter
              submitButtonText={t('create')}
              disabled={!dirty || !isValid}
            />
          }
        >
          <ProgressionForm actionType="create" />
        </Panel>
      )}
    </Formik>
  );
};
