import { adaptersApi } from 'app/api';

import {
  AdapterState,
  apiMap as adaptersApiMap,
} from 'app/providers/AdaptersProvider';
import { entitiesAPI } from 'app/providers/EntitiesProvider';

type Entity = keyof typeof entitiesAPI;

interface Props<T> {
  entity: Entity;
  limit?: number;
  sportId?: number;
  groupId?: number;
  categoryId?: number;
  scheduledFrom?: Date;
  scheduledTo?: Date;
  createOption?: any;
  additionalOptions?: T[];
  emptyOption?: T;
  excludeOptions?: any[];
  disabled?: boolean;
  transformOptions?: (options: any[]) => any[];
  searchField?: string;
  kind?: string;
}

export function getAsyncLoadOptions<T extends { id: string | number }>({
  entity,
  limit = 20,
  sportId,
  groupId,
  categoryId,
  scheduledFrom,
  scheduledTo,
  createOption,
  additionalOptions,
  emptyOption,
  disabled,
  excludeOptions = [],
  transformOptions = options => options,
  searchField = 'name',
  kind,
}: Props<T>) {
  if (!entitiesAPI[entity].methods['fetchItems']) {
    throw new Error('This entity has not method to fetch items');
  }

  return async (search: string, loadedOptions) => {
    const { items, hasMore } = await entitiesAPI[entity].endpoint[
      entitiesAPI[entity].methods['fetchItems']
    ]({
      offset: loadedOptions.length,
      limit,
      [searchField]:
        search.toLowerCase() === '' ? undefined : search.toLowerCase(),
      sportId,
      groupId,
      categoryId,
      scheduledFrom,
      scheduledTo,
      disabled,
      kind,
    });

    const filteredItems = transformOptions(
      items.filter(
        item =>
          !excludeOptions
            .filter(Boolean)
            .some(excludeItem => excludeItem?.id === item.id),
      ),
    );

    const options = [...filteredItems];
    if (emptyOption && search === '') {
      options.unshift(emptyOption);
    }
    if (additionalOptions) {
      options.unshift(...additionalOptions);
    }
    if (createOption) {
      options.unshift(createOption);
    }
    return {
      options,
      hasMore,
    };
  };
}

export function getAsyncUsersLoadOptions(limit: number = 20) {
  return async (search: string, _loadedOptions, additional) => {
    const { userList = [], paginationToken } =
      await entitiesAPI.users.endpoint.getUsers({
        limit,
        name: search.toLowerCase() === '' ? undefined : search.toLowerCase(),
        paginationToken: additional,
      });

    return {
      options: userList,
      hasMore: !!paginationToken,
      additional: paginationToken,
    };
  };
}

interface BaseAdapterEntity {
  id: string;
  name: string;
}
interface AdapterProps {
  entity: keyof AdapterState;
  adapter: string;
  limit?: number;
  sportId?: string;
  excludeOptions?: any[];
  transformOptions?: (options: any[]) => any[];
}

export function getAdapterAsyncLoadOptions<T extends BaseAdapterEntity>({
  entity,
  adapter,
  limit = 20,
  sportId,
  excludeOptions,
  transformOptions = options => options,
}: AdapterProps) {
  return async (search: string, loadedOptions) => {
    const promise = adaptersApi[adaptersApiMap[entity]['Fetch']]({
      offset: loadedOptions.length,
      limit,
      adapter,
      name: search.toLowerCase(),
      sportId,
    } as any) as Promise<{ items: T[]; hasMore?: boolean }>;

    const { items, hasMore } = await promise;

    return {
      options: transformOptions(
        items.filter(
          item =>
            !excludeOptions?.some(excludeItem => excludeItem.id === item.id) &&
            item.name.toLowerCase().includes(search.toLowerCase()),
        ),
      ),
      hasMore,
    };
  };
}
