import styled from 'styled-components';

import { Button } from 'app/components/ui';

export const PlainButton = styled(Button)`
  display: flex;
  justify-content: start;
  font-size: 14px;
  line-height: 22px;
  color: ${p => p.theme.colors.primary[500]};
`;
