import { EventSource } from 'sportsbook-openapi-react';

export const SOURCES_WITH_CALCULATORS = [
  EventSource.BETRADAR,
  EventSource.BETGENIUS,
  EventSource.BETCONSTRUCT,
  EventSource.LSPORTS,
  EventSource.BETBAZAR,
  EventSource.BETER,
  EventSource.BASELINE,
  EventSource.OPENBET,
  EventSource.FONBET,
  EventSource.SPORTLEVEL,
  EventSource.MAXSPORTS,
  EventSource.MANUAL,
  EventSource.SPORTSBOOK_BETRADAR_ENHANCED,
];
