import { Event } from 'sportsbook-openapi-react';

export interface AutocalculationModalProps {
  closeModal: () => void;
  event: Event;
  autocalculationType: AutocalculationTypeEnum;
}

export interface StatisticsValues {
  match: {
    score: {
      team1: number;
      team2: number;
    };
  };
  periods: {
    id: string;
    enabled?: boolean;
    score: {
      team1: number;
      team2: number;
    };
  }[];
}

export enum AutocalculationTypeEnum {
  FINAL = 'final',
  PARTIAL = 'partial',
}
