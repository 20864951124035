import {
  MarginProgression,
  MarginProgressionApiDeleteMarginProgressionRequest,
  MarginProgressionList,
} from 'sportsbook-openapi-react';

import { EntitiesErrorType } from 'app/providers/EntitiesProvider/types';
import { entityReducersGenerator } from 'app/providers/EntitiesProvider/utils';

const reducer = {
  ...entityReducersGenerator<
    MarginProgressionList,
    MarginProgression,
    MarginProgressionApiDeleteMarginProgressionRequest,
    EntitiesErrorType
  >({
    entity: 'marginProgressions',
  }),
};

export default reducer;
