import { TestIds } from 'types/testIds.types';

import { Checkbox } from 'app/components/forms';

interface Props {
  categoryId: number;
  excluded: boolean;
  updateCategoryExclusion: (categoryId: number, excluded: boolean) => void;
}

const ExclusionCell = ({
  categoryId,
  excluded,
  updateCategoryExclusion,
}: Props) => {
  const onChange = (checked: boolean) => {
    updateCategoryExclusion(categoryId, checked);
  };

  return (
    <div onClick={event => event.stopPropagation()}>
      <Checkbox
        checked={excluded}
        onChange={onChange}
        label=""
        testId={TestIds.CategoriesTableExclusionCheckbox}
      />
    </div>
  );
};

export default ExclusionCell;
