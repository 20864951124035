import ReactDatePicker from 'react-datepicker';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getTestId } from 'utils/getTestId';
import { useLocale } from 'utils/locale';

import { TextInput } from 'app/components/forms';

import { DatePickerWrapper } from './DatePicker.styles';
import { DatePickerProps } from './DatePicker.types';

export function DatePicker({
  size = 's',
  testId,
  className,
  ...props
}: DatePickerProps) {
  const locale = useLocale();
  return (
    <DatePickerWrapper className={className}>
      <ReactDatePicker
        customInput={
          <TextInput
            size={size}
            icon={<FontAwesomeIcon icon={faCalendar} />}
            testId={getTestId(testId, 'input')}
          />
        }
        locale={locale}
        {...props}
      />
    </DatePickerWrapper>
  );
}
