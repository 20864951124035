import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import { paramsToColumns } from 'app/pages/Events/utils/params';
import {
  resultToCode,
  resultToColor,
} from 'app/pages/Results/components/EventResultsTable/constants';
import * as S from 'app/pages/Results/components/EventResultsTable/EventResults.styles';
import { isEqual, round } from 'lodash-es';
import {
  Event,
  OutcomeMarketLink,
  OutcomeType,
  SportEventOdd,
  SportEventResult,
  SportEventResultType,
} from 'sportsbook-openapi-react';

import { Table } from 'app/components/ui';

interface Props {
  event: Event;
  results: SportEventOdd[];
  mappedResults: Dictionary<SportEventResult[]>;
  types: Dictionary<OutcomeType>;
}

const ResultLabel: FC<{ result: SportEventResultType }> = ({ result }) => (
  <S.StyledResultlWrapper>
    <S.StyledLabel style={{ background: resultToColor[result] }}>
      {resultToCode[result]}
    </S.StyledLabel>
    {result.replace('RESULT_', '').toUpperCase()}
  </S.StyledResultlWrapper>
);

export const PreviewResultsTable: FC<Props> = ({
  event,
  mappedResults,
  results,
  types,
}) => {
  const { t } = useTranslation();

  const filteredResults = useMemo(
    () => results.filter(r => !!types[r.outcomeTypeId]),
    [results, types],
  );

  const paramsColumns = useMemo(
    () => paramsToColumns(t, event, filteredResults, false),
    [event, filteredResults, t],
  );

  const oddsColumns: Column<SportEventOdd>[] = useMemo(
    () => [
      {
        Header: t('code').toString(),
        id: 'code',
        accessor: 'outcomeTypeId',
        Cell: ({ value }) => <>{types[value]?.code || value}</>,
      },
      {
        Header: t('outcome').toString(),
        id: 'outcome',
        accessor: 'outcomeTypeId',
        Cell: ({ value }) => <>{types[value]?.name || value}</>,
      },
      {
        Header: t('market type').toString(),
        id: 'marketLink',
        accessor: 'outcomeTypeId',
        Cell: ({ value }) => {
          if (!types[value]?.marketLink) return null;
          if (types[value].marketLink === OutcomeMarketLink.ANY) return null;
          return t(`marketLinkNames.${types[value]?.marketLink}`);
        },
      },
      ...paramsColumns,
      {
        Header: t('coefficient').toString(),
        accessor: 'value',
        Cell: ({ value }) => <>{round(value, 2)}</>,
      },
      {
        Header: t('result').toString(),
        id: 'result',
        Cell: ({ row: { original: data } }) => {
          const value =
            mappedResults[data.outcomeTypeId]?.find(sportEventResult =>
              isEqual(
                new Set(sportEventResult.params),
                new Set(data.parameters),
              ),
            )?.result ?? null;

          return value ? <ResultLabel result={value} /> : null;
        },
      },
    ],
    [mappedResults, paramsColumns, t, types],
  );

  const columnsWidth = [
    14,
    'auto',
    'auto',
    'auto',
    ...Array(paramsColumns.length).fill(14),
    'fit-content',
  ];

  return (
    <S.StyledOddsTableWrapper>
      <Table columns={oddsColumns} data={results} columnsWidth={columnsWidth} />
    </S.StyledOddsTableWrapper>
  );
};
