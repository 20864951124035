import { PropsWithChildren } from 'react';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

import { TestIds } from 'types/testIds.types';

import { SortableIconProps } from 'app/components/ui/Table/components/SortableHeader/SortableHeader.types';

import * as S from './SrtableIcon.styles';

export function SortableIcon({
  asc,
  children,
  onClick,
  columnName,
}: PropsWithChildren<SortableIconProps>) {
  const handleClick = (value: boolean) => () => {
    if (value === asc) {
      onClick(undefined);
      return;
    }

    onClick(value);
  };

  return (
    <S.SortableHeaderWrapper>
      {children}

      <S.SortingContainer>
        <S.SortingIcon
          icon={faChevronUp}
          onClick={handleClick(true)}
          $active={asc !== undefined && asc}
          data-test-id={
            !!columnName
              ? `${TestIds.TableSortingUpButton}--${columnName}`
              : undefined
          }
        />
        <S.SortingIcon
          icon={faChevronDown}
          onClick={handleClick(false)}
          $active={asc !== undefined && !asc}
          data-test-id={
            !!columnName
              ? `${TestIds.TableSortingDownButton}--${columnName}`
              : undefined
          }
        />
      </S.SortingContainer>
    </S.SortableHeaderWrapper>
  );
}
