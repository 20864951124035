import { useInfiniteQuery } from 'react-query';
import { categoriesApi } from 'app/api';
import { CategoriesApiGetCategoriesRequest } from 'sportsbook-openapi-react';

const DEFAULT_LIMIT = 100;

export const useGetCategoryList = (
  requestParameters: Omit<CategoriesApiGetCategoriesRequest, 'offset'>,
) => {
  const limit = requestParameters.limit || DEFAULT_LIMIT;
  return useInfiniteQuery({
    queryKey: ['categories', requestParameters],
    queryFn: async ({ pageParam }) =>
      categoriesApi.getCategories({
        ...requestParameters,
        limit,
        offset: pageParam,
      }),
    getNextPageParam: (lastPage, pages) =>
      lastPage.hasMore ? pages.length * limit : null,
  });
};
