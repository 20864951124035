/**
 *
 * Comments
 *
 */

import { useCallback, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'react-table';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DictionaryDropdown } from 'app/pages/Dictionary/components/DictionaryDropdown';
import { omit } from 'lodash-es';
import { Comment, UserGroupEnum } from 'sportsbook-openapi-react';

import { useAudit } from 'hooks/audit/useAudit';
import useBooleanState from 'hooks/useBooleanState';
import { useLazyLoading } from 'hooks/useLazyLoading';
import { useRequestState } from 'hooks/useRequestState';
import { useSearch } from 'hooks/useSearch';
import { TestIds } from 'types/testIds.types';
import { useTheme } from 'styles';

import { TextInput, TextInputCell } from 'app/components/forms';
import { CommentCreatePanel, CommentEditPanel } from 'app/components/panels';
import { RoleGuards } from 'app/components/RoleGuards';
import { Button, LoadingIndicator, Table } from 'app/components/ui';

import { AuditedEntities } from 'app/providers/AuditProvider';
import { actionsNT, Entities } from 'app/providers/EntitiesProvider';
import {
  getCommentsHasMore,
  selectCommentsItems,
} from 'app/providers/EntitiesProvider/comments';

import { useUserStore } from 'store';
import { getIsTranslator } from 'store/user';

import * as S from './Comments.styles';

export function Comments() {
  const { isLoading } = useRequestState(Entities.COMMENTS, 'fetchItems');
  const items = useSelector(selectCommentsItems);
  const hasMore = useSelector(getCommentsHasMore);

  const theme = useTheme();

  const dispatch = useDispatch();
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { searchQuery, setSearchQueryDebounced } = useSearch();
  const isTranslator = useUserStore(getIsTranslator);
  const [isCreateCommentVisible, showCreateComment, hideCreateComment] =
    useBooleanState();
  const [editCommentId, setEditCommentId] = useState<null | number>(null);

  const onLazyLoad = useCallback(
    ({ limit, offset }) => {
      dispatch(
        actionsNT.commentsFetchItems({
          limit,
          offset,
          withPagination: true,
          name: searchQuery.length ? searchQuery.toLowerCase() : undefined,
        }),
      );
    },
    [dispatch, searchQuery],
  );

  const onBottom = useLazyLoading({
    onLazyLoad,
    hasMore,
    extraDeps: [searchQuery],
    onPaginationReset: () => {
      dispatch(actionsNT.commentsReset());
    },
  });

  const handleUpdateComment = useCallback(
    (comment: Comment) => {
      dispatch(
        actionsNT.commentsUpdate({
          commentId: comment.id,
          commentWithoutId: {
            ...omit(comment, 'id'),
          },
        }),
      );
    },
    [dispatch],
  );

  const openAudit = useAudit({
    entity: AuditedEntities.COMMENTS,
  });

  const columns: Column<Comment>[] = useMemo(
    () => [
      {
        Header: t('name').toString(),
        accessor: 'name',
        Cell: ({ value, row: { original: data } }) => {
          return !isTranslator ? (
            <TextInputCell
              defaultValue={value}
              onSave={newValue => {
                handleUpdateComment({
                  ...data,
                  name: newValue,
                });
              }}
              validate={value => value.length > 3}
            />
          ) : (
            <>{value}</>
          );
        },
      },
      ...(!isTranslator
        ? [
            {
              Header: '',
              id: 'action',
              Cell: ({ row: { original: data } }) => (
                <S.ButtonsWrapper>
                  <Button
                    testId={`${TestIds.CommentsPageEditButton}--${data.name}`}
                    color="action"
                    onClick={() => {
                      setEditCommentId(data.id);
                    }}
                  >
                    {t('edit')}
                  </Button>
                </S.ButtonsWrapper>
              ),
            },
            {
              Header: '',
              accessor: 'id' as keyof Comment,
              id: 'audit',
              Cell: ({ row: { original: data } }) => (
                <Button
                  testId={`${TestIds.CommentsPageAuditButton}--${data.name}`}
                  color="action"
                  onClick={() => {
                    openAudit({ id: String(data.id), includeRelated: false });
                  }}
                >
                  {t('audit')}
                </Button>
              ),
            },
          ]
        : []),
    ],
    [t, isTranslator, handleUpdateComment, openAudit],
  );

  return (
    <>
      <Helmet>
        <title>{t('comments')}</title>
        <meta name="description" content="Description of Comments" />
      </Helmet>
      <S.Wrapper>
        <S.DropdownWrapper>
          <DictionaryDropdown value={t('comments')} />
        </S.DropdownWrapper>
        <S.ContentWrapper>
          <S.Header>
            <RoleGuards
              roles={[
                UserGroupEnum.ADMIN,
                UserGroupEnum.CUSTOMER,
                UserGroupEnum.OPERATOR,
                UserGroupEnum.SUPERVISOR,
              ]}
            >
              <Button
                onClick={showCreateComment}
                testId={TestIds.CommentsPageAddButton}
              >
                {t('add comment')}
              </Button>
            </RoleGuards>
            <TextInput
              testId={TestIds.CommentsPageSearch}
              placeholder={t('search')}
              onChange={e => setSearchQueryDebounced(e.target.value)}
              icon={<FontAwesomeIcon icon={faSearch} />}
            />
          </S.Header>
          {items.length === 0 && isLoading ? (
            <LoadingIndicator type="full" />
          ) : (
            <>
              <Table
                testId={TestIds.CommentsPageTable}
                testFieldName="name"
                columns={columns}
                data={items}
                onBottom={onBottom}
                columnsWidth={['auto', 'fit-content', 'fit-content']}
                rowStyles={row =>
                  row.disabled
                    ? `background-color: ${theme.colors.primary[50]} !important;
              &:hover {
                background-color: ${theme.colors.primary[50]} !important;
              }
              `
                    : ''
                }
              />
              {items.length > 0 && isLoading && (
                <LoadingIndicator type="absolute" />
              )}
            </>
          )}
        </S.ContentWrapper>
      </S.Wrapper>
      {isCreateCommentVisible && (
        <CommentCreatePanel onClose={hideCreateComment} />
      )}
      {!!editCommentId && (
        <CommentEditPanel
          onClose={() => {
            setEditCommentId(null);
          }}
          id={editCommentId}
        />
      )}
    </>
  );
}
