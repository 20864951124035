import { FC, Fragment, useCallback, useEffect, useMemo, useRef } from 'react';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEqual } from 'lodash-es';
import { SearchResult } from 'sportsbook-openapi-react';

import { FilterCacheKeys } from 'utils/filters';
import { cache, uncache } from 'utils/sessionStorage';
import { getHighlightedText } from 'utils/string';
import { useLazyLoading } from 'hooks/useLazyLoading';
import { useRequestState } from 'hooks/useRequestState';
import { useSearch } from 'hooks/useSearch';
import { TestIds } from 'types/testIds.types';

import { TextInput } from 'app/components/forms';
import { LoadingIndicator } from 'app/components/ui';

import { actionsNT } from 'app/providers/EntitiesProvider';
import { getSearchResults } from 'app/providers/EntitiesProvider/search';
import { getSportsEntries } from 'app/providers/EntitiesProvider/sports';

import * as S from './Search.styles';

interface Props {}

interface SearchState {
  searchQuery: string;
}

const MINIMAL_QUERY_LENGTH = 3;

export const Search: FC<Props> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);
  const { searchQuery, setSearchQueryDebounced } = useSearch(
    uncache<SearchState>(FilterCacheKeys.DICTIONARY_ENTITIES)?.searchQuery,
  );
  const searchResults = useSelector(getSearchResults);

  const hasMore =
    searchResults.sports.hasMore ||
    searchResults.sportGroups.hasMore ||
    searchResults.tournaments.hasMore;

  const onLazyLoad = useCallback(
    ({ limit, offset }) => {
      if (searchQuery.length >= MINIMAL_QUERY_LENGTH) {
        dispatch(
          actionsNT.searchFetchItems({
            limit,
            offset,
            name: searchQuery.toLowerCase(),
          }),
        );
      }
    },
    [dispatch, searchQuery],
  );

  const onBottom = useLazyLoading({
    onLazyLoad,
    hasMore,
    extraDeps: [searchQuery],
    onPaginationReset: () => {
      dispatch(actionsNT.searchReset());
    },
  });

  useEffect(() => {
    const rehydratedFilters = uncache<SearchState>(
      FilterCacheKeys.DICTIONARY_ENTITIES,
    );
    if (
      !isEqual(rehydratedFilters, {
        searchQuery,
      })
    ) {
      cache(FilterCacheKeys.DICTIONARY_ENTITIES, {
        searchQuery,
      });
    }
  }, [searchQuery]);

  const showSearchResults = searchQuery.length >= MINIMAL_QUERY_LENGTH;

  const handleClear = () => {
    dispatch(actionsNT.searchReset());
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    setSearchQueryDebounced('');
  };

  const isEmpty = useMemo(
    () => entityKeys.map(key => searchResults[key].items).flat().length === 0,
    [searchResults],
  );

  return (
    <>
      <TextInput
        testId={TestIds.SportPageSearch}
        ref={inputRef}
        onChange={e => {
          setSearchQueryDebounced(e.target.value);
        }}
        placeholder={t('search')}
        icon={
          !isEmpty ? (
            <FontAwesomeIcon icon={faTimes} onClick={handleClear} />
          ) : (
            <FontAwesomeIcon icon={faSearch} />
          )
        }
        defaultValue={searchQuery}
      />
      {showSearchResults && (
        <Results
          isEmpty={isEmpty}
          searchResults={searchResults}
          searchQuery={searchQuery}
          onBottom={onBottom}
        />
      )}
    </>
  );
};

const entityKeys: (keyof SearchResult)[] = [
  'sports',
  'sportGroups',
  'tournaments',
];

const entityKeyToType: Record<
  ArrayElement<typeof entityKeys>,
  'sports' | 'groups' | 'tournaments'
> = {
  sports: 'sports',
  sportGroups: 'groups',
  tournaments: 'tournaments',
};

const Results = ({
  isEmpty,
  searchResults,
  searchQuery,
  onBottom: handleBottomScroll,
}: {
  isEmpty: boolean;
  searchResults: SearchResult;
  searchQuery: string;
  onBottom: () => void;
}) => {
  const { t } = useTranslation();
  const { isLoading } = useRequestState('search', 'fetchItems');
  const entries = useSelector(getSportsEntries);

  const scrollRef = useBottomScrollListener<HTMLDivElement>(
    handleBottomScroll,
    {
      offset: 0,
      debounce: 500,
    },
  );

  return (
    <S.SearchResultWrapper ref={scrollRef}>
      {isEmpty && isLoading && <LoadingIndicator type="full" />}
      {isEmpty && !isLoading && (
        <S.EmptyMessage>{t('nothing found')}</S.EmptyMessage>
      )}
      {entityKeys
        .filter(key => !!searchResults[key].items.length)
        .map(key => {
          const formattedItems =
            key === 'tournaments'
              ? searchResults.tournaments.items.map(item => ({
                  ...item,
                  name: `(${entries[item.sportId]?.name}) ${item.name}`,
                }))
              : searchResults[key].items;

          return (
            <Fragment key={key}>
              <S.EntityKey>{t(`searchLabels.${key}`)}</S.EntityKey>
              {formattedItems.map(entity => (
                <S.StyledLink
                  key={entity.id}
                  to={`/dictionary/${entityKeyToType[key]}/${entity.id}`}
                  isDisabled={entity.disabled}
                  data-test-id={`${TestIds.SportPageNestedListSearchResult}--${entity.name}`}
                >
                  {getHighlightedText(entity.name, searchQuery)}
                </S.StyledLink>
              ))}
            </Fragment>
          );
        })}
    </S.SearchResultWrapper>
  );
};
