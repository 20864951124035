import styled from 'styled-components';

export const Wrapper = styled.form<{ withMatchType: boolean }>`
  display: grid;
  grid-template-columns: ${p =>
    p.withMatchType ? 'repeat(5, 1fr) auto auto' : 'repeat(4, 1fr) auto auto'};
  align-items: flex-end;
  grid-gap: 20px;
  padding: 20px;
  border-top: 1px solid ${p => p.theme.colors.primary[40]};
  border-bottom: 1px solid ${p => p.theme.colors.primary[40]};
`;

export const FormField = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto 32px;
  grid-gap: 10px;
  align-items: center;
`;

export const FieldLabel = styled.span`
  grid-column: 1/3;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  color: #000000;
`;

export const FieldValue = styled.span`
  font-weight: bold;
`;
