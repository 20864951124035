import { Column } from 'react-table';
import {
  MarginProgression,
  MarginProgressionOutcomeCategory,
} from 'sportsbook-openapi-react';

import { MarginProgressionOutcomeCategoryInfo } from 'app/providers/EntitiesProvider/hooks';

import MarginProgressionCell from './MarginProgressionCell';

interface Props {
  header: string;
  accessor: string;
  marginProgressions: Array<MarginProgression>;
  marginProgressionOutcomeCategories: Array<MarginProgressionOutcomeCategory>;
  updateMarginProgression: (
    newMarginProgressionId: string,
    marginProgressionOutcomeCategoryInfo: MarginProgressionOutcomeCategoryInfo,
  ) => void;
  isEditable: boolean;
}

export const MarginProgressionColumn = <T extends object>({
  header,
  accessor,
  marginProgressions,
  updateMarginProgression,
  marginProgressionOutcomeCategories,
  isEditable,
}: Props): Column<T> => {
  return {
    Header: header,
    id: 'marginProgression',
    accessor: accessor as keyof T,
    Cell: ({ value: categoryId }) => {
      const marginProgressionOutcomeCategory =
        marginProgressionOutcomeCategories.find(
          el => el.outcomeCategoryId === categoryId,
        );
      return (
        <MarginProgressionCell
          outcomeCategoryId={categoryId}
          handleCellChange={updateMarginProgression}
          marginProgressions={marginProgressions}
          marginProgressionOutcomeCategory={
            marginProgressionOutcomeCategory?.id
          }
          marginProgressionId={
            marginProgressionOutcomeCategory?.marginProgressionId
          }
          isEditable={isEditable}
        />
      );
    },
  };
};
