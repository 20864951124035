import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SOURCES_WITH_CALCULATORS } from 'app/pages/Dictionary/Calculators/constants';
import { Formik } from 'formik';
import {
  EventSource,
  OddCalculatorType,
  OddOutcomeCalculatorRule,
  OutcomeType,
} from 'sportsbook-openapi-react';

import { useRequestState } from 'hooks/useRequestState';
import { RootState } from 'types';

import { PanelFooter } from 'app/components/panels/PanelFooter';
import { Panel } from 'app/components/ui';

import { actionsNT, Entities } from 'app/providers/EntitiesProvider';
import { selectCalculatorsByOutcomeTypeId } from 'app/providers/EntitiesProvider/oddOutcomeCalculatorRules';

import { CalculatorsForm } from './CalculatorsForm';

interface Props {
  onClose: () => void;
  outcomeType: OutcomeType;
  sportId: number;
}

type Values = Partial<Record<EventSource, EnumOption<OddCalculatorType>>>;

export const CalculatorsPanel = ({ onClose, outcomeType, sportId }: Props) => {
  const calculatorsByTypes = useSelector((state: RootState) =>
    selectCalculatorsByOutcomeTypeId(state, outcomeType.id),
  );

  const { t } = useTranslation();

  const dispatch = useDispatch();

  useRequestState(Entities.ODDOUTCOMECALCULATORRULES, 'update', onClose);
  useRequestState(Entities.ODDOUTCOMECALCULATORRULES, 'create', onClose);
  useRequestState(Entities.ODDOUTCOMECALCULATORRULES, 'delete', onClose);

  const initialValues = useMemo<Values>(() => {
    const result: Values = {};
    Object.keys(calculatorsByTypes).forEach(source => {
      result[source] = {
        id: calculatorsByTypes[source].calculatorType,
        name: calculatorsByTypes[source].calculatorType.replace('TYPE_', ''),
      };
    });
    return result;
  }, [calculatorsByTypes]);

  const updateCalculator = (
    newCalculatorType: OddCalculatorType,
    calculator: OddOutcomeCalculatorRule,
  ) => {
    dispatch(
      actionsNT.oddOutcomeCalculatorRulesUpdate({
        oddOutcomeCalculatorRuleId: calculator.id,
        oddOutcomeCalculatorRuleWithOutId: {
          source: calculator.source,
          outcomeTypeId: outcomeType.id,
          sportId,
          calculatorType: newCalculatorType,
        },
      }),
    );
  };

  const createCalculator = (
    source: EventSource,
    calculatorType: OddCalculatorType,
  ) => {
    dispatch(
      actionsNT.oddOutcomeCalculatorRulesCreate({
        oddOutcomeCalculatorRuleWithOutId: {
          source,
          outcomeTypeId: outcomeType.id,
          sportId,
          calculatorType,
        },
      }),
    );
  };

  const deleteCalculator = (calculatorId: OddOutcomeCalculatorRule['id']) => {
    dispatch(
      actionsNT.oddOutcomeCalculatorRulesDelete({
        oddOutcomeCalculatorRuleId: calculatorId,
      }),
    );
  };

  const onSubmit = (values: Values) => {
    SOURCES_WITH_CALCULATORS.forEach(source => {
      const calculatorBySource = calculatorsByTypes[source];
      const calculatorValue = values[source];
      const isCreate = !calculatorBySource && !!calculatorValue;
      const isUpdate =
        !!calculatorBySource &&
        !!calculatorValue &&
        calculatorBySource.calculatorType !== calculatorValue.id;
      const isDelete = !!calculatorBySource && !calculatorValue;

      if (isCreate) {
        createCalculator(source as EventSource, calculatorValue.id);
      } else if (isUpdate) {
        updateCalculator(calculatorValue.id, calculatorBySource);
      } else if (isDelete) {
        deleteCalculator(calculatorBySource.id);
      }
    });
  };

  return (
    <>
      <Formik<Values>
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize
      >
        {({ handleSubmit, dirty }) => (
          <Panel
            panelWidth={30}
            title={outcomeType.code}
            onClose={onClose}
            onSubmit={handleSubmit}
            footer={
              <PanelFooter submitButtonText={t('edit')} disabled={!dirty} />
            }
          >
            <CalculatorsForm />
          </Panel>
        )}
      </Formik>
    </>
  );
};
