/**
 *
 * NestedList
 *
 */
import { FC, useState } from 'react';

import { Node } from './Node/Node';
import { ChildrenWrapper as List } from './Node/Node.styles';
import { NestedListProps } from './NestedList.types';
import { isNested } from './utils';

export const NestedList: FC<NestedListProps> = ({
  config,
  defaultFilled = false,
}) => {
  const [selected, setSelected] = useState('');

  return (
    <List parent nonNested={!isNested(config)} level={0}>
      {config.map(node => (
        <Node
          key={node.id}
          {...node}
          level={0}
          onSelect={node.onSelect}
          selected={selected}
          setSelected={setSelected}
          defaultFilled={defaultFilled}
        />
      ))}
    </List>
  );
};
