import { useTranslation } from 'react-i18next';
import { SOURCES_WITH_CALCULATORS } from 'app/pages/Dictionary/Calculators/constants';
import { OddCalculatorType } from 'sportsbook-openapi-react';

import { SelectField } from 'app/components/forms';
import { Span } from 'app/components/ui';
import EqualIcon from 'assets/images/EqualIcon.svg?react';

import * as S from './styles';

export const CalculatorsForm = () => {
  const { t } = useTranslation();

  return (
    <>
      <S.Header>
        <Span color="#6C7993">{t('source')}</Span>
        <div />
        <Span color="#6C7993">{t('calculator')}</Span>
      </S.Header>
      {SOURCES_WITH_CALCULATORS.map(source => (
        <CalculatorRow key={source} source={source} />
      ))}
    </>
  );
};

interface CalculatorRowProps {
  source: string;
}

const CalculatorRow = ({ source }: CalculatorRowProps) => {
  const { t } = useTranslation();

  const calculatorsOptions = Object.values(OddCalculatorType)
    .filter(calc => calc !== OddCalculatorType.UNKNOWN)
    .map(calculator => ({
      id: calculator,
      name: calculator.replace('TYPE_', ''),
    }));

  return (
    <S.Wrapper>
      <S.Source>{source.replace('SOURCE_', '')}</S.Source>
      <S.Spacer>
        <EqualIcon />
      </S.Spacer>
      <SelectField
        name={source}
        placeholder={t('not choosen')}
        options={calculatorsOptions}
        isClearable
      />
    </S.Wrapper>
  );
};
