import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import { RootState } from 'types';

import { apiMiddleware } from './apis';
import { createReducer } from './reducers';
import rootSaga from './sagas';

export function configureAppStore() {
  const sagaMiddleware = createSagaMiddleware();

  const store = configureStore({
    reducer: createReducer(),
    middleware: gDM =>
      gDM({
        serializableCheck: false,
        immutableCheck: false,
      }).concat([sagaMiddleware, apiMiddleware]),
    devTools: process.env.NODE_ENV === 'development' && {
      trace: true,
      traceLimit: 25,
    },
  });
  sagaMiddleware.run(rootSaga);
  return store;
}

export type AppStore = ReturnType<typeof configureAppStore>;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
