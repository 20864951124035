import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { DictionaryDropdown } from 'app/pages/Dictionary/components/DictionaryDropdown';
import { Search } from 'app/pages/Dictionary/Progressions/components/Search';
import { Sport } from 'sportsbook-openapi-react';

import { FETCH_ALL_LIMIT } from 'consts';
import { TestIds } from 'types/testIds.types';

import { actionsNT } from 'app/providers/EntitiesProvider';
import { selectSportsItems } from 'app/providers/EntitiesProvider/sports';

import { useAppSelector } from 'store/configureStore';

import * as S from './Panel.styles';

export const ProgressionsPanel: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const sports = useAppSelector(selectSportsItems);
  const [selected, setSelected] = useState<Sport['id']>();

  useEffect(() => {
    dispatch(actionsNT.sportsFetchItems({ limit: FETCH_ALL_LIMIT }));
  }, [dispatch]);

  const filteredSports = useMemo(
    () =>
      sports.filter(sport =>
        sport.name.toLowerCase().includes(searchQuery.toLowerCase()),
      ),
    [sports, searchQuery],
  );

  return (
    <S.Panel>
      <S.DropdownWrapper>
        <DictionaryDropdown value={t('progressions')} />
      </S.DropdownWrapper>
      <S.PanelHeading>
        <div>
          <Search searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
        </div>
      </S.PanelHeading>
      <S.PanelBlock>
        {filteredSports.map(el => (
          <S.StyledLink
            key={`${el.code}_${el.name}`}
            to={`${el.id}`}
            $isSelected={selected === el.id}
            onClick={() => {
              setSelected(el.id);
            }}
            data-test-id={`${TestIds.ProgressionsPageSportsList}--${el.name}`}
          >
            {el.name}
          </S.StyledLink>
        ))}
      </S.PanelBlock>
    </S.Panel>
  );
};
