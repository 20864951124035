/**
 *
 * Checkbox
 *
 */
import { FC, MouseEvent } from 'react';
import { faCheck, faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Text } from 'app/components/ui';

import * as S from './Checkbox.styles';
import { CheckboxProps } from './Checkbox.types';

export const Checkbox: FC<CheckboxProps> = ({
  checked,
  onChange,
  label,
  className,
  disabled,
  readOnly,
  indeterminate,
  testId,
}) => {
  const handleChange = (event: MouseEvent) => {
    event.stopPropagation();
    if (!readOnly && !disabled) {
      onChange(!checked);
    }
  };

  return (
    <S.ChechboxWrapper className={className} onClick={handleChange}>
      <S.StyledCheckbox
        checked={checked || indeterminate}
        data-test-id={testId}
      >
        {checked && (
          <FontAwesomeIcon icon={faCheck} color="white" size={'lg'} />
        )}
        {!checked && indeterminate && (
          <FontAwesomeIcon icon={faMinus} color="white" size={'lg'} />
        )}
      </S.StyledCheckbox>
      {typeof label === 'string' ? (
        <Text>{label}</Text>
      ) : (
        <div onClick={event => event.stopPropagation()}>{label}</div>
      )}
    </S.ChechboxWrapper>
  );
};
