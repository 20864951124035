import { useContext } from 'react';
import {
  components,
  DropdownIndicatorProps,
  GroupBase,
  InputProps,
  SingleValueProps,
  Theme,
} from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';
import { ThemeContext } from 'styled-components';

import { getTestId } from 'utils/getTestId';
import { TestIds } from 'types/testIds.types';

import {
  getStyles,
  SelectWrapper,
} from 'app/components/forms/Select/Select.styles';
import { BaseOptionType } from 'app/components/forms/Select/Select.types';
import { Button } from 'app/components/ui';

import { AsyncSelectProps, Size } from './AsyncSelect.types';

const sizeToHeight: Record<Size, number> = {
  s: 32,
  m: 40,
};

const CustomOption = props => {
  const { data } = props;
  return data.isButton ? (
    <components.Option
      {...props}
      innerProps={{
        ...props.innerProps,
        onClick: data.onClick,
        'data-test-id': `${TestIds.SelectOption}--${data.label}`,
      }}
    >
      <Button
        color="link"
        type="button"
        onClick={() => {
          data.onClick();
        }}
      >
        {data.label}
      </Button>
    </components.Option>
  ) : (
    <components.Option
      {...props}
      innerProps={{
        ...props.innerProps,
        'data-test-id': `${TestIds.SelectOption}--${data.name}`,
      }}
    />
  );
};

const SingleValueRenderer = <OptionType extends BaseOptionType>({
  children,
  ...props
}: SingleValueProps<OptionType>) => {
  return (
    <components.SingleValue {...props}>
      <span
        style={{
          width: 'fit-content',
        }}
        onMouseDown={event => {
          event.stopPropagation();
        }}
        data-test-id={getTestId(
          (props.selectProps as AsyncSelectProps<OptionType>).testId,
          'value',
        )}
      >
        {children}
      </span>
    </components.SingleValue>
  );
};

const CustomInput = <OptionType extends BaseOptionType>({
  children,
  ...props
}: InputProps<OptionType>) => {
  return (
    <components.Input
      {...props}
      data-test-id={getTestId(
        (props.selectProps as AsyncSelectProps<OptionType>).testId,
        'input',
      )}
    />
  );
};

const DropdownIndicator = <OptionType extends BaseOptionType>({
  children,
  ...props
}: DropdownIndicatorProps<OptionType>) => {
  return (
    <components.DropdownIndicator
      {...props}
      innerProps={{
        ...props.innerProps,
        // @ts-ignore
        'data-test-id': getTestId(
          (props.selectProps as AsyncSelectProps<OptionType>).testId,
          'dropdown-button',
        ),
      }}
    />
  );
};

export const AsyncSelect = <OptionsType extends BaseOptionType>({
  invalid,
  success,
  disabled,
  readOnly,
  size = 's',
  testId,
  className,
  getOptionLabel = option => option.name,
  getOptionValue = option => String(option.id),
  ...props
}: AsyncSelectProps<OptionsType>) => {
  const theme = useContext(ThemeContext);

  return (
    <SelectWrapper data-test-id={testId} className={className}>
      <AsyncPaginate<OptionsType, GroupBase<OptionsType>, unknown, boolean>
        {...props}
        // @ts-ignore
        testId={testId}
        value={props.value ?? null}
        styles={getStyles({ invalid, success, disabled, readOnly }, theme)}
        isDisabled={disabled}
        isSearchable={!readOnly}
        menuIsOpen={readOnly ? false : undefined}
        components={{
          IndicatorSeparator: null,
          SingleValue: SingleValueRenderer,
          Option: CustomOption,
          Input: CustomInput,
          DropdownIndicator,
        }}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        maxMenuHeight={150}
        theme={(selectTheme: Theme) => {
          return {
            ...selectTheme,
            colors: {
              ...selectTheme.colors,
              primary: theme.colors.primary[40],
              primary25: theme.colors.primary[20],
              primary50: theme.colors.primary[40],
              neutral5: theme.colors.primary[20],
              neutral0: theme.colors.primary[0],
            },
            borderRadius: 4,
            spacing: {
              ...selectTheme.spacing,
              controlHeight: sizeToHeight[size],
            },
          };
        }}
      />
    </SelectWrapper>
  );
};
