import { isNaN, isNumber } from 'lodash-es';
import { MarginProgressionWithOutId } from 'sportsbook-openapi-react';

import { FormValues } from './constants';

export const prepareData = (
  formValues: FormValues,
): MarginProgressionWithOutId => {
  const steps: number[] = [];
  formValues.steps.forEach(step => {
    if (typeof step === 'number') {
      steps.push(step);
    } else if (typeof step === 'string' && step !== '') {
      if (isNumber(+step) && !isNaN(+step)) {
        steps.push(step);
      }
    }
  });
  return {
    name: formValues.name,
    disabled: !formValues.enabled,
    sportId: formValues.sport?.id,
    steps,
  };
};
