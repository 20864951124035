import { Sport } from 'sportsbook-openapi-react';
import * as Yup from 'yup';

export const MIN_STEP_VALUE = 0.0001;
export const MAX_STEP_VALUE = 0.04;
export const STEP = 0.0001;

const StepNumberValidation = Yup.number()
  .min(MIN_STEP_VALUE)
  .max(MAX_STEP_VALUE);

export const validationSchema = Yup.object({
  name: Yup.string().required('Required'),
  sport: Yup.object().required('Required'),
  enabled: Yup.boolean().required('Required'),
  steps: Yup.array()
    .of(StepNumberValidation)
    .required('Required')
    .test(
      'previous-filled',
      'previous fields must be filled',
      function (value) {
        const { path, createError } = this;
        for (let i = 1; i < value.length; i++) {
          if (!value[i - 1] && !!value[i]) {
            return createError({
              path,
              message: 'previous fields must be filled',
            });
          }
        }
        return true;
      },
    )
    .of(Yup.number().min(MIN_STEP_VALUE).max(MAX_STEP_VALUE))
    .min(1),
});

export interface FormValues {
  name: string;
  steps: number[];
  enabled: boolean;
  sport?: Sport;
}

export const createInitialValues: FormValues = {
  name: '',
  sport: undefined,
  enabled: true,
  steps: [],
};
