import {
  reducer as translationReducer,
  sliceKey as translationSliceKey,
} from 'app/pages/Translations/slice';

import {
  reducer as adaptersReducer,
  sliceKey as adaptersSliceKey,
} from 'app/providers/AdaptersProvider';
import {
  reducer as auditReducer,
  sliceKey as auditSliceKey,
} from 'app/providers/AuditProvider';
import {
  reducer as entitiesReducer,
  sliceKey as entitiesSliceKey,
} from 'app/providers/EntitiesProvider';

import { apiReducer, reducerPath } from './apis';

export function createReducer() {
  return {
    [adaptersSliceKey]: adaptersReducer,
    [entitiesSliceKey]: entitiesReducer,
    [auditSliceKey]: auditReducer,
    [translationSliceKey]: translationReducer,
    [reducerPath]: apiReducer,
  };
}
