import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import values from 'lodash-es/values';
import { Customer, UserWithOutIdGroupEnum } from 'sportsbook-openapi-react';

import { TestIds } from 'types/testIds.types';

import { SelectField, TextInputField } from 'app/components/forms';
import { Span } from 'app/components/ui';

import { Entity } from 'app/providers/EntitiesProvider';

import { FormValues, groupOptions } from './constants';
import * as S from './styles';

interface Props {
  customers: Entity<Customer>;
  actionType: 'create' | 'edit';
}

export const UserForm = ({ customers, actionType }: Props) => {
  const { t } = useTranslation();

  const {
    values: { group },
  } = useFormikContext<FormValues>();

  return (
    <>
      {actionType === 'create' && (
        <S.FormBlock>
          <Span>{t('name')}</Span>
          <TextInputField name="name" testId={TestIds.UsersPanelName} />
        </S.FormBlock>
      )}
      <S.FormBlock>
        <Span>{t('email')}</Span>
        <TextInputField name="email" testId={TestIds.UsersPanelEmail} />
      </S.FormBlock>
      <S.FormBlock>
        <Span>{t('access')}</Span>
        <SelectField
          name="group"
          options={groupOptions}
          testId={TestIds.UsersPanelAccessSelect}
        />
      </S.FormBlock>
      {group?.id === UserWithOutIdGroupEnum.CUSTOMER && (
        <S.FormBlock>
          <Span>{t('customer')}</Span>
          <SelectField
            name="customer"
            options={values(customers.entries)}
            testId={TestIds.UsersPanelCustomerSelect}
          />
        </S.FormBlock>
      )}
    </>
  );
};
