import {
  Country,
  Group,
  Sport,
  Tournament,
  TournamentKind,
} from 'sportsbook-openapi-react';
import * as Yup from 'yup';

export const validationSchema = Yup.object({
  name: Yup.string().required('Required'),
  sport: Yup.object().required('Required'),
  country: Yup.object().required('Required'),
  group: Yup.object().required('Required'),
  kind: Yup.object(),
  baseTournament: Yup.object().when('kind', {
    is: (value: EnumOption<TournamentKind>) =>
      value?.id === TournamentKind.GENERAL,
    then: schema => schema.notRequired(),
    otherwise: schema => schema.required('Required'),
  }),
});

export interface FormValues {
  name: string;
  sport?: Sport;
  group?: Group;
  country?: Country;
  kind?: EnumOption<TournamentKind>;
  baseTournament?: Tournament;
}
