import { FC, useCallback, useEffect, useState } from 'react';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import isFunction from 'lodash/isFunction';

import { TestIds } from 'types/testIds.types';

import Chevron from 'app/components/forms/NestedList/assets/images/chevron.svg?react';
import LargePlus from 'app/components/forms/NestedList/assets/images/large-plus.svg?react';
import { NodeInterface } from 'app/components/forms/NestedList/NestedList.types';
import { isNested } from 'app/components/forms/NestedList/utils';

import * as S from './Node.styles';

export const Node: FC<NodeInterface> = ({
  label,
  buttonAction,
  children,
  onSelect,
  onOpen,
  onMount,
  selected,
  setSelected,
  onBottom,
  id,
  style,
  defaultFilled = false,
  level = 0,
  extraDeps = [],
  testId,
}) => {
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    if (isFunction(onMount)) {
      onMount(opened);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...extraDeps]);

  const handleOpenClick = event => {
    event.stopPropagation();
    event.preventDefault();
    if (!opened && isFunction(onOpen)) onOpen();
    setOpened(prevState => !prevState);
  };

  const handleBottomScroll = useCallback(() => {
    if (isFunction(onBottom)) {
      onBottom();
    }
  }, [onBottom]);

  const scrollRef = useBottomScrollListener(handleBottomScroll, {
    offset: 0,
    debounce: 0,
  });

  return (
    <S.Wrapper>
      <S.Item
        selected={selected === `${id}${label.toString()}`}
        withoutIcon={!children?.length && !defaultFilled}
        onDoubleClick={handleOpenClick}
        onClick={() => {
          setSelected(`${id}${label.toString()}`);
          onSelect && onSelect();
        }}
        style={style}
        data-test-id={testId}
      >
        {(!!children?.length || defaultFilled) && (
          <S.ChevronWrapper opened={opened}>
            <Chevron onClick={handleOpenClick} />
          </S.ChevronWrapper>
        )}
        <S.StyledLabel>{label}</S.StyledLabel>
        {buttonAction && (
          <S.StyledButton
            onClick={buttonAction}
            testId={`${TestIds.SportPageNestedListAddButton}--${testId}`}
          >
            <LargePlus />
          </S.StyledButton>
        )}
      </S.Item>
      {opened && !!children?.length && (
        <S.ChildrenWrapper
          nonNested={!isNested(children)}
          level={level + 1}
          ref={scrollRef as React.RefObject<HTMLDivElement>}
          data-test-id={TestIds.SportPageNestedListChildrenWrapper}
        >
          {children.map(node => (
            <Node
              key={node.id}
              {...node}
              level={level + 1}
              onSelect={node.onSelect}
              selected={selected}
              setSelected={setSelected}
            />
          ))}
        </S.ChildrenWrapper>
      )}
    </S.Wrapper>
  );
};
