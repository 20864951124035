import styled from 'styled-components';

import { SelectField, TextInputField } from 'app/components/forms';

export const StyledFormContent = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-row-gap: 10px;
`;

export const StyledFilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
`;

export const StyledFilterBlock = styled.div`
  display: flex;
  gap: 15px;
`;

export const TableWrapper = styled.div`
  flex-grow: 1;
`;

export const StyledSelectField = styled(SelectField)`
  max-width: 200px;
`;

export const StyledInputField = styled(TextInputField)`
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
