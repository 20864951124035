import { marginProgressionApi } from 'app/api';

const api = {
  endpoint: marginProgressionApi,
  methods: {
    fetchItems: 'getMarginProgressions',
    fetchItem: 'getMarginProgression',
    create: 'createMarginProgression',
    update: 'updateMarginProgression',
    delete: 'deleteMarginProgression',
  },
};

export default api;
