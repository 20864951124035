import { FC } from 'react';
import { useField } from 'formik';

import { AsyncSelect } from 'app/components/forms';

import { AsyncSelectFieldProps } from './AsyncSelectField.types';

export const AsyncSelectField: FC<AsyncSelectFieldProps> = ({
  name,
  readOnly,
  disabled,
  testId,
  ...props
}) => {
  const [field, meta, helpers] = useField(name);

  return (
    <AsyncSelect
      {...field}
      {...props}
      testId={testId}
      value={field.value ?? null}
      invalid={!!meta.error}
      readOnly={readOnly}
      disabled={disabled}
      onChange={value => helpers.setValue(value)}
    />
  );
};
