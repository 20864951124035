import { OddOutcomeCalculatorRule } from 'sportsbook-openapi-react';

import { EntitiesErrorType } from 'app/providers/EntitiesProvider/types';
import { entityReducersGenerator } from 'app/providers/EntitiesProvider/utils';

const reducer = entityReducersGenerator<
  OddOutcomeCalculatorRule[],
  OddOutcomeCalculatorRule,
  void,
  EntitiesErrorType
>({
  entity: 'oddOutcomeCalculatorRules',
});

export default reducer;
