import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useFormikContext } from 'formik';
import { groupBy, keyBy, mapValues } from 'lodash-es';
import {
  Country,
  Group,
  LogoEntityType,
  Sport,
  Tournament,
  TournamentKind,
} from 'sportsbook-openapi-react';

import { getAsyncLoadOptions } from 'utils/select';
import { FETCH_ALL_LIMIT } from 'consts';
import { TestIds } from 'types/testIds.types';

import {
  AsyncSelectField,
  SelectField,
  TextInputField,
} from 'app/components/forms';
import { LogoUploader } from 'app/components/LogoUploader';
import { Span } from 'app/components/ui';

import { actionsNT } from 'app/providers/EntitiesProvider';

import { FormValues } from './constants';
import * as S from './styles';

interface Props {
  countries: Dictionary<Country>;
  sports: Dictionary<Sport>;
  groups: Dictionary<Group>;
  actionType: 'create' | 'edit';
  tournament?: Tournament;
  sportId?: number;
  groupId?: number;
}

const kindOptions = Object.values(TournamentKind).map(kind => ({
  name: kind,
  id: kind,
}));

export const TournamentForm = ({
  countries,
  sports,
  groups,
  tournament,
  sportId,
  groupId,
  actionType,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actionsNT.groupsFetchItems({ limit: FETCH_ALL_LIMIT }));
    dispatch(actionsNT.countriesFetchItems({ limit: FETCH_ALL_LIMIT }));
  }, [dispatch]);

  const {
    values: { sport },
  } = useFormikContext<FormValues>();

  const groupsBySport = useMemo(
    () =>
      mapValues(
        groupBy(groups, item => String(item.sportId)),
        value => keyBy(value, valueItem => String(valueItem.id)),
      ),
    [groups],
  );

  return (
    <>
      <S.FormBlock>
        <Span>{t('name')}</Span>
        <TextInputField name="name" testId={TestIds.TournamentPanelName} />
      </S.FormBlock>
      <S.FormBlock>
        <Span>{t('sport type')}</Span>
        <SelectField
          testId={TestIds.TournamentPanelSportSelect}
          name="sport"
          options={Object.values(sports)}
          readOnly={!!(sportId && actionType === 'create')}
        />
      </S.FormBlock>
      <S.FormBlock>
        <Span>{t('group')}</Span>
        <SelectField
          testId={TestIds.TournamentPanelGroupSelect}
          name="group"
          options={
            !!sport && !!groupsBySport[sport.id]
              ? Object.values(groupsBySport[sport.id])
              : []
          }
          readOnly={!!(groupId && actionType === 'create') || !sport}
        />
      </S.FormBlock>
      <S.FormBlock>
        <Span>{t('country')}</Span>
        <SelectField
          name="country"
          options={Object.values(countries)}
          testId={TestIds.TournamentPanelCountrySelect}
        />
      </S.FormBlock>
      <S.FormBlock>
        <Span>{t('tournament kind')}</Span>
        <SelectField
          name="kind"
          options={kindOptions}
          size="m"
          testId={TestIds.TournamentPanelKindSelect}
        />
      </S.FormBlock>
      <S.FormBlock>
        <Span>{t('base tournament')}</Span>
        <AsyncSelectField
          testId={TestIds.TournamentPanelMainTournamentSelect}
          name="baseTournament"
          loadOptions={getAsyncLoadOptions({
            entity: 'tournaments',
            excludeOptions: [tournament?.id],
          })}
          isClearable
        />
      </S.FormBlock>
      {actionType === 'edit' && tournament && (
        <LogoUploader
          entityType={LogoEntityType.TOURNAMENT}
          entityId={tournament?.id}
        />
      )}
    </>
  );
};
