import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EntityType } from 'sportsbook-openapi-react';

import { useRequestState } from 'hooks/useRequestState';
import { TestIds } from 'types/testIds.types';

import { LoadingIndicator, Popup } from 'app/components/ui';

import { actionsNT } from 'app/providers/EntitiesProvider';

import * as S from './ExclusionPopup.styles';

interface Props {
  customerId: number;
  type: EntityType;
  id: number;
  excluded: boolean;
}

export const ExclusionPopup: FC<Props> = ({
  customerId,
  type,
  id,
  excluded,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { isLoading } = useRequestState('exclusions', 'update');
  const { isLoading: fetchItemIsLoading } = useRequestState(
    'exclusions',
    'fetchItems',
  );

  const handleChangeClick = () => {
    dispatch(
      actionsNT.exclusionsUpdate({
        customerId,
        entityId: id,
        entityType: type,
        exclusionStatus: {
          excluded: !excluded,
        },
      }),
    );
  };

  return (
    <Popup
      placement="bottom-end"
      button={
        <S.StyledButton
          isExcluded={excluded}
          disabled={isLoading || fetchItemIsLoading}
          testId={TestIds.SportPageIncludedButton}
        >
          {fetchItemIsLoading ? (
            <LoadingIndicator type="full" small />
          ) : (
            <>
              {t(excluded ? 'excluded' : 'included')}{' '}
              <FontAwesomeIcon icon={faAngleDown} color="000000" />
            </>
          )}
        </S.StyledButton>
      }
      body={
        <S.Column>
          <S.Span
            onClick={handleChangeClick}
            data-test-id={TestIds.SportPageExcludedButton}
          >
            {t(excluded ? 'include' : 'exclude')}
          </S.Span>
        </S.Column>
      }
    />
  );
};
