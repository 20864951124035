import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';

import { useRequestState } from 'hooks/useRequestState';
import { FETCH_ALL_LIMIT, RU_LANG_CODE } from 'consts';

import { PanelFooter } from 'app/components/panels/PanelFooter';
import { Panel } from 'app/components/ui';

import { actionsNT, Entities } from 'app/providers/EntitiesProvider';
import { getCategoriesEntries } from 'app/providers/EntitiesProvider/categories';

import { FormValues, validationSchema } from './constants';
import { OutcomeForm } from './OutcomeForm';

interface Props {
  categoryId?: number;
  onClose: () => void;
  onSuccess?: (result) => void;
}

export const OutcomeCreatePanel = ({
  onClose,
  onSuccess,
  categoryId,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actionsNT.marketsFetchItems({ limit: FETCH_ALL_LIMIT }));
    dispatch(actionsNT.categoriesFetchItems({ limit: FETCH_ALL_LIMIT }));
  }, [dispatch]);

  const handleOutcomeCreated = result => {
    if (onSuccess) onSuccess(result);
    onClose();
  };

  useRequestState(Entities.TYPES, 'create', handleOutcomeCreated);

  const categories = useSelector(getCategoriesEntries);

  const onSubmit = (values: FormValues) => {
    dispatch(
      actionsNT.typesCreate({
        outcomeTypeWithOutId: {
          name: values.name,
          marketId: values.market?.id!,
          categoryId: values.category?.id!,
          code: values.code,
          priorityOrder: values.priorityOrder,
          marketLink: values.marketLink?.id!,
          shortNames: [
            {
              langCode: RU_LANG_CODE,
              value: values.shortName,
            },
          ],
          translations: [],
        },
      }),
    );
  };

  return (
    <Formik<FormValues>
      initialValues={{
        name: '',
        market: undefined,
        category: categoryId ? categories[categoryId] : undefined,
        code: '',
        shortName: '',
        marketLink: undefined,
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {formikProps => (
        <Panel
          title={t('panels.create.outcomeType')}
          onClose={onClose}
          onSubmit={formikProps.handleSubmit}
          footer={
            <PanelFooter
              submitButtonText={t('create')}
              disabled={!formikProps.dirty || !formikProps.isValid}
            />
          }
        >
          <OutcomeForm {...formikProps} categories={categories} />
        </Panel>
      )}
    </Formik>
  );
};
