import { Entities, EntitiesState } from './types';

export const ENTITIES_PROVIDER_REDUCER_KEY = 'entities';

const requestInitialState = {
  loading: false,
  error: null,
  result: null,
};

const createRequestInitialState = {
  ...requestInitialState,
  id: null,
  result: null,
};

const createListRequestInitialState = {
  ...requestInitialState,
  ids: null,
  result: null,
};

const updateRequestInitialState = {
  ...requestInitialState,
  id: null,
  result: null,
};

export const entityInitialState = {
  entries: {},
  sortedIds: [],
  fetchItems: requestInitialState,
  fetchItem: requestInitialState,
  create: createRequestInitialState,
  createList: createListRequestInitialState,
  update: updateRequestInitialState,
  delete: requestInitialState,
  deleteList: requestInitialState,
  withPagination: false,
  hasMore: true,
};

export const searchInitialState = {
  fetchItems: requestInitialState,
  sports: {
    hasMore: true,
    items: [],
  },
  sportGroups: {
    hasMore: true,
    items: [],
  },
  tournaments: {
    hasMore: true,
    items: [],
  },
};

export const initialState: EntitiesState = {
  categories: { ...entityInitialState },
  competitors: { ...entityInitialState },
  countries: { ...entityInitialState },
  currencies: { ...entityInitialState },
  customers: { ...entityInitialState },
  betPayMax: { ...entityInitialState },
  customerTokens: { ...entityInitialState },
  users: { ...entityInitialState, paginationToken: '' },
  events: { ...entityInitialState },
  eventsOdds: { ...entityInitialState },
  eventsMappings: { ...entityInitialState },
  eventsResults: { ...entityInitialState },
  eventSuspends: { ...entityInitialState },
  eventStats: { ...entityInitialState },
  eventFavorites: { ...entityInitialState },
  groups: { ...entityInitialState },
  margins: { ...entityInitialState },
  matchTypes: { ...entityInitialState },
  markets: { ...entityInitialState },
  sports: { ...entityInitialState },
  tournaments: { ...entityInitialState },
  types: { ...entityInitialState, byCategory: {} },
  players: { ...entityInitialState },
  sources: { ...entityInitialState },
  exclusions: { ...entityInitialState },
  categoryExclusions: { ...entityInitialState },
  categoryCashoutBlocks: { ...entityInitialState },
  languages: { ...entityInitialState },
  matchPhases: { ...entityInitialState },
  tags: { ...entityInitialState },
  tagsByCustomer: { ...entityInitialState },
  eventLinkedIds: { ...entityInitialState },
  delays: { ...entityInitialState },
  eventsOddsReturns: { ...entityInitialState },
  crossMappings: { ...entityInitialState },
  search: searchInitialState,
  comments: { ...entityInitialState },
  commentsByEntity: { ...entityInitialState, sortedIds: {} },
  outcomeCategorySourceMappings: { ...entityInitialState },
  logos: { ...entityInitialState },
  maxOdds: { ...entityInitialState },
  oddOutcomeCalculatorRules: { ...entityInitialState },
  marginProgressions: { ...entityInitialState },
  marginProgressionOutcomeCategories: { ...entityInitialState },
};

export const idFieldByEntity = {
  [Entities.CATEGORIES]: 'categoryId',
  [Entities.COMPETITORS]: 'competitorId',
  [Entities.COUNTRIES]: 'countryId',
  [Entities.CURRENCIES]: 'currencyId',
  [Entities.CUSTOMERS]: 'customerId',
  [Entities.USERS]: 'userId',
  [Entities.EVENTS]: 'eventId',
  [Entities.GROUPS]: 'groupId',
  [Entities.MARGINS]: 'marginId',
  [Entities.MARKETS]: 'marketId',
  [Entities.SPORTS]: 'sportId',
  [Entities.TOURNAMENTS]: 'tournamentId',
  [Entities.TYPES]: 'typeId',
  [Entities.PLAYERS]: 'playerId',
  [Entities.LANGUAGES]: 'languageId',
  [Entities.MATCH_TYPES]: 'matchTypeId',
  [Entities.MATCH_PHASES]: 'matchphaseId',
  [Entities.TAGS]: 'tagId',
  [Entities.DELAYS]: 'delayId',
  [Entities.COMMENTS]: 'commentId',
  [Entities.OUTCOMECATEGORYSOURCEMAPPINGS]: 'id',
  [Entities.LOGOS]: 'logoId',
  [Entities.MAXODDS]: 'maxOddId',
  [Entities.ODDOUTCOMECALCULATORRULES]: 'oddOutcomeCalculatorRuleId',
  [Entities.MARGIN_PROGRESSIONS]: 'marginProgressionsId',
  [Entities.MARGIN_PROGRESSION_OUTCOME_CATEGORIES]:
    'categoryMarginProgressionId',
};
