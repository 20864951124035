import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';

import { getEntities, getEntityItems } from 'app/providers/EntitiesProvider';

const getMatchPhasesSubstate = (state: RootState) =>
  getEntities(state).matchPhases;

export const getMatchPhasesEntries = (state: RootState) =>
  getMatchPhasesSubstate(state).entries;

export const selectMatchPhasesItems = createSelector(
  [getMatchPhasesSubstate],
  matchPhases => getEntityItems(matchPhases),
);

export const selectMatchPhaseByCode = createSelector(
  [selectMatchPhasesItems, (_state, code) => code],
  (items, code) => items.find(item => item.phaseCode === code),
);
