import { useState } from 'react';
import { useQuery } from 'react-query';
import { currentUserApi, customersApi } from 'app/api';
import { UserGroupEnum } from 'sportsbook-openapi-react';

import { API_URL } from 'consts';

import { useUserStore } from 'store';

const useWhoIam = () => {
  const { setUser, setCustomer } = useUserStore();
  const [unauthorized, setUnauthorized] = useState(false);

  const query = useQuery(
    'whoIam',
    async () => {
      const user = await currentUserApi.getWhoAmI();
      setUser(user);

      if (user.group === UserGroupEnum.CUSTOMER && user.customerId) {
        const customer = await customersApi.getCustomer({
          customerId: user.customerId,
        });
        setCustomer(customer);
        setUnauthorized(false);
      }
      return user;
    },
    {
      onError: error => {
        // @ts-ignore
        const response = error.response as Response;
        setUnauthorized(false);
        if (
          response?.status === 401 &&
          process.env.NODE_ENV !== 'development'
        ) {
          setUnauthorized(true);
          window.location.href = `${API_URL}/ui`;
        }
      },
    },
  );

  return { ...query, unauthorized };
};

export default useWhoIam;
