import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MarginProgression } from 'sportsbook-openapi-react';

import { TestIds } from 'types/testIds.types';

import { SelectCell } from 'app/components/forms';

import { MarginProgressionOutcomeCategoryInfo } from 'app/providers/EntitiesProvider/hooks';

interface Props {
  outcomeCategoryId: number;
  handleCellChange: (
    newMarginProgressionId: string,
    marginProgressionOutcomeCategoryInfo: MarginProgressionOutcomeCategoryInfo,
  ) => void;
  marginProgressions: Array<MarginProgression>;
  marginProgressionId?: number;
  marginProgressionOutcomeCategory?: number;
  isEditable: boolean;
}

const MarginProgressionCell = ({
  outcomeCategoryId,
  handleCellChange,
  marginProgressionId,
  marginProgressionOutcomeCategory,
  marginProgressions,
  isEditable,
}: Props) => {
  const { t } = useTranslation();

  const marginProgression = useMemo(
    () =>
      marginProgressionId
        ? marginProgressions.find(el => el.id === marginProgressionId)
        : undefined,
    [marginProgressionId, marginProgressions],
  );
  const options = useMemo(
    () => [
      ...(marginProgression ? [{ id: 'delete', name: t('delete') }] : []),
      ...marginProgressions.map(el => ({
        name: el.name,
        id: `${el.id}`,
      })),
    ],
    [marginProgression, t, marginProgressions],
  );

  if (!isEditable) {
    return <div>{marginProgression?.name}</div>;
  }

  const onChange = value => {
    handleCellChange(value, {
      outcomeCategoryId,
      marginProgressionId,
      marginProgressionOutcomeCategory,
    });
  };

  return (
    <SelectCell
      testId={TestIds.CategoriesTableProgressionSelect}
      onChange={onChange}
      options={options}
      value={marginProgressionId ? `${marginProgressionId}` : undefined}
    />
  );
};

export default MarginProgressionCell;
