import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Panel = styled.div`
  display: grid;
  grid-template-rows: repeat(2, 56px) minmax(0, 1fr);

  border-bottom: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
`;

export const DropdownWrapper = styled.div`
  padding: 0 20px;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid #e5e5e5;
`;

export const PanelHeading = styled.div`
  padding: 0 20px;
  display: grid;
  align-items: center;
`;

export const PanelBlock = styled.div`
  padding: 0 20px;
  overflow-y: auto;
`;

type LinkProps = {
  $isSelected: boolean;
};

export const StyledLink = styled(Link)<LinkProps>`
  cursor: pointer;
  display: block;
  font-size: 14px;
  line-height: 40px;
  color: ${p => p.theme.colors.primary[900]};
  text-decoration: none;
  padding-left: 8px;
  border-radius: 4px;
  &:visited {
    color: ${p => p.theme.colors.primary[900]};
    text-decoration: none;
  }
  &:hover {
    background-color: ${p => p.theme.colors.primary[20]};
  }
  ${props =>
    props.$isSelected &&
    `
      background-color: #e6e6e6;
      &:hover {
        background-color: #e6e6e6;
      }
    `}
`;
