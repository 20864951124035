import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { MarginProgression } from 'sportsbook-openapi-react';

import useBooleanState from 'hooks/useBooleanState';
import { useRequestState } from 'hooks/useRequestState';

import { PanelFooter } from 'app/components/panels/PanelFooter';
import { Dialog, Panel } from 'app/components/ui';

import { actionsNT, Entities } from 'app/providers/EntitiesProvider';
import { getMarginProgressionById } from 'app/providers/EntitiesProvider/marginProgressions/selectors';
import { getSportById } from 'app/providers/EntitiesProvider/sports';

import useUserStore, { getIsAdmin } from 'store/user';

import { FormValues, validationSchema } from './constants';
import { ProgressionForm } from './ProgressionForm';
import { prepareData } from './validation';

interface Props {
  onClose: () => void;
  id: number;
}

type Match = {
  sportId?: string;
};

export const ProgressionEditPanel = ({ onClose, id }: Props) => {
  const params = useParams<Match>();
  const { sportId } = params;
  const isAdmin = useUserStore(getIsAdmin);

  const sport = useSelector(state => {
    if (!sportId) return undefined;
    return getSportById(state, +sportId);
  });

  const marginProgression: MarginProgression = useSelector(
    state => getMarginProgressionById(state, id)!,
  );

  const [deleteModalVisible, showDeleteModal, closeDeleteModal] =
    useBooleanState();

  const { t } = useTranslation();

  const dispatch = useDispatch();

  useRequestState(Entities.MARGIN_PROGRESSIONS, 'update', onClose);
  useRequestState(Entities.MARGIN_PROGRESSIONS, 'delete', onClose);

  const onSubmit = (values: FormValues) => {
    dispatch(
      actionsNT.marginProgressionsUpdate({
        marginProgressionsId: id,
        marginProgressionWithOutId: prepareData(values),
      }),
    );
  };

  const deleteMarginProgression = () => {
    dispatch(
      actionsNT.marginProgressionsDelete({
        marginProgressionsId: id,
      }),
    );
  };

  if (!marginProgression) {
    return null;
  }

  return (
    <>
      <Formik<FormValues>
        initialValues={{
          ...marginProgression,
          sport,
          enabled: !marginProgression.disabled,
        }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ handleSubmit, dirty, isValid }) => (
          <Panel
            title={t('panels.edit.progression')}
            onClose={onClose}
            onSubmit={handleSubmit}
            footer={
              isAdmin ? (
                <PanelFooter
                  submitButtonText={t('edit')}
                  disabled={!dirty || !isValid}
                  leftButtonText={t('delete')}
                  onLeftButtonClick={showDeleteModal}
                />
              ) : (
                <div />
              )
            }
          >
            <ProgressionForm actionType="edit" />
          </Panel>
        )}
      </Formik>
      {deleteModalVisible && (
        <Dialog
          text={t('deleteProgression', { name: marginProgression.name })}
          onClose={closeDeleteModal}
          onConfirm={deleteMarginProgression}
        />
      )}
    </>
  );
};
