import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EntityType, Sport } from 'sportsbook-openapi-react';

import { selectMarginProgressionOutcomeCategoriesItems } from 'app/providers/EntitiesProvider/marginProgressionOutcomeCategories';
import { selectMarginProgressionItemsBySport } from 'app/providers/EntitiesProvider/marginProgressions';
import { actionsNT } from 'app/providers/EntitiesProvider/slice';

interface UseMarginProgressionProps {
  entityId: number;
  entityType: EntityType;
  live: boolean;
  sportId?: Sport['id'];
  customerId?: number;
}

export interface MarginProgressionOutcomeCategoryInfo {
  outcomeCategoryId: number;
  marginProgressionId?: number;
  marginProgressionOutcomeCategory?: number;
}

export const useMarginProgression = ({
  entityId,
  entityType,
  live,
  sportId,
  customerId,
}: UseMarginProgressionProps) => {
  const dispatch = useDispatch();

  const marginProgressions = useSelector(state => {
    if (!sportId) return [];
    return selectMarginProgressionItemsBySport(state, +sportId);
  });

  const marginProgressionOutcomeCategories = useSelector(
    selectMarginProgressionOutcomeCategoriesItems,
  );

  useEffect(() => {
    if (sportId) {
      dispatch(
        actionsNT.marginProgressionsFetchItems({
          sportId: [sportId],
        }),
      );
    }
  }, [dispatch, sportId]);

  useEffect(() => {
    dispatch(
      actionsNT.marginProgressionOutcomeCategoriesFetchItems({
        entityType,
        entityId,
        live,
        customerId,
      }),
    );
  }, [entityType, entityId, live, customerId, dispatch]);

  const updateMarginProgression = useCallback(
    (
      newMarginProgressionId: string,
      marginProgressionOutcomeCategoryInfo: MarginProgressionOutcomeCategoryInfo,
    ) => {
      if (
        !newMarginProgressionId ||
        !marginProgressionOutcomeCategoryInfo.outcomeCategoryId
      ) {
        return;
      }

      switch (true) {
        case newMarginProgressionId === 'delete' &&
          !!marginProgressionOutcomeCategoryInfo.marginProgressionOutcomeCategory: {
          dispatch(
            actionsNT.marginProgressionOutcomeCategoriesDelete({
              categoryMarginProgressionId:
                marginProgressionOutcomeCategoryInfo.marginProgressionOutcomeCategory,
            }),
          );
          break;
        }
        case !marginProgressionOutcomeCategoryInfo.marginProgressionId: {
          dispatch(
            actionsNT.marginProgressionOutcomeCategoriesCreate({
              marginProgressionOutcomeCategoryWithoutId: {
                customerId,
                live,
                entityId,
                entityType,
                marginProgressionId: +newMarginProgressionId,
                outcomeCategoryId:
                  marginProgressionOutcomeCategoryInfo.outcomeCategoryId,
              },
            }),
          );
          break;
        }
        case !!marginProgressionOutcomeCategoryInfo.marginProgressionId &&
          !!marginProgressionOutcomeCategoryInfo.marginProgressionOutcomeCategory: {
          dispatch(
            actionsNT.marginProgressionOutcomeCategoriesUpdate({
              categoryMarginProgressionId:
                marginProgressionOutcomeCategoryInfo.marginProgressionOutcomeCategory,
              marginProgressionOutcomeCategoryWithoutId: {
                customerId,
                live,
                entityId,
                entityType,
                marginProgressionId: +newMarginProgressionId,
                outcomeCategoryId:
                  marginProgressionOutcomeCategoryInfo.outcomeCategoryId,
              },
            }),
          );
          break;
        }
      }
    },
    [entityType, entityId, live, customerId, dispatch],
  );

  return {
    marginProgressions,
    marginProgressionOutcomeCategories,
    updateMarginProgression,
  };
};
