import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { TextInput } from 'app/components/forms';

interface Props {
  searchQuery: string;
  setSearchQuery: (search: string) => void;
}

export const Search = ({ searchQuery, setSearchQuery }: Props) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLInputElement>(null);

  const handleClear = () => {
    if (ref.current) {
      ref.current.value = '';
      setSearchQuery('');
    }
  };

  return (
    <TextInput
      ref={ref}
      onChange={e => {
        setSearchQuery(e.target.value);
      }}
      placeholder={t('search')}
      icon={
        !!searchQuery ? (
          <FontAwesomeIcon icon={faTimes} onClick={handleClear} />
        ) : (
          <FontAwesomeIcon icon={faSearch} />
        )
      }
    />
  );
};
