import {
  Event,
  MatchPhase,
  ResultsPartialAutoCalculationInput,
  SportEventStatsV2,
  StatType,
} from 'sportsbook-openapi-react';

import { StatisticsValues } from './AutocalculationModal.types';

export const finalCalculate = (
  values: StatisticsValues,
  eventId: Event['id'],
  endedPhase?: MatchPhase,
) => {
  const autoCalculationStats: SportEventStatsV2 = {
    eventId,
    homeStats: { stats: [], periodStats: [] },
    awayStats: { stats: [], periodStats: [] },
    commonStats: {
      stats: !!endedPhase
        ? [
            {
              type: StatType.EVENT_PHASE_ID,
              value: { numberValue: endedPhase?.id },
            },
          ]
        : [],
    },
    fallbackStats: {},
  };

  // Fill home stats
  autoCalculationStats.homeStats.stats.push({
    type: StatType.SCORE,
    value: {
      numberValue: values.match.score.team1,
    },
  });

  autoCalculationStats.homeStats.periodStats = values.periods.map(
    (period, index) => ({
      periodNumber: index + 1,
      stat: {
        type: StatType.SCORE,
        value: {
          numberValue: period.score.team1,
        },
      },
    }),
  );

  // Fill away stats
  autoCalculationStats.awayStats.stats.push({
    type: StatType.SCORE,
    value: {
      numberValue: values.match.score.team2,
    },
  });

  autoCalculationStats.awayStats.periodStats = values.periods.map(
    (period, index) => ({
      periodNumber: index + 1,
      stat: {
        type: StatType.SCORE,
        value: {
          numberValue: period.score.team2,
        },
      },
    }),
  );

  return autoCalculationStats;
};

export const partialCalculate = (
  values: StatisticsValues,
  eventId: Event['id'],
) => {
  const autoCalculationStats: ResultsPartialAutoCalculationInput = {
    periodNumbers: [],
    stats: {
      eventId,
      homeStats: { stats: [], periodStats: [] },
      awayStats: { stats: [], periodStats: [] },
      commonStats: { stats: [] },
      fallbackStats: {},
    },
  };

  //Fill home stats
  values.periods.forEach((item, index) => {
    if (item.enabled === true) {
      autoCalculationStats.stats.homeStats.periodStats.push({
        periodNumber: index + 1,
        stat: {
          type: StatType.SCORE,
          value: {
            numberValue: item.score.team1,
          },
        },
      });
    }
  });

  // Fill away stats
  values.periods.forEach((item, index) => {
    if (item.enabled === true) {
      autoCalculationStats.stats.awayStats.periodStats.push({
        periodNumber: index + 1,
        stat: {
          type: StatType.SCORE,
          value: {
            numberValue: item.score.team2,
          },
        },
      });
    }
  });

  // Fill partial autocalculation stats with periodNumbers
  values.periods.forEach((item, index) => {
    if (item.enabled === true) {
      autoCalculationStats.periodNumbers.push(index + 1);
    }
  });

  return autoCalculationStats;
};
