import { useTranslation } from 'react-i18next';

import * as S from './AutocalculationPopup.styles';

export enum PopupStateEnum {
  EMPTY,
  FINAL,
  PARTIAL,
}

interface Props {
  onModalOpen: (type: PopupStateEnum) => void;
}

export const AutocalculationPopup = ({ onModalOpen }: Props) => {
  const { t } = useTranslation();

  return (
    <div>
      <S.PlainButton
        color="tretiary"
        onClick={() => {
          onModalOpen(PopupStateEnum.FINAL);
        }}
      >
        {t('final')}
      </S.PlainButton>
      <S.PlainButton
        color="tretiary"
        onClick={() => {
          onModalOpen(PopupStateEnum.PARTIAL);
        }}
      >
        {t('partial')}
      </S.PlainButton>
    </div>
  );
};
