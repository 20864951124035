import { createSlice } from 'utils/@reduxjs/toolkit';

import { reducer as betPayMaxReducer } from './betPayMax';
import { reducer as categoriesReducer } from './categories';
import { reducer as categoryCashoutBlocksReducer } from './categoryCashoutBlocks';
import { reducer as categoryExclusionsReducer } from './categoryExclusions';
import { reducer as commentsReducer } from './comments';
import { reducer as commentsByEntityReducer } from './commentsByEntity';
import { reducer as competitorsReducer } from './competitors';
import { ENTITIES_PROVIDER_REDUCER_KEY, initialState } from './constants';
import { reducer as countriesReducer } from './countries';
import { reducer as crossMappingsReducer } from './crossMappings';
import { reducer as currenciesReducer } from './currencies';
import { reducer as customersReducer } from './customers';
import { reducer as customerTokensReducer } from './customerTokens';
import { reducer as delaysReducer } from './delays';
import { reducer as eventFavoritesReducer } from './eventFavorites';
import { reducer as eventLinkedIdsReducer } from './eventLinkedIds';
import { reducer as eventsReducer } from './events';
import { reducer as eventsMappingsReducer } from './eventsMappings';
import { reducer as eventsOddsReducer } from './eventsOdds';
import { reducer as eventsOddsReturnsReducer } from './eventsOddsReturns';
import { reducer as eventsResultsReducer } from './eventsResults';
import { reducer as eventStatsReducer } from './eventStats';
import { reducer as eventStatusByCustomerReducer } from './eventStatusByCustomer';
import { reducer as eventSuspendsReducer } from './eventSuspends';
import { reducer as exclusionsReducer } from './exclusions';
import { reducer as groupsReducer } from './groups';
import { reducer as languagesReducer } from './languages';
import { reducer as logosReducer } from './logos';
import { reducer as marginProgressionOutcomeCategoriesReducer } from './marginProgressionOutcomeCategories';
import { reducer as marginProgressionsReducer } from './marginProgressions';
import { reducer as marginsReducer } from './margins';
import { reducer as marketsReducer } from './markets';
import { reducer as matchPhasesReducer } from './matchPhases';
import { reducer as matchTypesReducer } from './matchTypes';
import { reducer as maxOddsReducer } from './maxOdds';
import { reducer as oddOutcomeCalculatorRulesReducer } from './oddOutcomeCalculatorRules';
import { reducer as outcomeCategorySourceMappingsReducer } from './outcomeCategorySourceMappings';
import { reducer as playersReducer } from './players';
import { reducer as searchReducer } from './search';
import { reducer as sourcesReducer } from './sources';
import { reducer as sportsReducer } from './sports';
import { reducer as tagsReducer } from './tags';
import { reducer as tagsByCustomerReducer } from './tagsByCustomer';
import { reducer as tournamentsReducer } from './tournaments';
import { reducer as typesReducer } from './type';
import { EntitiesActions } from './types';
import { reducer as usersReducer } from './users';

const entitiesSlice = createSlice({
  name: ENTITIES_PROVIDER_REDUCER_KEY,
  initialState,
  reducers: {
    ...categoriesReducer,
    ...competitorsReducer,
    ...countriesReducer,
    ...currenciesReducer,
    ...customersReducer,
    ...usersReducer,
    ...eventsReducer,
    ...eventsOddsReducer,
    ...eventsMappingsReducer,
    ...eventsResultsReducer,
    ...eventSuspendsReducer,
    ...eventStatusByCustomerReducer,
    ...eventStatsReducer,
    ...eventFavoritesReducer,
    ...groupsReducer,
    ...marginsReducer,
    ...outcomeCategorySourceMappingsReducer,
    ...matchTypesReducer,
    ...marketsReducer,
    ...sportsReducer,
    ...tournamentsReducer,
    ...typesReducer,
    ...playersReducer,
    ...sourcesReducer,
    ...betPayMaxReducer,
    ...customerTokensReducer,
    ...exclusionsReducer,
    ...categoryExclusionsReducer,
    ...categoryCashoutBlocksReducer,
    ...languagesReducer,
    ...matchPhasesReducer,
    ...commentsReducer,
    ...commentsByEntityReducer,
    ...tagsReducer,
    ...tagsByCustomerReducer,
    ...eventsOddsReturnsReducer,
    ...eventLinkedIdsReducer,
    ...delaysReducer,
    ...logosReducer,
    ...crossMappingsReducer,
    ...searchReducer,
    ...maxOddsReducer,
    ...oddOutcomeCalculatorRulesReducer,
    ...marginProgressionsReducer,
    ...marginProgressionOutcomeCategoriesReducer,
  },
});

const { actions, reducer, name: sliceKey } = entitiesSlice;

const actionsNT = actions as unknown as EntitiesActions;
export { actionsNT, initialState, reducer, sliceKey };
