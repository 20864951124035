import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FieldArray, useFormikContext } from 'formik';
import { MatchPhaseWithoutId } from 'sportsbook-openapi-react';

import { TestIds } from 'types/testIds.types';

import { Checkbox, TextInputField } from 'app/components/forms';
import { Span, Table } from 'app/components/ui';

import { getSportsEntries } from 'app/providers/EntitiesProvider/sports';

import * as S from './styles';

const columns = ['sport'];

export const MatchPhaseForm = () => {
  const { t } = useTranslation();
  const sports = useSelector(getSportsEntries);
  const {
    values: { sportIds },
  } = useFormikContext<MatchPhaseWithoutId>();

  return (
    <>
      <S.FormBlock>
        <Span>{t('name')}</Span>
        <TextInputField name="name" testId={TestIds.MatchPhasesPanelName} />
      </S.FormBlock>
      <S.FormBlock>
        <Span>{t('phaseCode')}</Span>
        <TextInputField
          name="phaseCode"
          testId={TestIds.MatchPhasesPanelCode}
        />
      </S.FormBlock>
      <S.TableWrapper>
        <FieldArray name={'sportIds'}>
          {({ push, remove }) => (
            <Table<{ id: number }>
              columns={columns}
              data={Object.values(sports).map(sport => ({
                sport: (
                  <Checkbox
                    testId={`${TestIds.MatchPhasesPanelSportList}-checkbox--${sport.name}`}
                    checked={sportIds.includes(sport.id!)}
                    label={sport.name}
                    onChange={checked => {
                      if (checked) {
                        push(sport.id);
                      } else {
                        const index = sportIds.indexOf(sport.id);
                        remove(index);
                      }
                    }}
                  />
                ),
                id: sport.id,
              }))}
              onRowClick={({ id }) => {
                const sportIndex = sportIds.indexOf(id);
                if (sportIndex < 0) {
                  push(id);
                } else {
                  remove(sportIndex);
                }
              }}
              rowStyles={'cursor: pointer;'}
            />
          )}
        </FieldArray>
      </S.TableWrapper>
    </>
  );
};
