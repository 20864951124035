/**
 *
 * Tabs
 *
 */
import { useState } from 'react';
import head from 'lodash/head';

import { TestIds } from 'types/testIds.types';

import * as S from './Tabs.styles';
import { TabsProps } from './Tabs.types';

export const Tabs = <T,>({
  tabs,
  withNumber,
  withDots,
  defaultValue,
  onChange,
  className,
}: TabsProps<T>) => {
  const [selected, setSelected] = useState<T>(
    defaultValue ?? head(tabs)?.value!,
  );

  const onClick = (value: T) => {
    setSelected(value);
    onChange?.(value);
  };

  return (
    <S.TabsWrapper className={className} data-testid="Tabs">
      {tabs.map(tab => (
        <S.Item
          data-test-id={`${TestIds.SportPageTabType}--${tab.label}`}
          key={String(tab.value)}
          selected={tab.value === selected}
          withDots={withDots}
          withNumber={withNumber}
          onClick={() => onClick(tab.value)}
        >
          {tab.value === selected && withDots && <S.Point />}
          <S.Label>{tab.label}</S.Label>
          {withNumber && <S.Count>{tab.count}</S.Count>}
        </S.Item>
      ))}
    </S.TabsWrapper>
  );
};
