import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router-dom';
import { EventFilters } from 'app/pages/Events/components/EventsList';
import {
  initialEndDay,
  initialStartDay,
} from 'app/pages/Events/components/EventsList/constants';
import dayjs from 'dayjs';
import { isEqual } from 'lodash-es';

import { TestIds } from 'types/testIds.types';

import { Checkbox, DateRangePicker } from 'app/components/forms';
import { Button } from 'app/components/ui';

import * as S from './EventsListFilters.styles';

interface Props {
  filters: EventFilters;
  onFilterChange: (filter: EventFilters) => void;
  resetAll: () => void;
}

export const EventsListFilters: FC<Props> = ({
  filters,
  onFilterChange,
  resetAll,
}) => {
  const { t } = useTranslation();

  const handleFilterChange = (value: Partial<EventFilters>) => {
    if (!isEqual(filters, value)) {
      onFilterChange({
        ...filters,
        ...value,
      });
    }
  };

  const isResults = useMatch('/results');

  const adaptScheduledTo = (scheduledFrom?: Date) => {
    let scheduledTo = filters.scheduledTo;
    const hasScheduledFrom = !!scheduledFrom;

    if (
      hasScheduledFrom &&
      (dayjs(scheduledTo).diff(scheduledFrom, 'month') >= 6 ||
        dayjs(scheduledTo).diff(scheduledFrom, 'milliseconds') < 0)
    )
      scheduledTo = dayjs(scheduledFrom).add(6, 'month').toDate();

    return scheduledTo;
  };

  const adaptScheduledFrom = (scheduledTo?: Date) => {
    let scheduledFrom = filters.scheduledFrom;
    const hasScheduledTo =
      !!scheduledTo || dayjs(scheduledTo) < dayjs(scheduledFrom);

    if (
      hasScheduledTo &&
      (dayjs(scheduledTo).diff(scheduledFrom, 'month') >= 6 ||
        dayjs(scheduledTo).diff(scheduledFrom, 'milliseconds') < 0)
    ) {
      scheduledFrom = dayjs(scheduledTo).add(-6, 'month').toDate();
    }
    return scheduledFrom;
  };

  return (
    <S.Wrapper>
      <DateRangePicker
        startDate={filters.scheduledFrom}
        endDate={filters.scheduledTo}
        testId={TestIds.EventsPageDateFilter}
        onStartDateChange={scheduledFrom => {
          if (scheduledFrom === null) {
            handleFilterChange({
              notBegan: false,
              scheduledFrom: initialStartDay,
              scheduledTo: initialEndDay,
            });
            return;
          }
          handleFilterChange({
            notBegan: false,
            scheduledFrom,
            scheduledTo: adaptScheduledTo(scheduledFrom),
          });
        }}
        onEndDateChange={scheduledTo => {
          if (scheduledTo === null) {
            handleFilterChange({
              notBegan: false,
              scheduledFrom: initialStartDay,
              scheduledTo: initialEndDay,
            });
            return;
          }
          handleFilterChange({
            scheduledFrom: adaptScheduledFrom(scheduledTo),
            scheduledTo,
            notBegan: adaptScheduledFrom(scheduledTo) === filters.scheduledFrom,
          });
        }}
      />
      <Checkbox
        checked={filters.notBegan}
        onChange={notBegan => {
          handleFilterChange({
            notBegan,
            scheduledFrom: notBegan ? new Date() : initialStartDay,
            scheduledTo: notBegan
              ? dayjs(new Date()).add(6, 'month').toDate()
              : initialEndDay,
          });
        }}
        label={t('not started')}
        testId={TestIds.EventsPageNotBeganCheckbox}
      />
      <Checkbox
        checked={filters.favorite}
        onChange={favorite => {
          handleFilterChange({ favorite });
        }}
        label={t('favorite')}
        testId={TestIds.EventsPageFavoriteCheckbox}
      />
      {isResults && (
        <Checkbox
          checked={filters.unresulted}
          onChange={unresulted => {
            handleFilterChange({ unresulted });
          }}
          label={t('not resulted')}
          testId={TestIds.ResultsPageNotResultedCheckbox}
        />
      )}
      <Button
        color="action"
        onClick={resetAll}
        testId={TestIds.EventsPageResetFiltersButton}
      >
        {t('reset filters and sorting')}
      </Button>
    </S.Wrapper>
  );
};
