import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { SingleValue } from 'react-select';
import { AdaptersEventsList } from 'app/pages/Sources/components';
import { MapEntities } from 'app/pages/Sources/components';
import { IsReadyContext } from 'app/pages/Sources/Sources';
import { get } from 'lodash-es';
import { AdapterEvent, AdapterSport } from 'sportsbook-openapi-react';

import { FilterCacheKeys } from 'utils/filters';
import { cache, pruneCache, uncache } from 'utils/sessionStorage';
import { FETCH_ALL_LIMIT } from 'consts';
import { TestIds } from 'types/testIds.types';

import { Checkbox, DateRangePicker, Select } from 'app/components/forms';
import {
  Button,
  Dropdown,
  LoadingIndicator,
  Tabs,
  Text,
} from 'app/components/ui';

import {
  adapterActions,
  selectAdapters,
  selectAdapterSportsItems,
} from 'app/providers/AdaptersProvider';
import { actionsNT } from 'app/providers/EntitiesProvider';

import {
  initialTableState,
  WITH_PROBS_ID,
  WITHOUT_PROBS_ID,
} from './SourcesPage.constants';
import * as S from './SourcesPage.styles';
import {
  Filters,
  SourcePageParamsType,
  SourcesTableState,
  SourceTabsEnum,
} from './SourcesPage.types';
import { getProbsSelectValue, getSourceName } from './SourcesPage.utils';

export const SourcesPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { adapter } = useParams<SourcePageParamsType>();

  if (!adapter) {
    throw new Error('Provide adapter parameter');
  }
  const navigate = useNavigate();
  const { isReady, setIsReady } = useContext(IsReadyContext);

  const sourcesOptions = useSelector(selectAdapters);

  const [filters, setFilters] = useState<Filters>({
    scheduledFrom: new Date(),
    notBegan: true,
    isOutright: false,
    probsReceived: undefined,
    ...uncache<Filters>(FilterCacheKeys.SOURCE, [
      'scheduledFrom',
      'scheduledTo',
    ]),
  });

  useEffect(() => cache(FilterCacheKeys.SOURCE, filters), [filters]);

  useEffect(() => {
    if (sourcesOptions.some(({ name }) => name === adapter)) {
      setIsReady(true);
      return;
    }
    if (
      sourcesOptions.some(({ name }) => name === adapter) ||
      sourcesOptions.length === 0
    ) {
      return;
    }
    navigate(`/sources/${sourcesOptions[0].name}`, { replace: true });
  }, [adapter, navigate, setIsReady, sourcesOptions]);

  useEffect(() => {
    dispatch(adapterActions.fetchAdapters());
    dispatch(actionsNT.sportsFetchItems({ limit: FETCH_ALL_LIMIT }));
  }, [dispatch]);
  const [tableState, setTableState] =
    useState<SourcesTableState>(initialTableState);

  const adapterSportsOptions = useSelector(selectAdapterSportsItems);

  const [selectedAdapterEvent, setAdapterEvent] =
    useState<Maybe<AdapterEvent>>(null);

  const handleFilterChange = (key: keyof Filters) => value => {
    if (get(filters, key) !== value) {
      setFilters(prevFilters => ({
        ...prevFilters,
        [key]: value,
      }));
    }
  };

  const [tab, setTab] = useState(SourceTabsEnum.UNMAPPED);

  const tabs = [
    { label: t(SourceTabsEnum.UNMAPPED), value: SourceTabsEnum.UNMAPPED },
    { label: t(SourceTabsEnum.MAPPED), value: SourceTabsEnum.MAPPED },
    { label: t(SourceTabsEnum.EXCLUDED), value: SourceTabsEnum.EXCLUDED },
  ];

  const isMapped = tab === SourceTabsEnum.MAPPED;
  const isExcluded = tab === SourceTabsEnum.EXCLUDED;

  const allSportsOption = { id: '', name: t('all') } as Required<AdapterSport>;

  const resetFilters = () => {
    setFilters({
      scheduledFrom: new Date(),
      notBegan: true,
      sport: allSportsOption,
      isOutright: false,
      probsReceived: undefined,
    });
  };

  const handleRouteChange = () => {
    pruneCache(FilterCacheKeys.SOURCE);
    resetFilters();
  };

  const handleTabChange = (value: SourceTabsEnum) => {
    setTab(value);
    setAdapterEvent(null);
    setTableState(initialTableState);
  };

  const withProbsOptions = [
    { id: WITH_PROBS_ID, name: t('with probs') },
    { id: WITHOUT_PROBS_ID, name: t('without probs') },
    { id: '', name: t('all events') },
  ];
  const withProbsChangeHandler = (value: SingleValue<{ id: string }>) => {
    switch (value?.id) {
      case '': {
        handleFilterChange('probsReceived')(undefined);
        break;
      }
      case WITH_PROBS_ID: {
        handleFilterChange('probsReceived')(true);
        break;
      }
      case WITHOUT_PROBS_ID: {
        handleFilterChange('probsReceived')(false);
        break;
      }
    }
  };
  const withProbsValue = withProbsOptions.find(
    ({ id }) => id === getProbsSelectValue(filters.probsReceived),
  );

  return (
    <>
      <S.Header>
        <Dropdown
          component={
            <Text size="l" weight={500}>
              {getSourceName(adapter!)}
            </Text>
          }
          options={sourcesOptions.map(option => (
            <S.StyledLink
              to={`/sources/${option.name}`}
              onClick={handleRouteChange}
            >
              {getSourceName(option.name!)}
            </S.StyledLink>
          ))}
        />
        <Button
          color="secondary"
          onClick={() => navigate(`/sources/${adapter}/sports`)}
        >
          {t('data categories')}
        </Button>
      </S.Header>
      <Tabs tabs={tabs} onChange={handleTabChange} defaultValue={tab} />
      <S.FiltersBlock>
        <Text>{t('filter by sport')}</Text>
        <Select
          options={[allSportsOption, ...adapterSportsOptions]}
          onChange={handleFilterChange('sport')}
          value={filters.sport}
          testId={TestIds.SourcesPageSportFilter}
        />
        <DateRangePicker
          startDate={filters.scheduledFrom}
          endDate={filters.scheduledTo}
          onStartDateChange={handleFilterChange('scheduledFrom')}
          onEndDateChange={handleFilterChange('scheduledTo')}
        />
        <Select
          options={withProbsOptions}
          onChange={withProbsChangeHandler}
          value={withProbsValue}
          testId={TestIds.SourcesPageEventsFilter}
        />
        <Checkbox
          checked={filters.notBegan}
          onChange={value => {
            handleFilterChange('notBegan')(value);
            handleFilterChange('scheduledFrom')(value ? new Date() : undefined);
            handleFilterChange('scheduledTo')(undefined);
          }}
          label={t('not started')}
        />
        <Checkbox
          checked={filters.isOutright}
          onChange={handleFilterChange('isOutright')}
          label={t('outright')}
        />
        <Button
          color="action"
          onClick={resetFilters}
          testId={TestIds.SourcesPageResetButton}
        >
          {t('reset filter')}
        </Button>
      </S.FiltersBlock>
      {isReady ? (
        <AdaptersEventsList
          filters={filters}
          setFilters={setFilters}
          tableState={tableState}
          setTableState={setTableState}
          selectedAdapterEvent={selectedAdapterEvent}
          setAdapterEvent={setAdapterEvent}
          isExcluded={isExcluded}
          isMapped={isMapped}
          tab={tab}
        />
      ) : (
        <S.TableLoading>
          <LoadingIndicator />
        </S.TableLoading>
      )}
    </>
  );
};

export const EntitiesWrapper = () => {
  const navigate = useNavigate();

  const { adapter } = useParams<SourcePageParamsType>();
  const isReady = useContext(IsReadyContext);

  if (!adapter) {
    throw new Error('Provide adapter parameter');
  }

  if (!isReady) {
    return (
      <S.TableLoading>
        <LoadingIndicator />
      </S.TableLoading>
    );
  }

  return (
    <S.Wrapper>
      <MapEntities
        adapter={adapter!}
        onClose={() => navigate(`/sources/${adapter}`)}
      />
    </S.Wrapper>
  );
};
