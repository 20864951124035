import {
  MarginProgressionApiDeleteMarginProgressionOutcomeCategoryRequest,
  MarginProgressionOutcomeCategory,
  MarginProgressionOutcomeCategoryList,
} from 'sportsbook-openapi-react';

import { EntitiesErrorType } from 'app/providers/EntitiesProvider/types';
import { entityReducersGenerator } from 'app/providers/EntitiesProvider/utils';

const reducer = {
  ...entityReducersGenerator<
    MarginProgressionOutcomeCategoryList,
    MarginProgressionOutcomeCategory,
    MarginProgressionApiDeleteMarginProgressionOutcomeCategoryRequest,
    EntitiesErrorType
  >({
    entity: 'marginProgressionOutcomeCategories',
  }),
};

export default reducer;
