import { oddOutcomeCalculatorRulesApi } from 'app/api';

const api = {
  endpoint: oddOutcomeCalculatorRulesApi,
  methods: {
    fetchItems: 'getOddOutcomeCalculatorRules',
    fetchItem: 'getOddOutcomeCalculatorRule',
    create: 'createOddOutcomeCalculatorRule',
    update: 'updateOddOutcomeCalculatorRule',
    delete: 'deleteOddOutcomeCalculatorRule',
  },
};

export default api;
