import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getTranslatedEntity } from 'app/pages/Translations/selectors';
import { actions } from 'app/pages/Translations/slice';
import { TranslatedEntities } from 'app/pages/Translations/types';
import { Language, Sport } from 'sportsbook-openapi-react';

import { useSearch } from 'hooks/useSearch';
import { FETCH_ALL_LIMIT } from 'consts';

import { Select, TextInput } from 'app/components/forms';

import { actionsNT } from 'app/providers/EntitiesProvider';
import { selectLanguages } from 'app/providers/EntitiesProvider/languages';
import { selectSportsItems } from 'app/providers/EntitiesProvider/sports';

import * as S from './TranslationFilters.styles';

export function TranslationFilters() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const entity = useSelector(getTranslatedEntity);
  const searchRef = useRef<HTMLInputElement>(null);
  const translationSearchRef = useRef<HTMLInputElement>(null);

  const translatedOptions = [
    {
      id: 'all',
      name: t('all'),
    },
    {
      id: 'untranslated',
      name: t('untranslated'),
    },
    {
      id: 'translated',
      name: t('translated'),
    },
  ];

  const showSportsFilter =
    entity === TranslatedEntities.GROUPS ||
    entity === TranslatedEntities.TOURNAMENTS ||
    entity === TranslatedEntities.COMPETITORS;

  const showTranslationSearchInput =
    entity === TranslatedEntities.TOURNAMENTS ||
    entity === TranslatedEntities.COMPETITORS ||
    entity === TranslatedEntities.SPORTS ||
    entity === TranslatedEntities.COUNTRIES ||
    entity === TranslatedEntities.PLAYERS ||
    entity === TranslatedEntities.TYPES ||
    entity === TranslatedEntities.COMMENTS;

  const {
    searchQuery: searchNameQuery,
    setSearchQueryDebounced: setNameSearchQueryDebounced,
    setSearchQuery: setNameSearchQuery,
  } = useSearch();

  const {
    searchQuery: searchTranslationQuery,
    setSearchQueryDebounced: setTranslationSearchQueryDebounced,
    setSearchQuery: setTranslationSearchQuery,
  } = useSearch();

  useEffect(() => {
    dispatch(actionsNT.languagesFetchItems({ limit: FETCH_ALL_LIMIT }));
  }, [dispatch]);

  useEffect(() => {
    if (showSportsFilter) {
      dispatch(actionsNT.sportsFetchItems({ limit: FETCH_ALL_LIMIT }));
    }
  }, [dispatch, showSportsFilter]);

  useEffect(() => {
    if (searchRef.current) {
      searchRef.current.value = '';
      setNameSearchQuery('');
    }
    if (translationSearchRef.current) {
      translationSearchRef.current.value = '';
      setTranslationSearchQuery('');
    }
  }, [entity, setNameSearchQuery, setTranslationSearchQuery]);

  const languageItems = useSelector(selectLanguages);

  const sportItems = useSelector(selectSportsItems);

  const [language, selectLanguage] = useState<Maybe<Language>>(
    languageItems[0],
  );

  const [sport, selectSport] = useState<Maybe<Sport>>();

  useEffect(() => {
    if (!language) {
      selectLanguage(languageItems[0]);
    }
  }, [languageItems, language]);

  const [translated, selectTranslated] = useState<
    ArrayElement<typeof translatedOptions>
  >(translatedOptions[0]);

  useEffect(() => {
    dispatch(
      actions.setFilters({
        langCode: language ? language.code : undefined,
        translated:
          translated.id === 'all' ? undefined : translated.id === 'translated',
        sportId: sport?.id,
        name: searchNameQuery ? searchNameQuery : undefined,
        translationName: searchTranslationQuery
          ? searchTranslationQuery
          : undefined,
      }),
    );
  }, [
    language,
    translated,
    dispatch,
    sport?.id,
    searchNameQuery,
    searchTranslationQuery,
  ]);

  return (
    <S.Wrapper hasSearch={showTranslationSearchInput}>
      {t('translation language')}
      <S.SelectWrapper>
        <Select
          value={language}
          options={languageItems}
          onChange={value => selectLanguage(value as Language)}
        />
      </S.SelectWrapper>
      <S.Spacer />
      {t('show')}
      <S.SelectWrapper>
        <Select
          value={translated}
          options={translatedOptions}
          onChange={value =>
            selectTranslated(value as ArrayElement<typeof translatedOptions>)
          }
        />
      </S.SelectWrapper>
      {showSportsFilter && (
        <>
          {t('sport type')}
          <S.SelectWrapper>
            <Select
              isClearable
              value={sport}
              options={sportItems}
              onChange={value => selectSport(value as Sport)}
            />
          </S.SelectWrapper>
        </>
      )}
      <S.Spacer />
      <S.SelectWrapper>
        <TextInput
          ref={searchRef}
          placeholder={t('search')}
          onChange={e => setNameSearchQueryDebounced(e.target.value)}
          icon={<FontAwesomeIcon icon={faSearch} />}
        />
      </S.SelectWrapper>
      <S.Spacer />
      {showTranslationSearchInput && (
        <S.SelectWrapper>
          <TextInput
            ref={translationSearchRef}
            placeholder={t('search translation')}
            onChange={e => setTranslationSearchQueryDebounced(e.target.value)}
            icon={<FontAwesomeIcon icon={faSearch} />}
          />
        </S.SelectWrapper>
      )}
    </S.Wrapper>
  );
}
