import { FC, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { WITHOUT_COMPETITOR_OPTION_ID } from 'app/pages/Events/components/EventFormik';
import { MappedEventsTable } from 'app/pages/Sources/components';
import { getHomeAwayCompetitors } from 'app/pages/Sources/utils';
import dayjs from 'dayjs';
import { Formik, FormikProps } from 'formik';
import { get } from 'lodash-es';
import {
  AdapterEvent,
  Competitor,
  EventKind,
  MatchType,
  Sport,
  Tournament,
} from 'sportsbook-openapi-react';

import { getAsyncLoadOptions } from 'utils/select';
import { yup } from 'utils/validation';
import {
  MAIN_MATCH_TYPE_ID,
  useCreateEventForAdapter,
} from 'hooks/events/useEvent';
import useBooleanState from 'hooks/useBooleanState';
import { FETCH_ALL_LIMIT } from 'consts';
import { TestIds } from 'types/testIds.types';

import { AsyncSelectField, SelectField } from 'app/components/forms';
import {
  CompetitorCreatePanel,
  TournamentCreatePanel,
} from 'app/components/panels';
import { Button, Dialog, Tooltip } from 'app/components/ui';
import { TooltipPositionEnum } from 'app/components/ui/Tooltip/types';
import LargePlus from 'assets/images/large-plus.svg?react';

import { getAdapterSportsEntries } from 'app/providers/AdaptersProvider';
import { actionsNT } from 'app/providers/EntitiesProvider';
import { getCompetitorsEntries } from 'app/providers/EntitiesProvider/competitors';
import { selectMatchTypes } from 'app/providers/EntitiesProvider/matchTypes';
import {
  getSportsEntries,
  selectSportsItems,
} from 'app/providers/EntitiesProvider/sports';
import { getTournamentsEntries } from 'app/providers/EntitiesProvider/tournaments';

import * as S from './MapEventForm.styles';

interface Props {
  adapter: string;
  mapped?: boolean;
  adapterEvent: AdapterEvent;
  onMap: () => void;
}

interface Values {
  sport?: Sport;
  tournament?: Tournament;
  competitorA?: Competitor;
  competitorB?: Competitor;
  adapterEventId?: string;
  cross?: boolean;
  matchType?: MatchType;
}

const MapEventValidationSchema = yup.object({
  sport: yup.object(),
  tournament: yup.object(),
  competitorA: yup.object(),
  competitorB: yup.object(),
});

const HOURS_RANGE = 24;
const WEEKS_RANGE = 1;

export const MapEventForm: FC<Props> = ({
  mapped,
  adapter,
  adapterEvent,
  onMap,
}) => {
  const dispatch = useDispatch();

  const adapterSportsEntries = useSelector(getAdapterSportsEntries);
  const sports = useSelector(getSportsEntries);
  const tournaments = useSelector(getTournamentsEntries);
  const competitors = useSelector(getCompetitorsEntries);
  const { entries: matchTypesEntries } = useSelector(selectMatchTypes);

  const { homeCompetitor, awayCompetitor } = useMemo(
    () => getHomeAwayCompetitors(adapterEvent),
    [adapterEvent],
  );

  useEffect(() => {
    if (homeCompetitor?.sportsbookId) {
      dispatch(
        actionsNT.competitorsFetchItem({
          competitorId: homeCompetitor.sportsbookId!,
        }),
      );
    }
    if (awayCompetitor?.sportsbookId) {
      dispatch(
        actionsNT.competitorsFetchItem({
          competitorId: awayCompetitor.sportsbookId!,
        }),
      );
    }
  }, [awayCompetitor, dispatch, homeCompetitor]);

  useEffect(() => {
    if (adapterEvent.tournament?.sportsbookId) {
      dispatch(
        actionsNT.tournamentsFetchItem({
          tournamentId: adapterEvent.tournament?.sportsbookId,
        }),
      );
    }
  }, [adapterEvent.tournament, dispatch]);

  const sportsbookSport = useMemo(() => {
    if (
      adapterSportsEntries &&
      adapterEvent.tournament?.sportId &&
      adapterSportsEntries[adapterEvent.tournament?.sportId]?.sportsbookId
    ) {
      return sports[
        adapterSportsEntries[adapterEvent.tournament?.sportId]?.sportsbookId!
      ];
    }
  }, [adapterEvent.tournament, sports, adapterSportsEntries]);

  const initialValues = useMemo(() => {
    const tournamentId = adapterEvent.tournament?.sportsbookId;
    const competitorAId = homeCompetitor?.sportsbookId;
    const competitorBId = awayCompetitor?.sportsbookId;

    return {
      sport: sportsbookSport,
      tournament: tournamentId ? tournaments[tournamentId] : undefined,
      competitorA: competitorAId ? competitors[competitorAId] : undefined,
      competitorB: competitorBId ? competitors[competitorBId] : undefined,
      adapterEventId: adapterEvent.id,
      matchType: matchTypesEntries[MAIN_MATCH_TYPE_ID],
    };
  }, [
    adapterEvent.tournament?.sportsbookId,
    adapterEvent.id,
    homeCompetitor?.sportsbookId,
    awayCompetitor?.sportsbookId,
    sportsbookSport,
    tournaments,
    competitors,
    matchTypesEntries,
  ]);
  const createEvent = useCreateEventForAdapter(adapter);

  return (
    <Formik<Values>
      enableReinitialize
      initialValues={initialValues}
      onSubmit={({
        tournament,
        competitorA,
        competitorB,
        cross,
        matchType,
      }) => {
        createEvent({
          tournamentId: tournament?.id!,
          groupId: tournament?.groupId!,
          competitorA: cross ? competitorB?.id! : competitorA?.id!,
          competitorB: cross
            ? competitorA?.id!
            : competitorB?.id === WITHOUT_COMPETITOR_OPTION_ID
              ? undefined
              : competitorB?.id!,
          adapterEvent: adapterEvent!,
          matchTypeId: matchType?.id,
          kind: tournament?.kind as unknown as EventKind,
        });
      }}
      validationSchema={MapEventValidationSchema}
    >
      {formikProps => (
        <>
          {!mapped && (
            <EventForm
              {...formikProps}
              eventDate={adapterEvent.scheduled!}
              sport={
                get(
                  adapterSportsEntries,
                  `[${adapterEvent.tournament?.sportId}]`,
                )?.name
              }
              tournament={adapterEvent.tournament?.name}
              competitors={[homeCompetitor?.name, awayCompetitor?.name]}
              adapter={adapter}
            />
          )}
          <MappedEventsTable
            mapped={mapped}
            adapter={adapter}
            adapterEvent={adapterEvent}
            searchParams={{
              sportId: get(formikProps, 'values.sport.id'),
              tournamentId: get(formikProps, 'values.tournament.id'),
              competitors:
                formikProps.values.competitorB?.id ===
                WITHOUT_COMPETITOR_OPTION_ID
                  ? [get(formikProps, 'values.competitorA.id')]
                  : [
                      get(formikProps, 'values.competitorA.id'),
                      get(formikProps, 'values.competitorB.id'),
                    ],
            }}
            onMap={onMap}
          />
        </>
      )}
    </Formik>
  );
};

interface EventFormProps extends FormikProps<Values> {
  eventDate: Date;
  sport: string | undefined;
  tournament: string | undefined;
  competitors: (string | undefined)[];
  adapter: string;
}

const EventForm: FC<EventFormProps> = ({
  values,
  handleSubmit,
  setFieldValue,
  sport,
  tournament,
  competitors = [],
  eventDate,
  adapter,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isCrossDialogVisible, showCrossDialog, hideCrossDialog] =
    useBooleanState();
  const { items: matchTypesItems, entries: matchTypesEntries } =
    useSelector(selectMatchTypes);

  const [
    isCreateTournamentVisible,
    showCreateTournament,
    hideCreateTournament,
  ] = useBooleanState();
  const [
    isCreateCompetitorAVisible,
    showCreateCompetitorA,
    hideCreateCompetitorA,
  ] = useBooleanState();

  const [
    isCreateCompetitorBVisible,
    showCreateCompetitorB,
    hideCreateCompetitorB,
  ] = useBooleanState();

  const sports = useSelector(selectSportsItems);

  useEffect(() => {
    dispatch(actionsNT.sportsFetchItems({ limit: FETCH_ALL_LIMIT }));
  }, [dispatch]);

  useEffect(() => {
    if (values.tournament) {
      dispatch(
        actionsNT.tournamentsFetchItem({
          tournamentId: values.tournament?.id,
        }),
      );
    }
    if (values.sport) {
      dispatch(
        actionsNT.matchTypesFetchItems({
          sportId: values.sport.id,
          limit: FETCH_ALL_LIMIT,
        }),
      );
    }
  }, [dispatch, values.sport, values.tournament]);

  useEffect(() => {
    if (
      values.sport &&
      values.tournament &&
      values.competitorA &&
      values.competitorB
    ) {
      const fromDate = dayjs(eventDate).subtract(HOURS_RANGE, 'hour');
      const toDate = dayjs(eventDate).add(WEEKS_RANGE, 'week');
      const competitors =
        values.competitorB?.id === WITHOUT_COMPETITOR_OPTION_ID
          ? [values.competitorA.id]
          : [values.competitorA.id, values.competitorB.id];
      dispatch(
        actionsNT.eventsFetchItems({
          sportId: values.sport.id,
          tournamentId: values.tournament.id,
          competitors: competitors,
          scheduledFrom: fromDate.toDate(),
          scheduledTo: toDate.toDate(),
          limit: FETCH_ALL_LIMIT,
          withPagination: true,
        }),
      );
    }
  }, [values, dispatch, eventDate]);

  useEffect(() => {
    if (values.sport?.id) {
      if (values.matchType?.sports.includes(values.sport.id)) {
      } else {
        const matchType = matchTypesEntries[MAIN_MATCH_TYPE_ID];
        if (matchType) {
          setFieldValue('matchType', matchTypesEntries[matchType.id]);
        }
      }
    }
  }, [setFieldValue, values.sport, values.matchType, matchTypesEntries]);

  const disabled =
    !values.sport ||
    !values.tournament ||
    !values.competitorA ||
    !values.competitorB;

  const crossDisabled =
    !values.sport ||
    !values.tournament ||
    !values.competitorA ||
    !values.competitorB ||
    values.competitorB?.id === WITHOUT_COMPETITOR_OPTION_ID;

  const handleCrossCreate = () => {
    setFieldValue('cross', true);
    form.current?.dispatchEvent(
      new Event('submit', { cancelable: true, bubbles: true }),
    );
  };

  const handleTournamentSelect = (newTournament: Tournament) => {
    setFieldValue('tournament', newTournament);
  };

  const handleCompetitorASelect = (competitor: Competitor) => {
    setFieldValue('competitorA', competitor);
  };
  const handleCompetitorBSelect = (competitor: Competitor) => {
    setFieldValue('competitorB', competitor);
  };

  const form = useRef<HTMLFormElement>(null);

  const withoutCompetitorOption = {
    name: t('without competitor'),
    id: WITHOUT_COMPETITOR_OPTION_ID,
  };

  return (
    <S.Wrapper
      ref={form}
      onSubmit={handleSubmit}
      withMatchType={adapter === 'fonbet'}
    >
      <S.FormField>
        <S.FieldLabel>
          {t('sport')}: <S.FieldValue>{sport}</S.FieldValue>
        </S.FieldLabel>
        <SelectField
          name="sport"
          options={sports}
          testId={TestIds.MappedEventFormSport}
        />
      </S.FormField>
      <S.FormField>
        <S.FieldLabel>
          {t('tournament')}: <S.FieldValue>{tournament}</S.FieldValue>
        </S.FieldLabel>
        <Tooltip
          position={TooltipPositionEnum.TOP}
          content={values.tournament?.name}
          isVisible={!!values.tournament}
        >
          <AsyncSelectField
            testId={TestIds.MappedEventFormTournament}
            name="tournament"
            cacheUniqs={[values.sport]}
            loadOptions={getAsyncLoadOptions({
              entity: 'tournaments',
              limit: 20,
              sportId: values.sport?.id,
              disabled: false,
            })}
          />
        </Tooltip>
        <Button
          type="button"
          color="action"
          onClick={showCreateTournament}
          testId={TestIds.MappedEventFormTournamentAdd}
        >
          <LargePlus />
        </Button>
      </S.FormField>
      <S.FormField>
        <S.FieldLabel>
          {`${t('competitor')} 1`}:{' '}
          <S.FieldValue>{competitors[0]}</S.FieldValue>
        </S.FieldLabel>
        <Tooltip
          position={TooltipPositionEnum.TOP}
          content={values.competitorA?.name}
          isVisible={!!values.competitorA}
        >
          <AsyncSelectField
            testId={TestIds.MappedEventFormCompetitorA}
            name="competitorA"
            cacheUniqs={[values.sport]}
            loadOptions={getAsyncLoadOptions({
              entity: 'competitors',
              limit: 20,
              sportId: values.sport?.id,
              excludeOptions: [values.competitorB],
              disabled: false,
            })}
          />
        </Tooltip>
        <Button
          type="button"
          color="action"
          onClick={showCreateCompetitorA}
          testId={TestIds.MappedEventFormCompetitorAAdd}
        >
          <LargePlus />
        </Button>
      </S.FormField>
      <S.FormField>
        <S.FieldLabel>
          {`${t('competitor')} 2`}:{' '}
          <S.FieldValue>{competitors[1]}</S.FieldValue>
        </S.FieldLabel>
        <Tooltip
          position={TooltipPositionEnum.TOP}
          content={values.competitorB?.name}
          isVisible={!!values.competitorB}
        >
          <AsyncSelectField
            testId={TestIds.MappedEventFormCompetitorB}
            name="competitorB"
            cacheUniqs={[values.sport]}
            loadOptions={getAsyncLoadOptions({
              entity: 'competitors',
              limit: 20,
              sportId: values.sport?.id,
              excludeOptions: [values.competitorA],
              disabled: false,
              emptyOption: withoutCompetitorOption,
            })}
          />
        </Tooltip>
        <Button
          type="button"
          color="action"
          onClick={showCreateCompetitorB}
          testId={TestIds.MappedEventFormCompetitorBAdd}
        >
          <LargePlus />
        </Button>
      </S.FormField>
      {adapter === 'fonbet' && (
        <S.FormField>
          <S.FieldLabel>{t('match type')}</S.FieldLabel>
          <SelectField
            name="matchType"
            options={matchTypesItems}
            disabled={!values.sport?.id}
          />
        </S.FormField>
      )}
      <Button
        color="secondary"
        disabled={crossDisabled}
        onClick={showCrossDialog}
        type="button"
        testId={TestIds.MappedEventFormCreatCrossButton}
      >
        {t('create cross-mapping')}
      </Button>
      <Button
        testId={TestIds.MappedEventFormCreateButton}
        disabled={disabled}
        onClick={() => {
          setFieldValue('cross', false);
        }}
        type="submit"
      >
        {t('create new event')}
      </Button>
      {isCrossDialogVisible && (
        <Dialog
          text={t('crossDialog')}
          onClose={hideCrossDialog}
          onConfirm={handleCrossCreate}
        />
      )}
      {isCreateTournamentVisible && (
        <TournamentCreatePanel
          onClose={hideCreateTournament}
          sportId={values.sport?.id}
          onSuccess={handleTournamentSelect}
        />
      )}
      {isCreateCompetitorAVisible && (
        <CompetitorCreatePanel
          onClose={hideCreateCompetitorA}
          sportId={values.sport?.id}
          onSuccess={handleCompetitorASelect}
        />
      )}
      {isCreateCompetitorBVisible && (
        <CompetitorCreatePanel
          onClose={hideCreateCompetitorB}
          sportId={values.sport?.id}
          onSuccess={handleCompetitorBSelect}
        />
      )}
    </S.Wrapper>
  );
};
